<template>
  <div>
    <u-btn @click="toggle" color="blue-grey-1" size="sm" class="app-btn text-blue-grey-5" no-caps>
      <!--    Mais filtros-->
      <u-icon name="filter" class="m-l__ m-r-sm fin-icon-filter" type="fa" /> <span>Mais Filtros</span>
      <u-popover :anchor-click="false" disable-close-on-click-outside @show="activated" ref="popover" class="window-context-menu wrapper min popover-effect fin-filtro-mais-popover" anchor="bottom left" self="top left"
                 :offset="[0, 5]">
        <div class="simple-menu m-t-xs flex justify-between column full-width e-input-modern size1">
          <e-row class="col-grow-1 m-t" mr>
            <e-col style="min-width: 170px" class="m-l m-r">
              <erp-s-field
                  view="tl"
                  label="Código do Leilão"
              >
                <erp-input shortkey="F2" v-model="filters.codigo" />
              </erp-s-field>
            </e-col>
            <e-col style="min-width: 170px" class="m-l">
              <erp-s-field
                  view="tl"
                  label="Ano"
              >
                <erp-input v-model="filters.ano" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="col-grow-1 m-t" mr>
            <e-col style="min-width: 170px" class="m-l">
              <date-input field-view="tl" label="Data (De)" v-model="filters.data1"/>
            </e-col>

            <e-col style="min-width: 170px" class="m-l">
             <date-input field-view="tl" label="Data (Até)" v-model="filters.data2"/>
            </e-col>
          </e-row>
          <e-row class="col-grow-1 m-t" mr>
            <e-col style="min-width: 170px" class="m-l">
              <status-interno-select field-view="tl" multiple v-model="filters.statusInterno"/>
            </e-col>
<!--            <e-col style="min-width: 170px" class="m-l">
              <erp-s-field
                  view="tl"
                  label="Tipo do Evento"
              >
                <erp-select
                    :options="[
                    {label: 'Qualquer', value: null},
                    {label: 'Leilão', value: 'leilao'},
                    {label: 'Venda Direta', value: 'venda-direta'},
                  ]"
                    v-model="filters.tipoEvento" />
              </erp-s-field>
            </e-col>-->
          </e-row>
          <e-row class="col-grow-1 m-t" mr>
            <e-col style="min-width: 170px" class="m-l">
              <tipo-leilao-select v-model="filters.tipo"/>
            </e-col>
            <e-col style="min-width: 170px" class="m-l">
              <default-async-select label="Classificação" v-model="filters.classificacao"
                                    :list="listClassificacao"/>
            </e-col>
          </e-row>
          <e-row class="col-grow-1 m-t" mr>
            <e-col style="min-width: 170px" class="m-l">
              <default-async-select label="Leiloeiro" v-model="filters.leiloeiro" :list="listLeiloeiros"/>
            </e-col>
          </e-row>
          <e-row class="m-t" mr>
            <e-col class="text-right">
              <a @click="reset" class="m-r font-12 text-negative">Remover filtros</a>
              <e-btn label="Buscar" @click="buscarFechar" />
            </e-col>
          </e-row>
        </div>
      </u-popover>
    </u-btn>
  </div>
</template>

<script>
import {UPopover} from "uloc-vue"
import {ErpInput, ErpSField, ErpSelect} from "uloc-vue-plugin-erp"
import ECol from "@/components/layout/components/Col"
import ERow from "@/components/layout/components/Row"
import CentroCustoInput from "@/components/financeiro/components/input/CentroCustoInput"
import FinPessoaInput from "@/components/financeiro/components/input/PessoaInput"
import CategoriaHirerarquiaSelect from "@/components/financeiro/components/input/CategoriaHirerarquiaSelect"
import GlobalProcessoInput from "components/processos/components/processos/include/ProcessoInput"
import {filters} from "@/domain/leiloes/helpers/filters"
import StatusInternoSelect from "components/leiloes/components/include/StatusInternoLeilaoSelect.vue";
import DateInput from "@/reuse/input/Date.vue"
import TipoLeilaoSelect from "components/leiloes/components/include/TipoLeilaoSelect.vue";
import DefaultAsyncSelect from "@/reuse/input/DefaultAsyncSelect.vue"
import {listClassificacao, listLeiloeiros} from "@/domain/leiloes/services"

export default {
  name: "FinFiltrosAdicionais",
  inject: ['leilaoListContainer'],
  props: ['filters'],
  components: {
    DefaultAsyncSelect,
    TipoLeilaoSelect,
    DateInput,
    StatusInternoSelect,
    ERow,
    ECol,
    //MenuOptionsItem,
    UPopover,
    ErpSField,
    ErpInput,
    // ErpSelect,
  },
  mounted() {
    document.body.addEventListener('click', this.clickOutside, true)
  },
  watch: {
  },
  data() {
    return {
      prevent: false
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.clickOutside)
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.prevent) return
        !this.$refs.popover.showing ? this.$refs.popover.show() : this.$refs.popover.hide()
      })
    },
    activated() {
      this.$nextTick(() => {
        // this.$refs.input0.focus()
      })
    },
    buscar () {
      this.leilaoListContainer.table.filters.data1 = null
      this.leilaoListContainer.table.filters.data2 = null
      this.leilaoListContainer.table.filters.search = null
      this.leilaoListContainer.pesquisar()
    },
    buscarFechar () {
      this.leilaoListContainer.pesquisar()
      this.$refs.popover.hide()
    },
    reset () {
      this.leilaoListContainer.table.filters = JSON.parse(JSON.stringify(filters))
      this.$refs.popover.hide()
      this.leilaoListContainer.pesquisar()
    },
    clickOutside(evt) {
      if (
          evt && evt.target && this.$el &&
          (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
      ) {
        // this.leave()
        // this.$el.classList.add('focused')
        // this.focus()
        // placeCaretAtEnd(this.$refs.editor)
        console.log('Click outside')
        if (!this.$refs.popover.showing) return
        this.prevent = true
        setTimeout(() => {
          this.prevent = false
        }, 100)
        this.$refs.popover.hide()
        return true
      }
      console.log('Click inside')
      // this.$refs.popover.show()
      // this.hide(evt)
    },
    listClassificacao: listClassificacao,
    listLeiloeiros: listLeiloeiros
  }
}
</script>
