<template>
  <div class="m-t-lg">
    <div v-if="loading">
      <i class="fa fa-spin fa-spinner"></i> Aguarde enquanto verificamos a disponibilidade do ambiente.
    </div>
    <div v-if="disponibilidade">
      <div v-if="disponibilidade.statusAzul || disponibilidade.statusPorto">
        <div ref="terminal" class="text-white erp-terminal" style="max-height: 400px; height: 400px; overflow: auto">
          Ambientes disponíveis de Seguradoras: <br><br>
          Azul Seguros: <span v-if="disponibilidade.statusAzul">Sim</span><span v-else>Não</span><br>
          Porto Seguro: <span v-if="disponibilidade.statusPorto">Sim</span><span v-else>Não</span><br>
          Youse: <span v-if="disponibilidade.statusYouse">Sim</span><span v-else>Não</span><br>

          <div v-if="startCommand || finishCommand" class="m-t">
            <div v-for="message in buffer" :key="message.id" class="m-t-xs">
              [{{message.date.date|formatDate}}] {{message.message}}
            </div>
            <vue-typed-js v-if="!finishCommand" :typeSpeed="5" :strings="['Iniciando comunicação com o comitente...']">
              <div class="typing"></div>
            </vue-typed-js>
          </div>
        </div>

        <div class="m-t-sm">
          <div class="m-t-sm">
            <u-btn label="1 - Atualiza Lotes" @click="atualizaLotes" icon="exchange-alt" icon-type="fa" color="info"
                   class="m-r-xs"/>
            <u-btn label="2 - Atualiza Vendas" @click="atualizaVendas" icon="money-check" icon-type="fa" color="positive" class="m-r"/>
            <u-btn label="3 - Atualiza Arrematantes" @click="atualizaArrematantes" icon="user-friends" icon-type="fa" color="primary" class="m-r-xs"/>
            <u-btn @click="getBuffer(false, true)" label="Visualizar log" flat />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  checaDisponibilidade,
  commandLogBuffer,
  atualizaLotes,
  atualizaArrematantes,
  atualizaVendas
} from "@/domain/integra/services/grupo-porto"

export default {
  name: "GrupoPortoAcoesLeilao",
  props: ['leilao'],
  data() {
    return {
      loading: true,
      disponibilidade: null,
      startCommand: false,
      finishCommand: false,
      buffer: []
    }
  },
  mounted() {
    this.checaDisponibilidade()
    this.getBuffer(false, true)
  },
  watch: {
    // Caso você queira rolar sempre que o conteúdo mudar
    buffer() {
      this.$nextTick(() => {
        this.scrollToEnd()
      });
    }
  },
  methods: {
    checaDisponibilidade() {
      checaDisponibilidade()
          .then(({data}) => {
            this.loading = false
            this.disponibilidade = data
            console.log(data)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    atualizaLotes(leilao) {
      this.comando(leilao, 'atualizaLotes')
    },
    atualizaVendas(leilao) {
      this.comando(leilao, 'atualizaVendas')
    },
    atualizaArrematantes(leilao) {
      this.comando(leilao, 'atualizaArrematantes')
    },
    comando(leilao, comando) {
      let c = atualizaLotes
      if (comando === 'atualizaVendas') {
        c = atualizaVendas
      }
      if (comando === 'atualizaArrematantes') {
        c = atualizaArrematantes
      }
      this.startCommand = true
      this.finishCommand = false
      setTimeout(() => {
        this.scrollToEnd()
      }, 100)
      this.$commandInterval && clearInterval(this.$commandInterval)
      this.$commandInterval = setInterval(() => {
        this.getBuffer()
      }, 2000)
      setTimeout(() => {
        this.$commandInterval && clearInterval(this.$commandInterval)
      }, 60000)
      c(this.leilao.id)
          .then(({data}) => {
            console.log(data)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    getBuffer (force = false, noStart = false) {
      setTimeout(() => {
        this.scrollToEnd()
      }, 1000)
      if (force) {
        this.startCommand = true
        this.finishCommand = false
      }
      if (noStart) {
        this.finishCommand = true
      }
      commandLogBuffer(`entity=leilao&entityId=${this.leilao.id}&action=grupo-porto-atualiza-lotes&order=ASC`)
          .then(({data}) => {
            this.buffer = data
            if(!force) {
              const find = this.buffer.find(b => {
                return String(b.message).includes('[OK] Comando finalizado.')
              })
              if (find) {
                // this.$commandInterval && clearInterval(this.$commandInterval)
                this.startCommand = false
                this.finishCommand = true
              }
            }
          })
          .catch(error => {
            this.startCommand = false
            this.finishCommand = true
            console.log(error)
          })
    },
    scrollToEnd() {
      const terminal = this.$refs.terminal
      if (terminal) {
        terminal.scrollTop = terminal.scrollHeight
      }
    }
  },
  beforeDestroy() {
    this.$commandInterval && clearInterval(this.$commandInterval)
  },
}
</script>
