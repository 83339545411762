export default {
  menu: [
    {
      id: 'basic',
      title: 'Principal',
      icon: 'home',
      iconType: 'fa',
      iconStyle: 'light',
      class: '',
      route: 'integra.grupo-porto.main'
    },
    {
      id: 'consultaLeilao',
      title: 'Consulta Leilão',
      icon: 'search',
      iconType: 'fa',
      iconStyle: 'light',
      class: '',
      route: 'integra.grupo-porto.consultaLeilao'
    }
  ]
}
