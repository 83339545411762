<template>
  <li>
    <router-link tag="a" @click.native="handleClick" :to="routeData" active-class="active" exact class="console-page-link" :class="{disabled: disabled, active: checkChildActive}">
      <div class="micon"><i :class="icon"></i></div>
      <span class="lbl">{{ label }}</span>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "ConsoleMenuItem",
  props: ['icon', 'name', 'label', 'route', 'parentRoute', 'disabled'],
  computed: {
    routeData () {
      return this.route ? {name: this.route} : ''
    },
    checkChildActive () {
      return this.$route.matched.some(route => route.name === this.route || route.name === this.parentRoute)
    }
  },
  methods: {
    handleClick(event) {
      if (this.disabled) {
        event.preventDefault()
        event.stopImmediatePropagation()
      }
    }
  }
}
</script>
