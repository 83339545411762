import Vue from 'vue'
export const erpDefs = Vue.observable({
    loaded: false,
    invoices: [],
    dueInvoices: [],
    totalDue: 0,
    financialBlock: false,
    block: false,
    blockMessage: null
})
