import http from '../../../utils/services/http'

export const dashboard = () => {
    return http.get('/api/tarefas/resumeDashboard')
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const list = (limit, page, filtros, isSuporte = false, isGerencia = false) => {
    let url = '/api/tasks?page=' + page + '&limit=' + limit + filtros
    if (isGerencia) {
        url = url + '&req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id, isGerencia = false) => {
    let url = `/api/tasks/${id}`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newTask = (data, isGerencia = false) => {
    let url = `/api/tasks`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateTask = (task, method = 'PUT', isGerencia = false) => {
    let url = `/api/tasks/${task.id}`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, task)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const closeTask = (task, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/close`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    let _method = http.put
    return _method(url, task)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const reopenTask = (task, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/reopen`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    let _method = http.put
    return _method(url, task)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteTask = (id) => {
    let url = `/api/tasks/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const tiposStatus = (isGerencia = false) => {
    let url = '/api/tasks/status'
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const typeTask = (isGerencia = false) => {
    let url = '/api/tasks/type'
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const followTask = (task, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/follow`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const unfollowTask = (task, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/follow`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const createComment = (task, data, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/comment`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const uploadArquivoTask = (id, data, isGerencia = false) => {
    let url = `/api/tasks/${id}/arquivos`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadArquivoTask = (task, arquivo, inline = false, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/arquivos/${arquivo}/download?`
    if (inline) {
        url = url + '&inline=true'
    }
    if (isGerencia) {
        url = url + '&req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteArquivoTask = (task, arquivo, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/arquivos/${arquivo}/delete`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const historico = (task, tipo = null, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/historico?`
    if (tipo) {
        url = url + '&tipo=' + tipo
    }
    if (isGerencia) {
        url = url + '&req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showComment = (task, notaId, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/comments/${notaId}`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const showCommentLogByReferId = (task, referId, isGerencia = false) => {
    let url = `/api/tasks/${task.id}/comments/show-by-refer/${referId}`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listStatus = (limit, page, filtros, isGerencia = false) => {
    let url = '/api/tasks/status?page=' + page + '&limit=' + limit + filtros
    if (isGerencia) {
        url = url + '&req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findStatus = (id, isGerencia = false) => {
    let url = `/api/tasks/status/${id}`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newStatusTask = (data) => {
    let url = `/api/tasks/status`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateStatusTask = (id, data, method = 'PUT') => {
    let url = `/api/tasks/status/${id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteStatusTask = (id) => {
    let url = `/api/tasks/status/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listType = (limit, page, filtros, isGerencia = false) => {
    let url = '/api/tasks/type?page=' + page + '&limit=' + limit + filtros
    if (isGerencia) {
        url = url + '&req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findType = (id) => {
    let url = `/api/tasks/type/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newTypeTask = (data) => {
    let url = `/api/tasks/type`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateTypeTask = (id, data, method = 'PUT') => {
    let url = `/api/tasks/type/${id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteTypeTask = (id) => {
    let url = `/api/tasks/type/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const getTaskRelationData = (id, relation, isGerencia = false) => {
    let url = `/api/tasks/${id}/relation/${relation}`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listPriority = (limit, page, filtros, isGerencia = false) => {
    let url = '/api/tasks/priority?page=' + page + '&limit=' + limit + filtros
    if (isGerencia) {
        url = url + '&req-gerencia=1'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findPriority = (id) => {
    let url = `/api/tasks/priority/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newPriorityTask = (data) => {
    let url = `/api/tasks/priority`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updatePriorityTask = (id, data, method = 'PUT') => {
    let url = `/api/tasks/priority/${id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deletePriorityTask = (id) => {
    let url = `/api/tasks/priority/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const userStats = (participants, isGerencia = false) => {
    let url = `/api/tasks/user-stats`
    if (isGerencia) {
        url = url + '?req-gerencia=1'
    }
    return http.post(url, {
        participants: participants
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const work = (task, data) => {
    let url = `/api/tasks/${task.id}/work`
    let _method = http.post
    return _method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const completePart = (task, participant, data = null) => {
    let url = `/api/tasks/${task.id}/participant/${participant.id}/complete`
    let _method = http.post
    return _method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const dashboardSuporte = () => {
    let url = '/api/tasks/dashboard'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export default {
    dashboard
}
