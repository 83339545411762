<script>
import {UPopover} from 'uloc-vue'

export default {
  name: 'menu-options',
  props: {
    v2: {
      type: Boolean,
      default: false
    }
  },
  components: {UPopover},
  methods: {
    deleteTask (evt) {
      console.log('Delete task')
    }
  }
}
</script>

<template>
  <u-popover :class="v2 ? 'window-context-menu-v2' : 'window-context-menu menu-profile min'" anchor="bottom right" self="top right">
    <div> <!-- class="menu" -->
      <!--<ul>
          <task-menu-options-item @click="deleteTask" close label="Excluir tarefa" shortcut="TAB+DEL"/>
          <task-menu-options-item close label="Adicionar à reunião" shortcut="TAB+R"/>
          <task-menu-options-item close label="Adicionar ao projeto" shortcut="TAB+P"/>
          <task-menu-options-item close label="Duplicar"/>
          <li class="separator"></li>
          <task-menu-options-item close label="Imprimir"/>
      </ul>-->
      <slot />
    </div>
  </u-popover>
</template>
