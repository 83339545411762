<template>
  <nav class="menu-l header_menu_nav">
    <button @click="toogle" type="button" class="nav-btn" :class="{open: open}" aria-label="Toggle navigation" title="Menu">
      <span class="header-button-label">Serviços</span>
      <span class="header-button-arrow header-button-arrow_nav-toggle"></span>
    </button>
    <div v-if="open" class="main-menu-container">
      <ul class="main-menu">
        <nav-link @click="leilaoWindow(leilao.id)" label="Editar Leilão" icon-type="fa" icon="edit" icon-style="regular" />
        <nav-link :route="{name: 'leiloes.dashboard'}" label="Resumo" icon-type="fa" icon="grid" icon-style="regular" />
        <nav-link :route="{name: 'leiloes.show'}" label="Lotes" icon-type="erp" icon="metas" icon-style="regular" />
        <nav-link @click="sincronizarSites" label="Sincronizar Leilão com Site(s)" icon-type="erp" icon="api" icon-style="regular" />
        <nav-link @click="ordenarLotes" label="Organizar lotes" icon-type="fa" icon="arrow-up-1-9" icon-style="regular" />
        <nav-link icon="tasks" icon-type="fa" label="Automação" has-submenu>
          <template v-slot:submenu>
            <automacao-submenu @alterarStatusLotesDialog="alterarStatusLoteEmMassa" />
          </template>
        </nav-link>
        <nav-link icon-type="erp" icon="terminal" label="Marketplace API" has-submenu>
          <template v-slot:submenu>
            <marketplace-api-submenu />
          </template>
        </nav-link>
        <nav-link :route="{name: 'leiloes.controlador'}" label="Controle do Leilão" icon-type="fa" icon="gamepad" icon-style="light" icon-class="fa-duotone" />
        <nav-link :route="{name: 'leiloes.tela-leiloeiro'}" label="Painel do Leiloeiro" icon-type="fa" icon="id-card" icon-style="regular" icon-class="fa-duotone" />
        <nav-link :route="{name: 'leiloes.monitor-comitente'}" label="Monitor Comitente" icon-type="fa" icon="user-tie-hair" icon-style="light" icon-class="fa-duotone" />
        <nav-link icon="tv-retro" icon-type="fa" label="Transmissão" has-submenu>
          <template v-slot:submenu>
            <transmissao-submenu />
          </template>
        </nav-link>
        <nav-link :route="{name: 'leiloes.habilitacoes'}" label="Habilitações" icon-type="erp" icon="approved" />
        <nav-link :route="{name: 'leiloes.lances.automaticos'}" label="Lances Automáticos" icon-type="fa" icon="robot" />
        <nav-link icon="contract" icon-type="erp" label="Fechamento" has-submenu>
          <template v-slot:submenu>
            <fechamento-submenu />
          </template>
        </nav-link>
        <nav-link @click.native="$router.push({name: 'leiloes.relatorios'})" icon="reports" icon-type="erp" label="Relatórios" close-on-click has-submenu>
          <template v-slot:submenu>
            <relatorios-submenu />
          </template>
        </nav-link>
      </ul>
    </div>
    <alterar-status-lote-massa-dialog ref="alterarStatusLotesDialog" />
  </nav>
</template>

<script>
import NavLink from './NavLink'
// import ArrematanteSubmenu from './ArrematanteSubmenu'
// import ComitenteSubmenu from './ComitenteSubmenu'
import TransmissaoSubmenu from "@/components/leiloes/components/layout-components/TransmissaoSubmenu"
import FechamentoSubmenu from "@/components/leiloes/components/layout-components/FechamentoSubmenu"
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
import RelatoriosSubmenu from "@/components/leiloes/components/layout-components/RelatoriosSubmenu"
import {sincronizarSites, prepararLeilao} from "@/domain/leiloes/services"
import AutomacaoSubmenu from "components/leiloes/components/layout-components/AutomacaoSubmenu"
import MarketplaceApiSubmenu from "components/leiloes/components/layout-components/MarketplaceApiSubmenu"
import AlterarStatusLoteMassaDialog from "components/leiloes/components/include/AlterarStatusLoteMassaDialog"
import leilaoWindow from "components/leiloes/windows/leilao"
export default {
  name: 'NavBtn',
  mixins: [LeilaoMixin],
  provide: function () {
    return {
      nav: this
    }
  },
  components: {
    MarketplaceApiSubmenu,
    AutomacaoSubmenu,
    RelatoriosSubmenu,
    FechamentoSubmenu,
    TransmissaoSubmenu,
    // ComitenteSubmenu,
    // ArrematanteSubmenu,
    NavLink,
    AlterarStatusLoteMassaDialog
  },
  data () {
    return {
      open: false
    }
  },
  mounted () {
    document.body.addEventListener('click', this.clickOutside, true)
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.clickOutside)
  },
  methods: {
    toogle () {
      this.open = !this.open
    },
    clickEvent ($event) {
      this.toogle()
    },
    clickOutside (evt) {
      if (
        evt && evt.target && this.$el &&
        (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
      ) {
        return
      }
      this.open = false
    },
    sincronizarSites () {
      console.log('Sincronizando site(s)...')
      this.$uloc.loading.show({message: 'Sincronizando site(s)'})
      sincronizarSites(this.leilao.id)
          .then(response => {
            this.$uloc.loading.hide()
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Sincronização realizada com sucesso!'})
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    ordenarLotes () {
      console.log('Ordenando lotes...')
      this.$uloc.loading.show({message: 'Ordenando lotes'})
      prepararLeilao(this.leilao.id)
          .then(response => {
            this.$uloc.loading.hide()
            this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Loteamento organizado com sucesso!'})
          })
          .catch(error => {
            this.$uloc.loading.hide()
            this.alertApiError(error)
          })
    },
    alterarStatusLoteEmMassa () {
      this.$refs.alterarStatusLotesDialog.open()
    },
    leilaoWindow
  }
}
</script>
