<template>
    <erp-select
        @input="__set"
        :multiple="multiple"
        :placeholder="placeholder || 'Qualquer'"
        :options="status"
        v-model="model"
        propoverClass="select-status-def1"
        :disable="disable"
        :readonly="readonly"
        :style="inputStyle"
        :simple-border="simpleBorder"
        :class="{'bg-yellow-2 font-bold': isNaoPago}"
    >
      <template v-slot:itemLabel="props">
        <status-lote :lote="mapperProps(getProps(props.opt))" />
      </template>
    </erp-select>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {Status, STATUS_VENDIDO} from '@/domain/leiloes/helpers/LoteStatus'
import {Status as StatusP, STATUS_PENDENTE} from '../../../../domain/leiloes/helpers/PagamentoStatus'
import StatusLote from './StatusLote'

export default {
  name: 'StatusLoteSelectNoField',
  mixins: [SelectMixin],
  props: {
    lote: {required: false}
  },
  data () {
    return {
    }
  },
  computed: {
    isNaoPago () {
      if(!this.lote){
        return false
      }
      return this.lote.arremate && this.lote.arremate.status !== 2
    },
    status () {
      return Object.keys(Status).map((k) => {
        let title = Status[k].title
        if (this.lote && Number(k) === STATUS_VENDIDO) {
          if (this.isNaoPago) {
            title = StatusP[STATUS_PENDENTE].title
          }
        }
        return {
          label: title,
          value: Number(k),
          class: typeof Status[k].title !== 'undefined' ? Status[k].class : null
        }
      })
    }
  },
  components: {StatusLote},
  methods: {
    getProps (opt) {
      if (this.lote) {
        if (opt.value === this.lote.status) {
          opt = Object.assign(opt, this.lote)
        }
      }
      return opt
    },
    __updateInternal () {
      this.$nextTick(() => {
        if (this.value !== 'undefined' && this.value !== null && this.model === null) {
          let _cp = JSON.stringify(this.value) // force to update datepicker with actual value
          this.model = ''
          setTimeout(() => {
            this.model = JSON.parse(_cp)
          }, 1)
        }
      })
    }
  }
}
</script>

<style lang="stylus">
  .select-status-def1 {
    td {
      display flex
      justify-items center
    }
  }
</style>
