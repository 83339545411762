export const filters = {
    id: null,
    descricao: null,
    codigo: null,
    tipo: null,
    classificacao: null,
    data1: null,
    data2: null,
    leiloeiro: null,
    status: [0, 1, 2, 3, 4],
    statusInterno: [],
    modulo: null,
    typeFilter: null,
    tipoEvento: null
}
