<template>
  <u-icon style="font-size: 12px" @click.native="$refs.popover.toggle()" name="filter" type="fa" icon-style="solid" class="m-r cursor-pointer">
    <u-tooltip :offset="[5,5]">Filtros adicionais</u-tooltip>
    <!--    Mais filtros-->
    <u-popover :anchor-click="false" disable-close-on-click-outside @show="activated" ref="popover" class="window-context-menu wrapper min popover-effect task-filtro-mais-popover" anchor="bottom left" self="top left"
               :offset="[0, 10]">
      <div class="simple-menu m-t-xs flex justify-between column full-width">
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Cliente"
                label-width="90px"
            >
              <global-pessoa-input @keydown.enter.native="buscar" v-model="filters.cliente" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="lr"
                label="Processo"
                label-width="90px"
            >
              <global-processo-input v-model="filters.processoNumero"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <task-priority-select size="2" field-view="lr" label-width="90px" @keydown.enter.native="buscar" v-model="filters.priority" />
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
           <tribunal-select size="2" field-view="lr" label-width="90px" v-model="filters.tribunal" />
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
           <comarca-select size="2" field-view="lr" label-width="90px" v-model="filters.comarca" :tribunal="filters.tribunal" :limit="10000" />
          </e-col>
        </e-row>
        <e-row class="col-grow-1" mr>
          <e-col style="min-width: 170px" class="m-l m-r">
            <vara-select size="2" field-view="lr" label-width="90px" v-model="filters.vara" :comarca="filters.comarca" />
          </e-col>
        </e-row>
        <e-row class="m-t" mr>
          <e-col class="text-right">
            <a @click="reset" class="m-r font-12">Remover filtros</a>
            <e-btn label="Buscar" @click="buscarFechar" />
          </e-col>
        </e-row>
      </div>
    </u-popover>
  </u-icon>
</template>

<script>
import {UPopover} from "uloc-vue"
import {ErpInput, ErpSField} from "uloc-vue-plugin-erp"
import ECol from "@/components/layout/components/Col"
import ERow from "@/components/layout/components/Row"
import GlobalPessoaInput from "@/components/pessoa/components/include/pessoa/PessoaInput"
import TaskPrioritySelect from "@/components/sltarefas/components/inputs/TaskPrioritySelect"
import TribunalSelect from "@/components/processos/components/processos/include/TribunalSelect"
import ComarcaSelect from "@/components/processos/components/processos/include/ComarcaSelect"
import VaraSelect from "@/components/processos/components/processos/include/VaraSelect"
import GlobalProcessoInput from "components/processos/components/processos/include/ProcessoInput.vue"
import filters from "@/domain/tarefas/helpers/filters"

export default {
  name: "TaskFiltrosAdicionais",
  inject: ['container'],
  props: ['filters'],
  components: {
    GlobalProcessoInput,
    VaraSelect,
    ComarcaSelect,
    TribunalSelect,
    TaskPrioritySelect,
    GlobalPessoaInput,
    ERow,
    ECol,
    UPopover,
    ErpSField,
    //ErpInput
  },
  mounted() {
  },
  watch: {
  },
  data() {
    return {
    }
  },
  methods: {
    activated() {
      this.$nextTick(() => {
        // this.$refs.input0.focus()
      })
    },
    buscar () {
      this.container.pesquisar()
    },
    buscarFechar () {
      this.container.pesquisar()
      this.$refs.popover.hide()
    },
    reset () {
      this.container.table.filters = JSON.parse(JSON.stringify(filters))
      this.$refs.popover.hide()
      this.container.pesquisar()
    }
  }
}
</script>
