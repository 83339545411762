<template>
  <div class="lista-lotes-filtros">
    <div class="contadores">
      <div class="count cl">
        <div class="lbl">Com Lances</div>
        <div class="val">{{ listPage.stats.comLance ?? 0 }}</div>
      </div>
      <div class="count ret">
        <div class="lbl">Retirados</div>
        <div class="val">{{ listPage.stats.retirados ?? 0 }}</div>
      </div>
      <div class="count ok">
        <div class="lbl">Vendidos</div>
        <div class="val">{{ listPage.stats.vendidos ?? 0 }}</div>
      </div>
      <div class="count con">
        <div class="lbl">Não Vendidos</div>
        <div class="val">{{ listPage.stats.naoVendidos ?? 0 }}</div>
      </div>
      <div class="count sl">
        <div class="lbl">Condicional</div>
        <div class="val">{{ listPage.stats.condicional ?? 0 }}</div>
      </div>
    </div>
    <div class="filtros">
      <e-row mr>
        <!--<e-col style="max-width: 120px">
          <erp-s-field label="Filtros adicionais">
          </erp-s-field>
        </e-col>-->
        <e-col style="max-width: 200px">
          <erp-s-field label="Filtros pelo status" class="m-r">
            <status-lote-select-no-field no-field multiple v-model="listPage.table.filters.status" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Mostrar fotos">
            <sl-checkbox v-model="listPage.table.filters.mostrarFotos" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 250px; min-width: 200px; display: flex; align-items: flex-end">
          <erp-input placeholder="Pesquisa inteligente nos lotes" class="busca" v-model="listPage.table.busca" @keydown.enter="listPage.load()" :after="[{icon: 'search'}]" />
        </e-col>
      </e-row>
    </div>
  </div>
</template>

<script>
import ERow from "components/layout/components/Row.vue"
import ECol from "components/layout/components/Col.vue"
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import SlCheckbox from "@/reuse/input/Checkbox.vue"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import StatusLoteSelectNoField from "components/leiloes/components/include/StatusLoteSelectNoField.vue";

export default {
  name: "FiltrosListaLotes",
  inject: ['listPage'],
  components: {
    StatusLoteSelectNoField,
    ErpInput,
    SlCheckbox,
    ErpSField,
    ERow,
    ECol
  }
}
</script>

<style scoped lang="stylus">

</style>
