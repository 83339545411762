<template>
  <ul>
    <menu-options-item close label="Abrir" @click="$emit('abrir')" icon="folder-open" />
    <menu-options-item v-if="vistoriador.active" close label="Desativar" @click="$emit('desativarAtivar')" icon="times" />
    <menu-options-item v-else close label="Reativar" @click="$emit('desativarAtivar')" icon="check" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'vistoriador-list-menu',
  props: ['vistoriador'],
  components: {MenuOptionsItem}
}
</script>
