export default [
    { name: 'Brasil', code: 'BR', ddi: '55', nat: 'Brasileiro' },
    { name: 'Afeganistão', code: 'AF', ddi: '93', nat: 'Afegão' },
    { name: 'Albânia', code: 'AL', ddi: '355', nat: 'Albanês' },
    { name: 'Alemanha', code: 'DE', ddi: '49', nat: 'Alemão' },
    { name: 'Andorra', code: 'AD', ddi: '376', nat: 'Andorrano' },
    { name: 'Angola', code: 'AO', ddi: '244', nat: 'Angolano' },
    { name: 'Arábia Saudita', code: 'SA', ddi: '966', nat: 'Saudita' },
    { name: 'Argélia', code: 'DZ', ddi: '213', nat: 'Argelino' },
    { name: 'Argentina', code: 'AR', ddi: '54', nat: 'Argentino' },
    { name: 'Armênia', code: 'AM', ddi: '374', nat: 'Armênio' },
    { name: 'Austrália', code: 'AU', ddi: '61', nat: 'Australiano' },
    { name: 'Áustria', code: 'AT', ddi: '43', nat: 'Austríaco' },
    { name: 'Azerbaijão', code: 'AZ', ddi: '994', nat: 'Azeri' },
    { name: 'Bahamas', code: 'BS', ddi: '1-242', nat: 'Bahamense' },
    { name: 'Bahrein', code: 'BH', ddi: '973', nat: 'Bareinita' },
    { name: 'Bangladesh', code: 'BD', ddi: '880', nat: 'Bangladeshiano' },
    { name: 'Barbados', code: 'BB', ddi: '1-246', nat: 'Barbadense' },
    { name: 'Bélgica', code: 'BE', ddi: '32', nat: 'Belga' },
    { name: 'Belize', code: 'BZ', ddi: '501', nat: 'Belizenho' },
    { name: 'Benim', code: 'BJ', ddi: '229', nat: 'Beninense' },
    { name: 'Bielorrússia', code: 'BY', ddi: '375', nat: 'Bielorrusso' },
    { name: 'Bolívia', code: 'BO', ddi: '591', nat: 'Boliviano' },
    { name: 'Bósnia e Herzegovina', code: 'BA', ddi: '387', nat: 'Bósnio' },
    { name: 'Botsuana', code: 'BW', ddi: '267', nat: 'Betsuano' },
    { name: 'Burquina Faso', code: 'BF', ddi: '226', nat: 'Burquinense' },
    { name: 'Burundi', code: 'BI', ddi: '257', nat: 'Burundês' },
    { name: 'Butão', code: 'BT', ddi: '975', nat: 'Butanês' },
    { name: 'Cabo Verde', code: 'CV', ddi: '238', nat: 'Cabo-verdiano' },
    { name: 'Camarões', code: 'CM', ddi: '237', nat: 'Camarones' },
    { name: 'Camboja', code: 'KH', ddi: '855', nat: 'Cambojano' },
    { name: 'Canadá', code: 'CA', ddi: '1', nat: 'Canadense' },
    { name: 'Catar', code: 'QA', ddi: '974', nat: 'Catarense' },
    { name: 'Cazaquistão', code: 'KZ', ddi: '7', nat: 'Cazaque' },
    { name: 'Chade', code: 'TD', ddi: '235', nat: 'Chadiano' },
    { name: 'Chile', code: 'CL', ddi: '56', nat: 'Chileno' },
    { name: 'China', code: 'CN', ddi: '86', nat: 'Chinês' },
    { name: 'Chipre', code: 'CY', ddi: '357', nat: 'Cipriota' },
    { name: 'Colômbia', code: 'CO', ddi: '57', nat: 'Colombiano' },
    { name: 'Comores', code: 'KM', ddi: '269', nat: 'Comoriano' },
    { name: 'Congo (Congo-Brazzaville)', code: 'CG', ddi: '242', nat: 'Congolês' },
    { name: 'Coreia do Norte', code: 'KP', ddi: '850', nat: 'Norte-coreano' },
    { name: 'Coreia do Sul', code: 'KR', ddi: '82', nat: 'Sul-coreano' },
    { name: 'Costa Rica', code: 'CR', ddi: '506', nat: 'Costarriquenho' },
    { name: 'Croácia', code: 'HR', ddi: '385', nat: 'Croata' },
    { name: 'Cuba', code: 'CU', ddi: '53', nat: 'Cubano' },
    { name: 'Dinamarca', code: 'DK', ddi: '45', nat: 'Dinamarquês' },
    { name: 'Djibuti', code: 'DJ', ddi: '253', nat: 'Djibutiano' },
    { name: 'Dominica', code: 'DM', ddi: '1-767', nat: 'Dominiquense' },
    { name: 'Egito', code: 'EG', ddi: '20', nat: 'Egípcio' },
    { name: 'El Salvador', code: 'SV', ddi: '503', nat: 'Salvadorenho' },
    { name: 'Emirados Árabes Unidos', code: 'AE', ddi: '971', nat: 'Emiradense' },
    { name: 'Equador', code: 'EC', ddi: '593', nat: 'Equatoriano' },
    { name: 'Eritreia', code: 'ER', ddi: '291', nat: 'Eritreu' },
    { name: 'Eslováquia', code: 'SK', ddi: '421', nat: 'Eslovaco' },
    { name: 'Eslovênia', code: 'SI', ddi: '386', nat: 'Esloveno' },
    { name: 'Espanha', code: 'ES', ddi: '34', nat: 'Espanhol' },
    { name: 'Eswatini', code: 'SZ', ddi: '268', nat: 'Swazi' },
    { name: 'Estados Unidos da América', code: 'US', ddi: '1', nat: 'Americano' },
    { name: 'Estônia', code: 'EE', ddi: '372', nat: 'Estoniano' },
    { name: 'Etiópia', code: 'ET', ddi: '251', nat: 'Etíope' },
    { name: 'Fiji', code: 'FJ', ddi: '679', nat: 'Fijiano' },
    { name: 'Filipinas', code: 'PH', ddi: '63', nat: 'Filipino' },
    { name: 'Finlândia', code: 'FI', ddi: '358', nat: 'Finlandês' },
    { name: 'França', code: 'FR', ddi: '33', nat: 'Francês' },
    { name: 'Gabão', code: 'GA', ddi: '241', nat: 'Gabonesa' },
    { name: 'Gâmbia', code: 'GM', ddi: '220', nat: 'Gambiano' },
    { name: 'Gana', code: 'GH', ddi: '233', nat: 'Ganês' },
    { name: 'Geórgia', code: 'GE', ddi: '995', nat: 'Georgiano' },
    { name: 'Granada', code: 'GD', ddi: '1-473', nat: 'Granadino' },
    { name: 'Grécia', code: 'GR', ddi: '30', nat: 'Grego' },
    { name: 'Guatemala', code: 'GT', ddi: '502', nat: 'Guatemalteco' },
    { name: 'Guiana', code: 'GY', ddi: '592', nat: 'Guyanês' },
    { name: 'Guiné', code: 'GN', ddi: '224', nat: 'Guineense' },
    { name: 'Guiné Equatorial', code: 'GQ', ddi: '240', nat: 'Equato-guineense' },
    { name: 'Guiné-Bissau', code: 'GW', ddi: '245', nat: 'Guineense' },
    { name: 'Haiti', code: 'HT', ddi: '509', nat: 'Haitiano' },
    { name: 'Honduras', code: 'HN', ddi: '504', nat: 'Hondurenho' },
    { name: 'Hungria', code: 'HU', ddi: '36', nat: 'Húngaro' },
    { name: 'Iêmen', code: 'YE', ddi: '967', nat: 'Iemenita' },
    { name: 'Ilhas Marshall', code: 'MH', ddi: '692', nat: 'Marshalês' },
    { name: 'Ilhas Salomão', code: 'SB', ddi: '677', nat: 'Salomônico' },
    { name: 'Índia', code: 'IN', ddi: '91', nat: 'Indiano' },
    { name: 'Indonésia', code: 'ID', ddi: '62', nat: 'Indonésio' },
    { name: 'Irã', code: 'IR', ddi: '98', nat: 'Iraniano' },
    { name: 'Iraque', code: 'IQ', ddi: '964', nat: 'Iraquiano' },
    { name: 'Irlanda', code: 'IE', ddi: '353', nat: 'Irlandês' },
    { name: 'Islândia', code: 'IS', ddi: '354', nat: 'Islandês' },
    { name: 'Israel', code: 'IL', ddi: '972', nat: 'Israelense' },
    { name: 'Itália', code: 'IT', ddi: '39', nat: 'Italiano' },
    { name: 'Jamaica', code: 'JM', ddi: '1-876', nat: 'Jamaicano' },
    { name: 'Japão', code: 'JP', ddi: '81', nat: 'Japonês' },
    { name: 'Jordânia', code: 'JO', ddi: '962', nat: 'Jordano' },
    { name: 'Kiribati', code: 'KI', ddi: '686', nat: 'Kiribatiano' },
    { name: 'Kosovo', code: 'XK', ddi: '383', nat: 'Kosovar' },
    { name: 'Kuwait', code: 'KW', ddi: '965', nat: 'Kuwaitiano' },
    { name: 'Laos', code: 'LA', ddi: '856', nat: 'Laosiano' },
    { name: 'Lesoto', code: 'LS', ddi: '266', nat: 'Lesotiano' },
    { name: 'Letônia', code: 'LV', ddi: '371', nat: 'Letão' },
    { name: 'Líbano', code: 'LB', ddi: '961', nat: 'Libanês' },
    { name: 'Libéria', code: 'LR', ddi: '231', nat: 'Liberiano' },
    { name: 'Líbia', code: 'LY', ddi: '218', nat: 'Líbio' },
    { name: 'Liechtenstein', code: 'LI', ddi: '423', nat: 'Liechtensteinense' },
    { name: 'Lituânia', code: 'LT', ddi: '370', nat: 'Lituano' },
    { name: 'Luxemburgo', code: 'LU', ddi: '352', nat: 'Luxemburguês' },
    { name: 'Madagascar', code: 'MG', ddi: '261', nat: 'Malgaxe' },
    { name: 'Malásia', code: 'MY', ddi: '60', nat: 'Malaio' },
    { name: 'Malaui', code: 'MW', ddi: '265', nat: 'Malauiano' },
    { name: 'Maldivas', code: 'MV', ddi: '960', nat: 'Maldivo' },
    { name: 'Mali', code: 'ML', ddi: '223', nat: 'Maliano' },
    { name: 'Malta', code: 'MT', ddi: '356', nat: 'Maltês' },
    { name: 'Marrocos', code: 'MA', ddi: '212', nat: 'Marroquino' },
    { name: 'Maurícia', code: 'MU', ddi: '230', nat: 'Mauriciano' },
    { name: 'Mauritânia', code: 'MR', ddi: '222', nat: 'Mauritano' },
    { name: 'México', code: 'MX', ddi: '52', nat: 'Mexicano' },
    { name: 'Micronésia', code: 'FM', ddi: '691', nat: 'Micronésio' },
    { name: 'Moçambique', code: 'MZ', ddi: '258', nat: 'Moçambicano' },
    { name: 'Moldávia', code: 'MD', ddi: '373', nat: 'Moldavo' },
    { name: 'Mônaco', code: 'MC', ddi: '377', nat: 'Monegasco' },
    { name: 'Mongólia', code: 'MN', ddi: '976', nat: 'Mongol' },
    { name: 'Montenegro', code: 'ME', ddi: '382', nat: 'Montenegrino' },
    { name: 'Myanmar (Birmânia)', code: 'MM', ddi: '95', nat: 'Birmanês' },
    { name: 'Namíbia', code: 'NA', ddi: '264', nat: 'Namibiano' },
    { name: 'Nauru', code: 'NR', ddi: '674', nat: 'Nauruano' },
    { name: 'Nepal', code: 'NP', ddi: '977', nat: 'Nepalês' },
    { name: 'Nicarágua', code: 'NI', ddi: '505', nat: 'Nicaraguense' },
    { name: 'Níger', code: 'NE', ddi: '227', nat: 'Nigerino' },
    { name: 'Nigéria', code: 'NG', ddi: '234', nat: 'Nigeriano' },
    { name: 'Noruega', code: 'NO', ddi: '47', nat: 'Norueguês' },
    { name: 'Nova Zelândia', code: 'NZ', ddi: '64', nat: 'Neozelandês' },
    { name: 'Omã', code: 'OM', ddi: '968', nat: 'Omanense' },
    { name: 'Países Baixos', code: 'NL', ddi: '31', nat: 'Holandês' },
    { name: 'Palau', code: 'PW', ddi: '680', nat: 'Palauense' },
    { name: 'Palestina', code: 'PS', ddi: '970', nat: 'Palestino' },
    { name: 'Panamá', code: 'PA', ddi: '507', nat: 'Panamenho' },
    { name: 'Papua Nova Guiné', code: 'PG', ddi: '675', nat: 'Papuásio' },
    { name: 'Paquistão', code: 'PK', ddi: '92', nat: 'Paquistanês' },
    { name: 'Paraguai', code: 'PY', ddi: '595', nat: 'Paraguaio' },
    { name: 'Peru', code: 'PE', ddi: '51', nat: 'Peruano' },
    { name: 'Polônia', code: 'PL', ddi: '48', nat: 'Polonês' },
    { name: 'Portugal', code: 'PT', ddi: '351', nat: 'Português' },
    { name: 'Quênia', code: 'KE', ddi: '254', nat: 'Queniano' },
    { name: 'Quirguistão', code: 'KG', ddi: '996', nat: 'Quirguiz' },
    { name: 'Quiribati', code: 'KI', ddi: '686', nat: 'Kiribatiano' },
    { name: 'Reino Unido', code: 'GB', ddi: '44', nat: 'Britânico' },
    { name: 'República Centro-Africana', code: 'CF', ddi: '236', nat: 'Centro-africano' },
    { name: 'República Democrática do Congo', code: 'CD', ddi: '243', nat: 'Congolês' },
    { name: 'República Dominicana', code: 'DO', ddi: '1-809', nat: 'Dominicano' },
    { name: 'Romênia', code: 'RO', ddi: '40', nat: 'Romeno' },
    { name: 'Ruanda', code: 'RW', ddi: '250', nat: 'Ruandês' },
    { name: 'Rússia', code: 'RU', ddi: '7', nat: 'Russo' },
    { name: 'São Cristóvão e Nevis', code: 'KN', ddi: '1-869', nat: 'Cristofense' },
    { name: 'Santa Lúcia', code: 'LC', ddi: '1-758', nat: 'Santa-lucense' },
    { name: 'São Tomé e Príncipe', code: 'ST', ddi: '239', nat: 'São-tomense' },
    { name: 'São Vicente e Granadinas', code: 'VC', ddi: '1-784', nat: 'São-vicentino' },
    { name: 'Seicheles', code: 'SC', ddi: '248', nat: 'Seichelense' },
    { name: 'Senegal', code: 'SN', ddi: '221', nat: 'Senegalês' },
    { name: 'Serra Leoa', code: 'SL', ddi: '232', nat: 'Serra-leonês' },
    { name: 'Sérvia', code: 'RS', ddi: '381', nat: 'Sérvio' },
    { name: 'Singapura', code: 'SG', ddi: '65', nat: 'Singapurense' },
    { name: 'Síria', code: 'SY', ddi: '963', nat: 'Sírio' },
    { name: 'Somália', code: 'SO', ddi: '252', nat: 'Somali' },
    { name: 'Sri Lanka', code: 'LK', ddi: '94', nat: 'Cingalês' },
    { name: 'Sudão', code: 'SD', ddi: '249', nat: 'Sudanês' },
    { name: 'Sudão do Sul', code: 'SS', ddi: '211', nat: 'Sul-sudanês' },
    { name: 'Suécia', code: 'SE', ddi: '46', nat: 'Sueco' },
    { name: 'Suíça', code: 'CH', ddi: '41', nat: 'Suíço' },
    { name: 'Suriname', code: 'SR', ddi: '597', nat: 'Surinamês' },
    { name: 'Tailândia', code: 'TH', ddi: '66', nat: 'Tailandês' },
    { name: 'Taiwan', code: 'TW', ddi: '886', nat: 'Taiwanês' },
    { name: 'Tajiquistão', code: 'TJ', ddi: '992', nat: 'Tajique' },
    { name: 'Tanzânia', code: 'TZ', ddi: '255', nat: 'Tanzaniano' },
    { name: 'Timor-Leste', code: 'TL', ddi: '670', nat: 'Timorense' },
    { name: 'Togo', code: 'TG', ddi: '228', nat: 'Togolês' },
    { name: 'Tonga', code: 'TO', ddi: '676', nat: 'Tongalês' },
    { name: 'Trindade e Tobago', code: 'TT', ddi: '1-868', nat: 'Trinitário' },
    { name: 'Tunísia', code: 'TN', ddi: '216', nat: 'Tunisiano' },
    { name: 'Turcomenistão', code: 'TM', ddi: '993', nat: 'Turcomeno' },
    { name: 'Turquia', code: 'TR', ddi: '90', nat: 'Turco' },
    { name: 'Tuvalu', code: 'TV', ddi: '688', nat: 'Tuvaluano' },
    { name: 'Ucrânia', code: 'UA', ddi: '380', nat: 'Ucraniano' },
    { name: 'Uganda', code: 'UG', ddi: '256', nat: 'Ugandense' },
    { name: 'Uruguai', code: 'UY', ddi: '598', nat: 'Uruguaio' },
    { name: 'Uzbequistão', code: 'UZ', ddi: '998', nat: 'Uzbeque' },
    { name: 'Vanuatu', code: 'VU', ddi: '678', nat: 'Vanuatuano' },
    { name: 'Vaticano', code: 'VA', ddi: '39', nat: 'Vaticano' },
    { name: 'Venezuela', code: 'VE', ddi: '58', nat: 'Venezuelano' },
    { name: 'Vietnã', code: 'VN', ddi: '84', nat: 'Vietnamita' },
    { name: 'Zâmbia', code: 'ZM', ddi: '260', nat: 'Zambiano' },
    { name: 'Zimbábue', code: 'ZW', ddi: '263', nat: 'Zimbabuano' }
]
