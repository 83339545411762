export default function (remocao, id) {
  let window = id ? `solicitacao-remocao.${id}` : 'solicitacao-remocao.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Solicitação de Remoção' + (id ? ` #${id}` : ''),
    width: '1000',
    height: '723',
    minHeight: '500px',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: '__overflow-hidden',
    props: {
      remocao: remocao,
      id: id,
      remocaoContainer: this.remocaoContainer
    }
  }, () => import('../components/window/Solicitacao.vue'))
    .then((wid) => {
      console.log(wid)
      this.$uloc.window.listen(wid, {
        update: (wid, val) => {
          this.load && this.load()
        },
      })
    }) // return wid
}
