export default function (id) {
  let window = id ? `comitente.${id}` : 'comitente.new'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Comitente' + (id ? ` #${id}` : ''),
    width: '1000',
    height: '800',
    minHeight: '500px',
    backdrop: false,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden',
    props: {
      id: id,
      listClassificacao: this.listClassificacao,
      listLeiloeiros: this.listLeiloeiros,
      router: this.$router
    }
  }, () => import('../components/window/Comitente.vue'))
    .then((wid) => {
      console.log(wid)
    }) // return wid
}
