import http from '../../../utils/services/http'

export const dashboard = () => {
  return http.get('/api/reboquista/resumeDashboard')
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const list = (limit, page, filtros, basic) => {
  let url = '/api/reboquistas?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/reboquistas/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newReboquista = (data) => {
  let url = `/api/reboquistas`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateReboquista = (id, data, method = 'PUT') => {
  let url = `/api/reboquistas/${id}`
  let m = http.put
  if (method === 'PATCH') {
    m = http.patch
  }
  return m(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateFotoReboquista = (id, data) => {
  let url = `/api/reboquistas/${id}/photo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listReboques = (reboquista, limit, page, filtros, basic) => {
  let url = `/api/reboquistas/${reboquista}/reboques?page=` + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findReboque = (reboquista, id) => {
  let url = `/api/reboquistas/${reboquista}/reboques/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newReboque = (reboquista, data) => {
  let url = `/api/reboquistas/${reboquista}/reboques`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateReboque = (reboquista, id, data) => {
  let url = `/api/reboquistas/${reboquista}/reboques/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteReboque = (reboquista, id) => {
  let url = `/api/reboquistas/${reboquista}/reboques/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export default {
  dashboard,
  list,
  find
}
