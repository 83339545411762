import { render, staticRenderFns } from "./HistoricoProcessos.vue?vue&type=template&id=72f7c33a"
import script from "./HistoricoProcessos.vue?vue&type=script&lang=js"
export * from "./HistoricoProcessos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports