<template>
  <div>
    <u-btn @click="toggle" color="blue-grey-1" size="sm" class="app-btn text-blue-grey-5" no-caps>
      <!--    Mais filtros-->
      <u-icon name="filter" class="m-l__ m-r-sm fin-icon-filter" type="fa" /> <span>Mais Filtros</span>
      <u-popover :anchor-click="false" disable-close-on-click-outside @show="activated" ref="popover" class="window-context-menu wrapper min popover-effect" style="min-height: 500px" anchor="bottom left" self="top left"
                 :offset="[0, 5]">
        <div class="simple-menu m-t-xs flex justify-between column full-width e-input-modern size1">
          <e-row class="col-grow-1 m-t" mr>
            <e-col style="max-width: 260px" class="m-r m-l">
              <erp-s-field label="UF">
                <uf-select :city="filters.cidade" v-model="filters.uf" @preventCitySelected="(city) => filters.cidade = city" />
              </erp-s-field>
            </e-col>
            <e-col style="max-width: 260px" class="m-r m-l">
              <erp-s-field label="Cidade">
                <cidade-select :uf="filters.uf" v-model="filters.cidade" />
              </erp-s-field>
            </e-col>
          </e-row>
          <e-row class="col-grow-1 m-t" mr>
            <e-col style="min-width: 170px" class="m-l">
              <date-input label="Data de cadastro (De)" v-model="filters.data1" />
            </e-col>
            <e-col style="min-width: 170px" class="m-l m-r">
              <date-input label="Data de cadastro (Até)" v-model="filters.data2" />
            </e-col>
          </e-row>

          <e-row class="m-t" mr>
            <e-col class="text-right">
              <a @click="reset" class="m-r font-12 text-negative">Remover filtros</a>
              <e-btn label="Buscar" @click="buscarFechar" />
            </e-col>
          </e-row>
        </div>
      </u-popover>
    </u-btn>
  </div>
</template>

<script>
import {UPopover} from "uloc-vue"
import {ErpInput, ErpSField, ErpSelect} from "uloc-vue-plugin-erp"
import ECol from "@/components/layout/components/Col"
import ERow from "@/components/layout/components/Row"
import filters from "@/domain/arrematante/helpers/filters"
import DateInput from "@/reuse/input/Date.vue"
import CidadeSelect from "components/pessoa/components/include/CidadeSelect"
import UfSelect from "components/pessoa/components/include/UfSelect"

export default {
  name: "ArrematantesFiltrosAdicionais",
  inject: ['container'],
  props: ['filters'],
  components: {
    ErpSField,
    CidadeSelect,
    UfSelect,
    DateInput,
    ERow,
    ECol,
    //MenuOptionsItem,
    UPopover,
    //ErpSField,
  },
  mounted() {
    document.body.addEventListener('click', this.clickOutside, true)
  },
  watch: {
  },
  data() {
    return {
      prevent: false
    }
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.clickOutside)
  },
  methods: {
    toggle () {
      this.$nextTick(() => {
        if (this.prevent) return
        !this.$refs.popover.showing ? this.$refs.popover.show() : this.$refs.popover.hide()
      })
    },
    activated() {
      this.$nextTick(() => {
        // this.$refs.input0.focus()
      })
    },
    buscar () {
      this.container.pesquisar()
    },
    buscarFechar () {
      this.container.pesquisar()
      this.$refs.popover.hide()
    },
    reset () {
      this.container.table.filters = JSON.parse(JSON.stringify(filters))
      this.$refs.popover.hide()
      this.container.pesquisar()
    },
    clickOutside(evt) {
      if (
          evt && evt.target && this.$el &&
          (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
      ) {
        // this.leave()
        // this.$el.classList.add('focused')
        // this.focus()
        // placeCaretAtEnd(this.$refs.editor)
        console.log('Click outside')
        if (!this.$refs.popover.showing) return
        this.prevent = true
        setTimeout(() => {
          this.prevent = false
        }, 100)
        this.$refs.popover.hide()
        return true
      }
      console.log('Click inside')
      // this.$refs.popover.show()
      // this.hide(evt)
    }
  }
}
</script>
