<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import {UTooltip} from "uloc-vue"

export default {
  name: "FaturamentoTabs",
  mixins: [tabMixin],
  data() {
    return {
      show: true
    }
  },
  components: {
    UTooltip
  }
}
</script>

<template>
  <div class="flex" style="flex-wrap: nowrap">
    <div class="tab">
      <div class="title">
        Faturamento estimado
        <i class="fal fa-circle-question">
          <u-tooltip :offset="[5,5]">Considerando o valor inicial ou o valor do maior lance, caso exista. Lance / Comissão e taxas</u-tooltip>
        </i>
      </div>
      <div class="val" v-if="show"><span class="text-grey-8">R$ {{ leilao.cache.faturamentoPrevisto|moeda }}</span> / R$ {{ leilao.cache.comissaoPrevista|moeda }}</div>
      <div class="val" v-else>-</div>
    </div>
<!--    <div class="tab">
      <div class="title">
        Comissão prevista
        <i class="fal fa-circle-question">
          <u-tooltip :offset="[5,5]">Considerando o valor da comissão + taxas, se existir.</u-tooltip>
        </i>
      </div>
      <div class="val" v-if="show">R$ {{ leilao.cache.faturamentoPrevisto|moeda }}</div>
      <div class="val" v-else>-</div>
    </div>-->
    <div class="tab">
      <div class="title">
        Faturamento alcancado
        <i class="fal fa-circle-question">
          <u-tooltip :offset="[5,5]">Considerando o valor total dos arremates. Lance / Comissão e taxas</u-tooltip>
        </i>
      </div>
      <div class="val" v-if="show" style="color: #127188"><span class="text-grey-8">R$ {{ leilao.cache.faturamentoAlcancado|moeda }}</span> / R$ {{ leilao.cache.comissaoAlcancada|moeda }}</div>
      <div class="val" v-else>-</div>
    </div>
<!--    <div class="tab">
      <div class="title">
        Comissão alcancada
        &lt;!&ndash;        <i class="fal fa-circle-question">
                  <u-tooltip :offset="[5,5]">Considerando o valor total dos arremates.</u-tooltip>
                </i>&ndash;&gt;
      </div>
      <div class="val" v-if="show">R$ {{ leilao.cache.faturamentoAlcancado|moeda }}</div>
      <div class="val" v-else>-</div>
    </div>-->
    <div class="tab">
      <div class="title">&nbsp;</div>
      <div class="val">
        <i @click="show = false" class="far fa-duotone fa-eye-slash text-orange cursor-pointer" v-if="show">
          <u-tooltip :offset="[5,5]">Ocultar valores</u-tooltip>
        </i>
        <i @click="show = true" class="far fa-eye-slash fa-duotone cursor-pointer" v-else>
          <u-tooltip :offset="[5,5]">Mostrar valores</u-tooltip>
        </i>
      </div>
    </div>
  </div>
</template>
