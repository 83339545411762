<template>
  <div class="no-select" v-if="lote.status === statusVendido && getArremate">
    <span class="lote-pag-status v2 scolor" :class="'lote-pag-status-' + getArremate.status">{{statusPagamento.title}}</span>
  </div>
  <div v-else class="no-select no-wrap text-nowrap">
    <span class="lote-status v2 scolor" :class="'lote-status-' + lote.status">{{statusLote.title}}</span>
  </div>
</template>

<script>
import {
  Status as StatusPagamento,
  getStatus,
} from '@/domain/leiloes/helpers/PagamentoStatus'
import {
  Status as StatusLote
} from '@/domain/leiloes/helpers/LoteStatus'
import {STATUS_VENDIDO} from '@/domain/leiloes/helpers/LoteStatus'

export default {
  name: 'StatusLoteV2',
  components: {},
  props: ['lote', 'arremate'],
  mounted () {
    if (this.arremate) {
      this.lote.arremate = this.arremate
    }
  },
  computed: {
    statusVendido () {
      return STATUS_VENDIDO
    },
    getArremate () {
      if (this.arremate){
        return this.arremate
      }
      if (this.lote.arremate) {
        return this.lote.arremate
      }
      return null
    },
    statusPagamento () {
      return getStatus(Number(this.getArremate.status), StatusPagamento)
    },
    statusLote () {
      return getStatus(Number(this.lote.status), StatusLote)
    }
  }
}
</script>
