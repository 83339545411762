<template>
  <div class="submenu-item" :class="{sublevel: level > 1, 'sublevel-showed': sublevelShowed}">
    <ul class="menu-items">
      <menu-item @active="$emit('active')" submenu :menu-level="level + 1" class="in-submenu" :key="m.id" v-for="m in menu" :m="m" />
    </ul>
  </div>
</template>

<script>
// import MenuItem from "@/components/layout/menu-components/MenuItem"
export default {
  name: "Submenu",
  inject: ['menuContent'],
  beforeCreate: function () {
    // this.$options.components.MenuItem = require('./MenuItem.vue').default
  },
  components: {
   MenuItem: () => import('@/components/layout/menu-components/MenuItem')
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
      required: true
    },
    menu: {
      required: true
    },
    title: {
      type: String
    },
    level: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      sublevelShowed: false
    }
  },
  mounted() {
    // this.level === 1 && this.menuContent.$el.append(this.$el)
    if (this.level > 1) {
      setTimeout(() => {
        this.sublevelShowed = true
      }, 20)
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
