import { render, staticRenderFns } from "./ListaLeiloes.vue?vue&type=template&id=73cbed6a"
import script from "./ListaLeiloes.vue?vue&type=script&lang=js"
export * from "./ListaLeiloes.vue?vue&type=script&lang=js"
import style0 from "../assets/default.styl?vue&type=style&index=0&prod&lang=stylus&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports