<template>
  <erp-s-field
      view="lr"
      :label="pessoa.type === 1 ? 'CPF:' : 'CNPJ:'"
      label-width="40px"
  >
    <erp-input ref="input" autofocus @keydown.enter.prevent="consultaCpfCnpj" @keyup="consultaCpfCnpj" v-model="pessoa.document" v-mask="'###.###.###-##'" :after="iconAfter"
               v-if="pessoa.type === 1" />
    <erp-input ref="input" autofocus @keydown.enter.prevent="consultaCpfCnpj" @keyup="consultaCpfCnpj" v-model="pessoa.document" v-mask="'##.###.###/####-##'" :after="iconAfter" v-else />
  </erp-s-field>
</template>

<script>
import ErpSField from "@/plugins/uloc-erp/components/form/SimpleField"
import ErpInput from "@/plugins/uloc-erp/components/form/input"
import {date, debounce} from "uloc-vue"
import {consultarCpfCnpj} from "@/domain/pessoa/services"
import {mockEndereco} from "components/pessoa/mock/endereco"
import {mockTelefone} from "components/pessoa/mock/telefone"
import {mockEmail} from "components/pessoa/mock/email"
import {mockDocument} from "components/pessoa/mock/document"
import {adjustPurposes} from "@/domain/pessoa/helpers/Purpose"
export default {
  name: "DocumentInput",
  props: {
    pessoa: {required: true}
  },
  data () {
    return {
      loading: false,
      regular: null,
      forceConsulta: false
    }
  },
  components: {ErpInput, ErpSField},
  computed: {
    iconAfter () {
      if (this.regular !== null) {
        const r = this.regular
        return [{icon: r ? 'check' : 'exclamation-triangle', class: 'font-bold', color: r ? 'positive' : 'negative', type: 'fa', title: r ? 'Regular' : 'Irregular'}]
      }
      if (this.loading) {
        return [{icon: 'circle-notch', class: 'fa-spin', type: 'fa', title: 'Buscando dados...'}]
      }
      return [{icon: 'money-check', type: 'fa', color: 'blue-grey', title: 'Consultar documento', handler: this.iconClick}]
    }
  },
  methods: {
    iconClick () {
      this.forceConsulta = true
      setTimeout(() => {
        this.forceConsulta = false
      }, 1000)
      this.consultaCpfCnpj()
    },
    consultaCpfCnpj: debounce(function () {
      if (this.pessoa.id && !this.forceConsulta) return
      const d = this.pessoa.document.replace(/\D/g, '')
      if (d.length < 11) return
      if (d.length > 11 && d.length < 14) return
      this.loading = true
      this.regular = null
      consultarCpfCnpj(d)
          .then(({data}) => {
            console.log(data)
            this.loading = false
            if (data.pessoa) {
              // Pessoa encontrada
              let p = this.parsePessoa(data.pessoa)
              p.document = this.pessoa.document
              this.$emit('mergePessoa', p)
            }
            if (data.consulta) {
              const c = data.consulta
              if (c.nome) {
                this.pessoa.name = data.consulta.nome
              }
              if (c.endereco) {
                const e = c.endereco
                if (e.cep) {
                  this.pessoa.addresses[0].zip = e.cep
                }
                if (e.endereco) {
                  this.pessoa.addresses[0].address = e.endereco
                }
                if (e.numero) {
                  this.pessoa.addresses[0].number = e.numero
                }
                if (e.bairro) {
                  this.pessoa.addresses[0].district = e.bairro
                }
                /*if (e.uf) {
                  this.pessoa.addresses[0].state = e.uf
                }
                if (e.cidade) {
                  this.pessoa.addresses[0].city = e.cidade
                }*/
              }
              if (c.email) {
                this.pessoa.emails[0].email = c.email
              }
              if (c.telefone1) {
                console.log('SET PHONE', c.telefone1)
                this.pessoa.phoneNumbers[0].phoneNumber = c.telefone1
              }
              if (c.telefone2) {
                this.pessoa.phoneNumbers[1].phoneNumber = c.telefone2
              }
              if (c.telefone3) {
                this.pessoa.phoneNumbers[2].phoneNumber = c.telefone3
              }
              this.$emit('data', c)
            }

            this.regular = data.consulta ? data.consulta.regular : null
            if (this.pessoa.type === 1) {
              // PF
              if (data.consulta && data.consulta.nascimento && String(data.consulta.nascimento).length === 8) {
                this.pessoa.birthDate = String(data.consulta.nascimento).replace(/^(\d{2})(\d{2})(\d{4})$/, "$1/$2/$3");
              }
            } else {

            }
            // this.$uloc.loading.hide()
          })
          .catch(error => {
            this.loading = false
            // this.$uloc.loading.hide()
            this.alertApiError(error)
            this.$refs.input.focus()
          })
    }, 600),
    parsePessoa (pessoa) {
      if (pessoa.birthDate && pessoa.birthDate.date) {
        pessoa.birthDate = date.formatDate(pessoa.birthDate.date, 'DD/MM/YYYY')
      }
      // let mock = JSON.parse(JSON.stringify(mockArrematante))
      // console.log(mock)
      // this.arrematante = Object.assign(mock, data)
      // console.log(this.arrematante)
      if (!pessoa.addresses || pessoa.addresses.length === 0) {
        pessoa.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
      }

      if (!pessoa.phoneNumbers || pessoa.phoneNumbers.length < 3) {
        if (!Array.isArray(pessoa.phoneNumbers)) {
          pessoa.phoneNumbers = []
        }
        const len = 3 - Number(pessoa.phoneNumbers.length)
        for (let i = 0; i < len; i++) {
          pessoa.phoneNumbers.push(JSON.parse(JSON.stringify(mockTelefone)))
        }
      }

      if (!pessoa.emails || pessoa.emails.length === 0) {
        pessoa.emails = [JSON.parse(JSON.stringify(mockEmail))]
      }

      if (!pessoa.documents || pessoa.documents.length === 0) {
        pessoa.documents = [JSON.parse(JSON.stringify(mockDocument))]
      }

      adjustPurposes([
        pessoa.addresses,
        pessoa.phoneNumbers,
        pessoa.contacts,
        pessoa.emails
      ])
      return pessoa
    }
  }
}
</script>
