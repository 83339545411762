<template>
  <div v-if="!bind">
    <h2 class="diviser">
      Notificações
    </h2>

    <div v-if="!bem.notificacoes || !bem.notificacoes.length" class="m-t">
      Nenhum registro de notificação lançado.
    </div>
    <div v-for="(notificacao, key) in bem.notificacoes" :key="key" class="m-t">
      <div v-if="!notificacao.bind">
      <e-row mr>
        <e-col style="max-width: 200px">
          <erp-s-field label="Tipo">
            <tipo-notificacao-select v-model="notificacao.tipo" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Pessoa">
            <fin-pessoa-input full placeholder="Selecione uma pessoa" @input="(v) => definePessoa(v, notificacao)" :value="notificacao.fakePessoa" :router="$router || router"/>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field label="CPF/CNPJ">
            <erp-input v-model="notificacao.pessoa.document" v-mask="notificacao.pessoa.type == 1 ? '###.###.###-##' : '##.###.###/####-##'" disable />
          </erp-s-field>
        </e-col>
        <e-col v-if="notificacao.pessoa && notificacao.pessoa.id" style="max-width: 150px">
          <erp-s-field label="Telefone">
            <erp-input v-model="notificacao.pessoa.phoneNumbers[0].phoneNumber" v-mask="notificacao.pessoa.phoneNumbers[0].phoneNumber && notificacao.pessoa.phoneNumbers[0].phoneNumber.length === 11 ? '(##) # ####-####' : ''" disable />
          </erp-s-field>
        </e-col>
        <e-col v-else style="max-width: 150px">
          <erp-s-field label="Telefone">
            <erp-input v-model="fake" disable />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row v-if="notificacao.pessoa && notificacao.pessoa.id" mr>
        <e-col style="max-width: 80px">
          <erp-s-field label="CEP">
            <erp-input v-model="notificacao.pessoa.addresses[0].zip" disable />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 300px">
          <erp-s-field label="Endereço">
            <erp-input v-model="notificacao.pessoa.addresses[0].address" disable />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 60px">
          <erp-s-field label="Número">
            <erp-input v-model="notificacao.pessoa.addresses[0].number" disable />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 80px">
          <erp-s-field label="Complemento">
            <erp-input v-model="notificacao.pessoa.addresses[0].complement" disable />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 140px">
          <erp-s-field label="Bairro">
            <erp-input v-model="notificacao.pessoa.addresses[0].district" disable />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 140px">
          <erp-s-field label="Cidade">
            <erp-input v-model="notificacao.pessoa.addresses[0].city" disable />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 50px">
          <erp-s-field label="UF">
            <erp-input v-model="notificacao.pessoa.addresses[0].state" disable />
          </erp-s-field>
        </e-col>
      </e-row>
      </div>
      <div v-if="startRemove">
        <a class="text-negative" @click="remover(notificacao)">Remover registro</a>
      </div>
      <div style="border-top: #cccccc 1px dotted; margin-top: 12px; padding-bottom: 12px"></div>
    </div>

    <div class="m-t">
      <u-btn @click="novo" class="" color="positive" no-caps label="Registrar" />
      <u-btn @click="startRemove = !startRemove" class="m-l" color="negative" flat no-caps size="sm" label="Remover algum registro" />
    </div>

    <div class="m-t">
      <e-row mr>
        <bem-campo-secao css="erp-row-view simple col-limit-4 v3 view-wrap" secao="gestao-notificacoes" />
      </e-row>
    </div>
  </div>
</template>

<script>
import CadastroBemMixin from "@/components/bem/components/include/CadastroBemMixin"
import {ErpSelect} from "uloc-vue-plugin-erp"
import {REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import HistoricoProprietarios from "@/components/bem/components/include/Proprietarios"
import BemCampoSecao from "@/components/bem/components/include/CamposSecao";
import TipoNotificacaoSelect from "components/bem/components/include/notificacao/TipoNotificacaoSelect.vue";
import FinPessoaInput from "components/financeiro/components/input/PessoaInput.vue";
import {mockEndereco} from "components/pessoa/mock/endereco";
import {mockTelefone} from "components/pessoa/mock/telefone";

const mockNotificacao = {
  bind: false,
  tipo: null,
  fakePessoa: null,
  pessoa: {
    id: null,
    type: 1,
    document: null,
    addresses: [JSON.parse(JSON.stringify(mockEndereco))],
    phoneNumbers: [JSON.parse(JSON.stringify(mockTelefone))]
  }
}

export default {
  name: "GestaoNotificacoes",
  directives: {money: VMoney},
  components: {
    FinPessoaInput,
    TipoNotificacaoSelect,
    BemCampoSecao,
  },
  mounted() {
  },
  mixins: [CadastroBemMixin],
  data() {
    return {
      money: REAL_BRL,
      bind: false,
      fake: null,
      startRemove: false
    }
  },
  watch: {
  },
  computed: {},
  methods: {
    definePessoa (p, n) {
      n.fakePessoa = p
      if (!p || !p.id) return
      if (!p.addresses || typeof p.addresses[0] === 'undefined') {
        p.addresses = [JSON.parse(JSON.stringify(mockEndereco))]
      }
      console.log(p)
      if (!p.phoneNumbers || typeof p.phoneNumbers[0] === 'undefined') {
        p.phoneNumbers = [JSON.parse(JSON.stringify(mockTelefone))]
      }
      n.pessoa = p
      n.bind = true
      this.$nextTick(() => {
        n.bind = false
      })
    },
    novo () {
      if (!this.bem.notificacoes || !Array.isArray(this.bem.notificacoes)) {
        this.bem.notificacoes = []
      }
      this.bem.notificacoes.push(JSON.parse(JSON.stringify(mockNotificacao)))
    },
    remover (n) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? ', 'Sim')
          .then(() => {
            this.bind = true
            this.$nextTick(() => {
              this.bem.notificacoes.splice(this.bem.notificacoes.indexOf(n), 1)
              this.$nextTick(() => {
                this.bind = false
              })
            })
          })
          .catch(() => {})
    }
  }
}
</script>
