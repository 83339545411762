import http from "@/utils/services/http"

export const listDocumentos = (limit, page, filtros) => {
    let url = '/api/documentos?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findDocumento = (id) => {
    let url = `/api/documentos/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const previewDocumento = (id, data) => {
    let url = `/api/documentos/preview`
    return http.post(url, {
        document: data,
        id: id
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newDocumento = (data) => {
    let url = `/api/documentos`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateDocumento = (documento, method = 'PUT') => {
    let url = `/api/documentos/${documento.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, documento)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteDocumento = (id) => {
    let url = `/api/documentos/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const gerar = (id, data, formato) => {
    let url = `/api/relatorio/gerador/${id}`
    const config = {}
    if (['word', 'pdf', 'excel'].includes(formato)) {
        config.responseType = 'blob'
        config.timeout = 30000
    }
    return http.post(url, data, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => { // @TODO: Mover para ser reutilizado
            if (response.data instanceof Blob) {
                return new Promise((resolve, reject) => {
                    const fr = new FileReader();
                    fr.onload = function () {
                        const r = JSON.parse(this.result)
                        reject({data: r})
                    }
                    fr.readAsText(response.data)
                })
            } else {
                return Promise.reject(response)
            }
        })
}

export const deleteAnexoDocumento = (id) => {
    let url = `/api/documentos/${id}/removerAnexo`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const downloadAnexoDocumento = (documento, inline = false) => {
    let url = `/api/documentos/${documento}/downloadAnexo`
    if (inline) {
        url = url + '?inline=true'
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

/*
export const uploadArquivoDocumento = (id, data) => {
    let url = `/api/documentos/${id}/arquivos`
    return http.post(url, data, {
        'timeout': 300000
        // headers: {'Content-Type': 'multipart/form-data'}
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const excluirArquivoDocumento = (documentoId, arquivo) => {
    let url = `/api/documentos/${documentoId}/arquivos/${arquivo}/remove`

    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}


 */
