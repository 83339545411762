export const REAL_BRL = {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  suffix: '',
  precision: 2,
  masked: false
}

export const convertRealToMoney = function (v, enableNegative = false) {
  /*if(!Number.isNaN(v)){
    return v
  }*/
  if (enableNegative) {
    return parseFloat(v.replace("R$", "").replace(/\./g, "").replace(",", ".").trim())
  }
  return Number(String(v).replace(/\D/gi, '')) / 100
}

/**
 * V2 da função.
 * @param valor
 * @param digits
 * @returns {string}
 */
export function formatarParaMoedaBrasileira(valor, digits = 2) {
  // Converte o número para uma string com exatamente 4 casas decimais
  let valorFormatado = parseFloat(valor).toFixed(digits)
  // Substitui o ponto por vírgula para o formato brasileiro
  valorFormatado = valorFormatado.replace('.', ',')
  console.log('Valor: ', valor)
  console.log('Valor formatado: ', valorFormatado)
  return valorFormatado
}

export function converterParaFloat(valor, simbolo = 'R$') {
  if (typeof valor === 'undefined' || null === valor) {
    return null
  }
  let valorLimpo = valor.replace(simbolo, '').trim();
  valorLimpo = valorLimpo.replace(/\./g, '');
  valorLimpo = valorLimpo.replace(',', '.');
  return parseFloat(valorLimpo);
}
