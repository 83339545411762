<script>
import {
  LocalStorage,
  UIcon,
  UInput,
  USelect,
  UTooltip,
  UOptionGroup,
  UStepper,
  UStep,
  UStepperNavigation,
  date
} from 'uloc-vue'
import ErpSField from 'uloc-vue-plugin-erp/components/form/SimpleField'
import alterarSenhaWindow from '../window/alterarSenhaWindow'
import {fileToBase64} from '@/utils/fileToBase64'
import {updateUserProfilePicture, create, update, find, sugerirUsername, resetPassword} from '@/domain/usuario/services'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import CepInput from "components/pessoa/components/include/CepInput.vue"
import SlLoading from "components/layout/components/Loading.vue"
import {adjustPersonData, adjustPurposes} from "@/domain/pessoa/helpers/Purpose"
import UserMixin from "components/usuario/components/include/UserMixin"
import UserPermission from "components/usuario/components/include/Permission.vue"
import {consultarCpfCnpj, find as findPessoa} from "@/domain/pessoa/services"
import {ValidaCPF, ValidaCNPJ} from "@/utils/validators"
import {acl, list as listGroups} from "@/domain/usuario/services/groups"
import UserRoleSelect from "components/usuario/components/include/RoleSelect.vue"
import countries from "components/pessoa/helpers/countries";
import AlterarSenhaTerminal from "components/usuario/v2/components/AlterarSenhaTerminal.vue";

export default {
  name: 'UserForm',
  inject: ['userPage'],
  provide: function () {
    return {
      userForm: this
    }
  },
  mixins: [UserMixin],
  props: {
    userId: {required: false},
    full: {required: false, type: Boolean, default: false},
    editUsername: {required: false, type: Boolean, default: false},
    disableSuccessSavedDialog: {required: false, type: Boolean, default: false},
    role: {required: false, default: null},
    personId: {required: false, default: null}
  },
  components: {
    AlterarSenhaTerminal,
    UserRoleSelect,
    UserPermission,
    SlLoading,
    CepInput,
    // ErpInput,
    ErpSField,
    UIcon,
    UInput,
    ERow,
    ECol,
    USelect,
    //UTooltip,
    UOptionGroup,
    UStepper,
    UStep
    // Layout,
  },
  data() {
    console.log('YYY', this.role, this.full)
    return {
      countries: countries,
      profilePicture: '',
      preventBlur: false,
      loadingPicture: false,
      mockProfilePicture: 'http://www.gravatar.com/avatar/xxx?s=256',
      loading: true,
      saving: false,
      user: {
        id: null,
        username: null,
        acl: null,
        roles: [],
        person: {
          type: 1,
          name: null,
          document: null,
          tratment: null,
          surname: null,
          nationality: 'BR',
          phoneNumbers: [
            {areaCode: 55, phone: null}
          ],
          emails: [
            {email: null}
          ],
          addresses: [
            {zip: null, address: null, number: null, city: null, state: null}
          ]
        }
      },
      ddiBrAuto: true,
      fake: {
        doisFatores: false,
        loginCodigoAcessoEmail: false,
        nacionalidade: 1, // Ajustar ao carregar o cadastro
        criarPerfil: false,
        criarPerfilNome: null
      },
      etapa: 'cadastro',
      step: 1,
      perfil: null,
      groups: this.userPage.groups,
      acl: [],
      bindPermissions: false,
      cache: {
        ultimoDocumentoConsultado: null
      }
    }
  },
  watch: {
    'fake.criarPerfil' (v) {
      this.$nextTick(() => {
        this.$refs.userPermissions && this.$refs.userPermissions.reset()
      })
      if (!v) {
        this.groups = this.groups.filter(g => {
          return g.id
        })
        return
      }
      const p = this.groups.find(p => {
        return p && !p.id
      })
      if (p) {
      } else {
        this.groups.push({
          id: null,
          name: null,
          value: null
        })
      }
      this.perfil = null
    },
    perfil (v) {
      if (v === 'admin') {
        this.user.defaultGroup = null
      } else {
        const g = this.groups.find(g => g.id === v)
        if (g) {
          this.user.defaultGroup = g
          if (!Array.isArray(this.user.roles)) {
            this.user.roles = []
          }
          this.user.roles = this.user.roles.concat(g.roles)
          this.user.acl = g.acl
          this.bindPermissions = true
          this.$nextTick(() => {
            this.bindPermissions = false
          })
        }
      }
    },
    'user.person.document' (v) {
      if (ValidaCPF(v) || ValidaCNPJ(v)) {
        !this.preventBlur && this.consultaDocumentoPessoa(v)
      }
    }
  },
  computed: {
    perfis () {
      return [
        {label: 'Administrador', value: 'admin'},
          ...this.groups.map(g => {
            delete g.active
            return {
              ...g,
              label: g.name || 'Informe o nome do perfil',
              value: g.id
            }
          })
      ]
    },
    perfilAtivo () {
      const p = this.groups.find(p => {
        return p && !p.id
      })
      return p
    },
    isMe () {
      return this.$uloc.auth.session.user.id === this.user.id
    },
    ddis () {
      return this.countries.map(c => {
        return {
          label: '+' + c.ddi,
          value: c.ddi
        }
      })
    }
  },
  mounted() {
    let isLoad = false
    if (this.personId) {
      this.loading = true
      this.preventBlur = true
      findPessoa(this.personId)
          .then(response => {
            this.user.person = this.preparePersonToEdit(response.data)
            this.$nextTick(() => {
              this.loading = false
              setTimeout(() => {
                this.preventBlur = false
              }, 2000)
            })
          })
          .catch(error => {
            this.alertApiError(error)
          })
    } else {
      if (this.userId) {
        isLoad = true
        this.load(this.userId)
      } else {
        this.loading = false
      }
      if (this.$route && this.$route.query.tab) {
        if (this.$route.query.tab === 'perfil') {
          this.etapa = 'perfil'
        }
      }
    }
    setTimeout(() => {
      !isLoad && this.load(null)
    }, 1000)
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    load(id) {
      if (this.full) {
        this.rotinas() // for use in window components
      }
      if (typeof id === 'undefined' || id === null) {
        return
      }
      this.loading = true
      this.preventBlur = true
      find(id)
          .then(response => {
            const user = response.data
            user.person = this.preparePersonToEdit(user.person)
            if (this.isAdmin(user)) {
              this.perfil = 'admin'
            } else if (user.defaultGroup) {
              this.perfil = user.defaultGroup.id
            }
            this.user = user
            this.profilePicture = user.person.photo

            this.$nextTick(() => {
              this.loading = false
              setTimeout(() => {
                this.preventBlur = false
              }, 2000)
            })
          })
          .catch(error => {
            this.loading = false
            console.log(error)
            this.alertApiError(error)
          })
    },
    setFile(evt) {
      this.loadingPicture = true
      const file = evt.target.files[0]
      setTimeout(() => {
        fileToBase64(file)
            .then(response => {
              this.updateProfilePicture(response)
            })
            .catch(error => {
              alert('Ocorreu um erro na leitura do arquivo')
              this.loadingPicture = false
            })
      }, 50)
    },
    updateProfilePicture(data) {
      updateUserProfilePicture(this.user.id, {data, filename: 'foto'})
          .then(response => {
            this.profilePicture = response.data.image
            let user = LocalStorage.get.item('auth.user')
            user.photo = response.data.image
            LocalStorage.set('auth.user', user)
            if (this.$uloc.auth.session.user.id === this.user.id) {
              this.$uloc.auth.session.user = user
              this.$uloc.auth.loginByStorage()
            }
            console.log(response.data)
            this.loadingPicture = false
            // @TODO: session não está reativo, por isto não atualiza a foto. Verificar
          })
          .catch(error => {
            alert('Ocorreu um erro ao alterar a foto de usuário')
            console.log(error)
            this.loadingPicture = false
          })
    },
    openEditPassword() {
      this.alterarSenhaWindow()
    },
    cep(data) {
      this.$refs.addressNumber.focus()
      this.user.person.addresses[0].address = data.logradouro
      this.user.person.addresses[0].complement = data.complemento
      this.user.person.addresses[0].district = data.bairro
      this.bindCidade = true
      setTimeout(() => {
        this.user.person.addresses[0].city = data.localidade
        this.$nextTick(() => {
          this.bindCidade = false
        })
        // this.bem.cityId = data.ibge
      }, 1200)
      this.user.person.addresses[0].state = data.uf
    },
    preparePersonToEdit (p) {
      p = adjustPersonData(p, 1, 0, true)
      p.phoneNumbers = p.phoneNumbers.map(p => {
        if (String(p.phoneNumber).startsWith('+55')) {
          p.phoneNumber = String(p.phoneNumber).replace('+55', '')
        }
        return p
      })
      if (p && p.birthDate && typeof p.birthDate.date !== "undefined") {
        p.birthDate = date.formatDate(p.birthDate.date, 'DD/MM/YYYY')
      }
      return p
    },
    consultaDocumentoPessoa () {
      if (this.preventBlur) return
      if (this.cache.ultimoDocumentoConsultado === this.user.person.document) {
        return
      }
      this.cache.ultimoDocumentoConsultado = this.user.person.document
      this.$nextTick(() => {
        consultarCpfCnpj(this.user.person.document, true)
            .then(response => {
              console.log(response.data)
              if (response.data.person) {
                response.data.person.document = this.user.person.document
                this.user.person = this.preparePersonToEdit(response.data.person)
              }
            }).catch(error => {
          console.log(error)
        })
      })
    },
    async rotinas () {
      await listGroups(100, 1, '')
          .then(response => {
            this.groups = response.data.result
          })
          .catch(error => {
            this.alertApiError(error)
          })
      this.acl = await acl()
    },
    alterarSenhaWindow,
    saveUser(a = null) {
      this.$uloc.dialog({
        title: this.user.id ? 'Alterar dados cadastrais' : 'Criar usuário',
        message: this.user.id ? 'Você tem certeza que deseja alterar o cadastro' : 'Confirmar criação do novo cadastro com acesso ao sistema?',
        ok: 'Sim',
        cancel: 'Cancelar'
      }).then(() => {
        this.saving = true
        const data = JSON.parse(JSON.stringify(this.user))
        if (!data.email && typeof data.person.emails[0] !== 'undefined') {
          data.email = data.person.emails[0].email
        }
        adjustPurposes([
          data.person.addresses,
          data.person.phoneNumbers,
          data.person.contacts,
          data.person.emails
        ])

        if (data.person.addresses && data.person.addresses.length) {
          data.person.addresses = data.person.addresses.filter(a => {
            return a.zip && a.address
          })
        }

        if (data.person.phoneNumbers && data.person.phoneNumbers.length) {
          data.person.phoneNumbers = data.person.phoneNumbers.filter(a => {
            return a.phoneNumber
          })
        }


        if (data.person.documents && data.person.documents.length) {
          data.person.documents = data.person.documents.map(d => {
            if (d.type && d.type.id) {
              d.type = d.type.id
            }
            return d
          })
        }

        let method
        if (data.id) {
          method = () => update(this.user.id, data, true)
        } else {
          method = () => create(data)
        }
        console.log('Save')
        method()
            .then(response => {
              console.log(response.data)
              this.$nextTick(() => {
                this.$uloc && this.$uloc.window && this.$root && this.$root.wid && this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.$emit('updated')
                this.saving = false
                if (!this.user.id && response.data.id) {
                  this.$emit('updateUser')
                  if (this.$router) {
                    this.$router.push({
                      name: 'usuarios.usuario',
                      params: {id: response.data.id},
                      query: {tab: 'perfil'}
                    })
                  } else {
                    this.load(response.data.id)
                    setTimeout(() => {
                      this.etapa = 'perfil'
                    }, 1000)
                  }
                  return
                }
                if (this.full) {
                  if (this.etapa === 'perfil') {
                    !this.disableSuccessSavedDialog && this.dg()
                    this.etapa = 'cadastro'
                    this.fake.criarPerfil = false
                    this.fake.criarPerfilNome = null
                  } else {
                    this.etapa = 'perfil'
                    this.step = 2
                  }
                } else {
                  !this.disableSuccessSavedDialog && this.dg()
                }
                if (a && a === 'perfil') {
                  this.etapa = 'perfil'
                }
                this.load(this.user.id)
              })
            })
            .catch(error => {
              this.saving = false
              this.alertApiError(error)
            })
      }).catch(() => {
      })
    },
    savePermissions () {
      if (this.perfil !== 'admin') {
        const modules = this.$refs.userPermissions ? this.$refs.userPermissions.modules.map(m => {
          m.components = m.components.filter(c => c.check)
          return m
        }) : []
        const group = this.groups.find(g => g.value === this.perfil || g.id === this.perfil)
        if (group && group.value === null) {
          group.acl = modules
        } else {
          this.user._permissions = modules
        }
        this.user.defaultGroup = group
      } else {
        this.user.isAdmin = true
      }
      this.saveUser('perfil')
    },
    sugerir () {
      if (!this.user.person.name || String(this.user.person.name).length < 3) {
        this.dg('Erro', 'Informe o nome antes para gerar um login', 'Ok', 'c-error')
        return
      }
      sugerirUsername(this.user.person.name)
          .then(response => {
            this.user.username = response.data.username
          }).catch(error => {
            this.alertApiError(error)
          })
    },
    getCountryDDI(code) {
      const country = this.countries.find(country => country.code === code);
      return country ? country.ddi : '';
    },
    generatePassword() {
      this.$refs.passwd.generatePassword()
    }
  }
}
</script>

<template>
  <div v-if="loading" class="m-t-xxl flex justify-center">
    <div class="flex column items-center">
      <div class="">
        <sl-loading/>
      </div>
      <div class="m-t">Carregando dados</div>
    </div>
  </div>
  <div v-else class="user-form-v2">
    <u-stepper
        v-model="step"
        class="stepper-sl"
        ref="stepper"
        color="green"
        no-header-navigation
        animated
        v-if="full"
    >
      <u-step
          :name="1"
          title="Dados do usuário"
          icon="settings"
          :done="step > 1"
      >
      </u-step>

      <u-step
          :name="2"
          title="Perfil de Acesso"
          icon="security"
          :done="step > 2"
      >
      </u-step>
    </u-stepper>
    <div v-if="etapa === 'cadastro'" class="profile-page wrapper-lg">
      <form ref="form" @submit.stop.prevent="saveUser">
      <div class="box-v2">
        <div class="box-cad">
          <h2 v-if="user.id && isMe">Meu cadastro</h2>
          <h2 v-if="user.id">Usuário de Acesso</h2>
          <h2 v-else>Registro de Usuário</h2>
          <e-row mr2 class="m-t">
            <e-col>
              <u-input :autofocus="!user.id" v-if="user.person.nationality !== 'BR'" float-label="Passaporte" v-model="user.person.document"/>
              <u-input :autofocus="!user.id" v-else-if="user.person.type === 2" float-label="CNPJ" v-model="user.person.document" v-mask="'##.###.###/####-##'"/>
              <u-input :autofocus="!user.id" v-else float-label="CPF" v-model="user.person.document"
                       v-mask="'###.###.###-##'" @blur="consultaDocumentoPessoa"/>
            </e-col>
            <e-col>
              <label class="input-lbl">Nacionalidade</label>
              <u-select
                  style="max-width: 300px"
                  v-model="user.person.nationality"
                  :options="countries.map(c => {
                    return {
                      ...c,
                      label: c.nat,
                      value: c.code
                    }
                  })"
                  label="País"
                  emit-value
                  :option-dense="true"
                  :hint="'DDI: ' + (user.person.nationality ? getCountryDDI(user.person.nationality) : '')"
              />
            </e-col>
          </e-row>
          <e-row mr2 class="m-t">
            <e-col>
              <u-input required float-label="Nome Completo *" v-model="user.person.name"/>
            </e-col>
            <e-col>
              <u-input :float-label="isMe ? 'Como você gostaria de ser chamado?' : 'Nome de Tratamento'" v-model="user.person.surname"/>
            </e-col>
          </e-row>
          <e-row mr2 class="m-t">
            <e-col>
              <u-input float-label="Data de Nascimento" v-model="user.person.birthDate" v-mask="'##/##/####'"/>
            </e-col>
            <e-col>
              <u-select float-label="Sexo" :options="[{label: 'Masculino', value: 1}, {label: 'Feminino', value: 2}]"
                        v-model="user.person.gender"/>
            </e-col>
          </e-row>
          <e-row mr2 class="m-t">
            <e-col>
              <u-input float-label="Email *" required v-model="user.person.emails[0].email" type="email"/>
            </e-col>
            <e-col>
              <e-row>
                <e-col style="max-width: 100px; display: flex; align-items: flex-end; justify-content: space-between; padding-right: 10px">
                    <u-select
                        class="full-width"
                        v-model="user.person.phoneNumbers[0].areaCode"
                        :options="ddis"
                        label="DDI"
                        emit-value
                        map-options
                        :option-dense="true"
                    />
                </e-col>
                <e-col>
                  <u-input
                      v-if="user.person.nationality === 'BR' && user.person.phoneNumbers[0].areaCode == '55'"
                      ref="cellphoneInput"
                           v-mask="'(##) # ####-####'"
                           :type="'text'" class="phone"
                           :float-label="' '" dense v-model="user.person.phoneNumbers[0].phoneNumber"/>
                  <u-input
                      v-else
                      ref="cellphoneInput"
                      :type="'text'" class="phone"
                      :float-label="' '" dense v-model="user.person.phoneNumbers[0].phoneNumber"/>
                </e-col>
              </e-row>
            </e-col>
          </e-row>
          <e-row mr2 class="m-t">
            <e-col>
              <!--<u-input float-label="CEP" v-model="user.person.addresses[0].zip" />-->
              <cep-input float-label="CEP" simple-input @cep="cep" v-model="user.person.addresses[0].zip" no-update/>
            </e-col>
            <e-col>
              <e-row mr>
                <e-col>
                  <u-input float-label="Endereço" v-model="user.person.addresses[0].address"/>
                </e-col>
                <e-col style="max-width: 100px">
                  <u-input ref="addressNumber" float-label="Número" v-model="user.person.addresses[0].number"/>
                </e-col>
              </e-row>
            </e-col>
          </e-row>
          <e-row mr2 class="m-t">
            <e-col>
              <u-input float-label="Complemento" v-model="user.person.addresses[0].complement"/>
            </e-col>
            <e-col>
              <u-input float-label="Bairro" v-model="user.person.addresses[0].district"/>
            </e-col>
          </e-row>
          <e-row mr2 class="m-t">
            <e-col>
              <u-input float-label="Estado" v-model="user.person.addresses[0].state"/>
            </e-col>
            <e-col>
              <u-input float-label="Cidade" v-model="user.person.addresses[0].city"/>
            </e-col>
          </e-row>
        </div>
        <e-row v-if="user.id" class="m-t-xxl">
          <e-col>
            <div v-if="isMe"><h2>Meu acesso</h2></div>
            <div v-else><h2>Dados de acesso</h2></div>
            <div class="user-profile flex m-t-lg">
              <div>
                <div class="u-img-rounded hoverable pos-rlt profile-image min">
                  <!--<img :src="$uloc.auth.session.user.image" />-->
                  <img :src="profilePicture || mockProfilePicture"/>
                  <div class="camera-hover hidden-nohover" @click="$refs.profileInput.click()" v-if="!loadingPicture">
                    <u-icon name="camera" type="fa"/>
                  </div>
                  <div class="camera-hover" v-else>
                    <strong>Carregando...</strong>
                  </div>
                  <input type="file" ref="profileInput" accept="image/*" @change="setFile($event)" v-show="false">
                </div>
              </div>
              <div class="m-l-lg m-t col-grow">
                <e-row mr2>
                  <e-col style="max-width: 100px">
                    <erp-s-field label="Papel">
                      <strong>{{ getPapers(user.roles) }}</strong>
                    </erp-s-field>
                  </e-col>
                  <e-col v-if="user.worker" style="max-width: 100px">
                    <erp-s-field label="Cargo">
                      <strong>-</strong>
                    </erp-s-field>
                  </e-col>
                  <e-col style="max-width: 150px">
                    <erp-s-field label="Perfil de Acesso">
                      <strong>{{perfil === 'admin' ? 'Administrador' : (user.defaultGroup ? user.defaultGroup.name : 'Limitado')}}</strong>
                    </erp-s-field>
                  </e-col>
                </e-row>
                <e-row class="m-t" mr2>
                  <e-col style="max-width: 200px; margin-right: 10px">
                    <erp-s-field v-if="editUsername || full" label="Login" class="full-width">
                      <u-input v-model="user.username" class="full-width"/>
                    </erp-s-field>
                  </e-col>
                  <e-col v-if="editUsername || full" style="max-width: 100px" class="flex items-end m-l">
                    <u-btn @click="sugerir" label="Gerar" no-caps color="blue-grey-3" />
                  </e-col>
                </e-row>
                <e-row class="m-t" mr2>
                  <e-col v-if="!full && !editUsername" style="max-width: 100px">
                    <erp-s-field label="Login">
                      <strong>{{ user.username }}</strong>
                    </erp-s-field>
                  </e-col>
                  <e-col style="max-width: 140px">
                    <erp-s-field v-if="isMe" label="Sua senha">
                      <a @click="openEditPassword">Alterar minha senha</a>
                    </erp-s-field>
                    <erp-s-field v-else label="Senha se acesso">
                      <u-btn color="red" class="bg-danger" no-caps @click="generatePassword">Alterar senha</u-btn>
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field label="Autenticação por Dois Fatores"
                                 icon-help="Reforça a segurança e somente permite acessar o sistema com um dispositivo de geração de token">
                      <select disabled v-model="fake.doisFatores" class="sl-input input-sm">
                        <option :value="false">Desativado</option>
                        <option :value="true">Ativado</option>
                      </select>
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field label="Logar com Código de Acesso"
                                 icon-help="Reforça a segurança e somente permite acessar o sistema com um código de acesso enviado para seu email">
                      <select disabled v-model="fake.loginCodigoAcessoEmail" class="sl-input input-sm">
                        <option :value="false">Desativado</option>
                        <option :value="true">Ativado</option>
                      </select>
                    </erp-s-field>
                  </e-col>
                </e-row>
                <alterar-senha-terminal :user="user" ref="passwd" />
              </div>
            </div>
          </e-col>
        </e-row>
        <e-row v-else class="m-t-lg">
          <e-col>
            <e-row>
              <e-col>
                <div><h2>Dados para acesso</h2></div>
              </e-col>
            </e-row>
            <e-row mr2 class="m-t">
              <e-col>
                <e-row>
                  <e-col><u-input required float-label="Login de acesso *" v-model="user.username"/></e-col>
                  <e-col style="max-width: 100px" class="flex items-end m-l">
                    <u-btn @click="sugerir" label="Gerar" no-caps color="blue-grey-3" />
                  </e-col>
                </e-row>
              </e-col>
              <e-col>

              </e-col>
            </e-row>
            <e-row v-if="full">
              <e-col>
                <div class="group-perfil-subtitle m-t-xxl m-b">Papéis no sistema</div>
                <user-role-select :user="user" :default-roles="role ? [role] : []" />
              </e-col>
            </e-row>
          </e-col>
        </e-row>
        <e-row class="m-t-lg">
          <e-col class="flex justify-end">
            <u-btn v-if="$router" label="Voltar" outline @click="$router.go(-1)" class="sl-btn bg-white text-green m-r"/>
            <u-btn :label="full ? 'Continuar' : 'Salvar alterações'" :loading="saving" type="submit" class="sl-btn bg-green text-white"/>
          </e-col>
        </e-row>
      </div>
      </form>
    </div>
    <div v-else-if="etapa === 'perfil'" class="profile-page wrapper-lg perfil">
      <div class="box-v2">
        <h2 class="m-b-none">Perfil de Acesso de {{user.person.name}}</h2>
        <p>Os usuários do ERP terão acesso exclusivamente às permissões atribuídas ao perfil selecionado. Além disso, você tem a opção de criar um novo perfil personalizado para ser utilizado no cadastro de novos usuários.</p>
        <div class="m-t-xxl">
          <e-row>
            <e-col>
              <u-option-group
                  v-model="fake.criarPerfil"
                  :options="[{label: 'Usar perfil existente', value: false}, {label: 'Criar um novo perfil de acesso', value: true}]"
                  color="primary"
                  inline
              />
            </e-col>
          </e-row>
          <e-row v-if="!fake.criarPerfil">
            <e-col style="max-width: 400px">
              <u-select placeholder="Selecione um perfil de acesso" inverted-light class="sl-select-v2" :options="perfis" v-model="perfil" />
            </e-col>
          </e-row>
          <p v-if="perfil === 'admin'"><strong class="text-negative">Cuidado!</strong> Administrador tem acesso total e irrestrito ao software.</p>

          <e-row v-if="fake.criarPerfil">
            <e-col style="max-width: 400px">
              <erp-s-field label="Nome do novo perfil" class="full-width">
                <u-input autofocus placeholder="Informe o nome do novo perfil" hide-underline class="sl-input full-width"  v-model="perfilAtivo.name" />
              </erp-s-field>
            </e-col>
          </e-row>

          <div v-if="!bindPermissions && (perfil !== 'admin' || fake.criarPerfil) && (user.defaultGroup || fake.criarPerfil)" class="m-t-xxl">
            <div class="group-perfil-subtitle">Permissões do perfil</div>
            <user-permission ref="userPermissions" :acl="user._erp.acl" :entity="user" :disabled="perfil !== null" />
          </div>
          <div>
            <div class="group-perfil-subtitle m-t-xxl m-b">Papéis no sistema</div>
            <user-role-select :user="user" :default-roles="role ? [role] : []" />
          </div>

          <e-row class="m-t-lg">
            <e-col class="flex justify-end">
              <u-btn label="Voltar" outline @click="etapa = 'cadastro'" class="sl-btn bg-white text-green m-r"/>
              <u-btn label="Salvar alterações" :loading="saving" @click="savePermissions" class="sl-btn bg-green text-white"/>
            </e-col>
          </e-row>
        </div>
      </div>
    </div>
  </div>

</template>

<style src="../assets/user-form.styl" lang="stylus"></style>
