import http from '../../../utils/services/http'

export const listAcessorios = (limit, page, filtros) => {
  let url = '/api/acessorios?page=' + page + '&limit=' + limit + filtros
  // let url = '/api/acessorios'
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findAcessorio = (id) => {
  let url = `/api/acessorios/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newAcessorio = (data) => {
  let url = `/api/acessorios`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateAcessorio = (id, data) => {
  let url = `/api/acessorios/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const excluirAcessorio = (id) => {
  let url = `/api/acessorios/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposBem = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/tiposBem?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/tiposBem/tree'
  }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const excluirTipoBem = (id) => {
  const url = `/api/tiposBem/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposBem = (id) => {
  let url = `/api/tiposBem/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoBem = (data) => {
  let url = `/api/tiposBem`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoBem = (id, data) => {
  let url = `/api/tiposBem/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposApreensao = (id) => {
  let url = `/api/tiposApreensao/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposApreensao = (limit, page, filtros) => {
  let url = '/api/tiposApreensao?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoApreensao = (data) => {
  let url = `/api/tiposApreensao`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoApreensao = (id, data) => {
  let url = `/api/tiposApreensao/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoApreensao = (id) => {
  let url = `/api/tiposApreensao/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposArquivo = (id) => {
  let url = `/api/tiposArquivo/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposArquivo = (limit, page, filtros) => {
  let url = '/api/tiposArquivo?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoArquivo = (data) => {
  let url = `/api/tiposArquivo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoArquivo = (id, data) => {
  let url = `/api/tiposArquivo/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoArquivo = (id) => {
  let url = `/api/tiposArquivo/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposArquivoArrematante = (id) => {
  let url = `/api/tiposArquivoArrematante/${id}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listTiposArquivoArrematante = (limit, page, filtros) => {
  let url = '/api/tiposArquivoArrematante?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const newTipoArquivoArrematante = (data) => {
  let url = `/api/tiposArquivoArrematante`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateTipoArquivoArrematante = (id, data) => {
  let url = `/api/tiposArquivoArrematante/${id}`
  return http.put(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const deleteTipoArquivoArrematante = (id) => {
  let url = `/api/tiposArquivoArrematante/${id}`
  return http.delete(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const findTiposCombustivel = (id) => {
  let url = `/api/tiposCombustivel/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposCombustivel = (limit, page, filtros) => {
  let url = '/api/tiposCombustivel?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoCombustivel = (data) => {
  let url = `/api/tiposCombustivel`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoCombustivel = (id, data) => {
  let url = `/api/tiposCombustivel/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoCombustivel = (id) => {
  let url = `/api/tiposCombustivel/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposFinalidadeImovel = (id) => {
  let url = `/api/finalidadeImovel/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposFinalidadeImovel = (limit, page, filtros) => {
  let url = '/api/finalidadeImovel?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoFinalidadeImovel = (data) => {
  let url = `/api/finalidadeImovel`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoFinalidadeImovel = (id, data) => {
  let url = `/api/finalidadeImovel/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoFinalidadeImovel = (id) => {
  let url = `/api/finalidadeImovel/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposPericia = (id) => {
  let url = `/api/tiposPericia/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposPericia = (limit, page, filtros) => {
  let url = '/api/tiposPericia?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoPericia = (data) => {
  let url = `/api/tiposPericia`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoPericia = (id, data) => {
  let url = `/api/tiposPericia/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoPericia = (id) => {
  let url = `/api/tiposPericia/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposDocumentoLeilao = (id) => {
  let url = `/api/tiposDocumentoLeilao/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoDocumentoLeilao = (data) => {
  let url = `/api/tiposDocumentoLeilao`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoDocumentoLeilao = (id, data) => {
  let url = `/api/tiposDocumentoLeilao/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoDocumentoLeilao = (id) => {
  let url = `/api/tiposDocumentoLeilao/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposDocumentoLeilao = (limit, page, filtros = '') => {
  let url = '/api/tiposDocumentoLeilao?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposInteresseArrematante = (id) => {
  let url = `/api/tiposInteresseArrematante/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposInteresseArrematante = (limit, page, filtros) => {
  let url = '/api/tiposInteresseArrematante?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoInteresseArrematante = (data) => {
  let url = `/api/tiposInteresseArrematante`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoInteresseArrematante = (id, data) => {
  let url = `/api/tiposInteresseArrematante/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposCor = (limit, page, filtros) => {
  let url = '/api/tiposCor?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposCor = (id) => {
  let url = `/api/tiposCor/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoCor = (data) => {
  let url = `/api/tiposCor`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoCor = (id, data) => {
  let url = `/api/tiposCor/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoCor = (id) => {
  let url = `/api/tiposCor/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposConservacaoBem = (limit, page, filtros) => {
  let url = '/api/tiposConservacaoBem?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposConservacaoBem = (id) => {
  let url = `/api/tiposConservacaoBem/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoConservacaoBem = (data) => {
  let url = `/api/tiposConservacaoBem`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoConservacaoBem = (id, data) => {
  let url = `/api/tiposConservacaoBem/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoConservacaoBem = (id) => {
  let url = `/api/tiposConservacaoBem/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposPatio = (limit, page, filtros) => {
  let url = '/api/tiposPatio?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposPatio = (id) => {
  let url = `/api/tiposPatio/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoPatio = (data) => {
  let url = `/api/tiposPatio`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoPatio = (id, data) => {
  let url = `/api/tiposPatio/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoPatio = (id) => {
  let url = `/api/tiposPatio/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposVagaPatio = (limit, page, filtros) => {
  let url = '/api/tiposVagaPatio?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposVagaPatio = (id) => {
  let url = `/api/tiposVagaPatio/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoVagaPatio = (data) => {
  let url = `/api/tiposVagaPatio`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoVagaPatio = (id, data) => {
  let url = `/api/tiposVagaPatio/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoVagaPatio = (id) => {
  let url = `/api/tiposVagaPatio/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposComitente = (id) => {
  let url = `/api/tiposComitente/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposComitente = (limit, page, filtros) => {
  let url = '/api/tiposComitente?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoComitente = (data) => {
  let url = `/api/tiposComitente`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoComitente = (id, data) => {
  let url = `/api/tiposComitente/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoComitente = (id) => {
  let url = `/api/tiposComitente/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listMarcas = (limit, page, filtros) => {
  let url = '/api/marcas?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findMarca = (id) => {
  let url = `/api/marcas/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newMarca = (data) => {
  let url = `/api/marcas`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateMarca = (id, data) => {
  let url = `/api/marcas/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteMarca = (id) => {
  let url = `/api/marcas/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listModelos = (limit, page, filtros) => {
  let url = '/api/modelos?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findModelo = (id) => {
  let url = `/api/modelos/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newModelo = (data) => {
  let url = `/api/modelos`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateModelo = (id, data) => {
  let url = `/api/modelos/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteModelo = (id) => {
  let url = `/api/modelos/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listLeiloeiros = (limit, page, filtros) => {
  let url = '/api/leiloeiros?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findLeiloeiro = (id) => {
  let url = `/api/leiloeiros/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newLeiloeiro = (data) => {
  let url = `/api/leiloeiros`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateLeiloeiro = (id, data) => {
  let url = `/api/leiloeiros/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteLeiloeiro = (id) => {
  let url = `/api/leiloeiros/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listClassificacoesLeilao = (limit, page, filtros) => {
  let url = '/api/classificacoesLeilao?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findClassificacaoLeilao = (id) => {
  let url = `/api/classificacoesLeilao/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newClassificacaoLeilao = (data) => {
  let url = `/api/classificacoesLeilao`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateClassificacaoLeilao = (id, data) => {
  let url = `/api/classificacoesLeilao/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteClassificacaoLeilao = (id) => {
  let url = `/api/classificacoesLeilao/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findDocumentoRequeridoArrematante = (id) => {
  let url = `/api/documentosRequeridosArrematante/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listDocumentoRequeridoArrematante = (limit, page, filtros) => {
  let url = '/api/documentosRequeridosArrematante?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newDocumentoRequeridoArrematante = (data) => {
  let url = `/api/documentosRequeridosArrematante`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateDocumentoRequeridoArrematante = (id, data) => {
  let url = `/api/documentosRequeridosArrematante/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteDocumentoRequeridoArrematante = (id) => {
  let url = `/api/documentosRequeridosArrematante/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listTiposCancelamentoVenda = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/tiposCancelamentoVenda?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/tiposCancelamentoVenda/tree'
  }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findTiposCancelamentoVenda = (id) => {
  let url = `/api/tiposCancelamentoVenda/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newTipoCancelamentoVenda = (data) => {
  let url = `/api/tiposCancelamentoVenda`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateTipoCancelamentoVenda = (id, data) => {
  let url = `/api/tiposCancelamentoVenda/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteTipoCancelamentoVenda = (id) => {
  let url = `/api/tiposCancelamentoVenda/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listBancos = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/bancos?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/bancos/tree'
  }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findBanco = (id) => {
  let url = `/api/bancos/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newBanco = (data) => {
  let url = `/api/bancos`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateBanco = (id, data) => {
  let url = `/api/bancos/${id}?xxx=1`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const removeBanco = (id) => {
  let url = `/api/bancos/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listMetodosPagamentoLote = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/metodosPagamentoLote?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/metodosPagamentoLote/tree'
  }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findMetodoPagamentoLote = (id) => {
  let url = `/api/metodosPagamentoLote/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newMetodoPagamentoLote = (data) => {
  let url = `/api/metodosPagamentoLote`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateMetodoPagamentoLote = (id, data) => {
  let url = `/api/metodosPagamentoLote/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const removeMetodoPagamentoLote = (id) => {
  let url = `/api/metodosPagamentoLote/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const uploadDocumentoRequerido = (id, data) => {
  let url = `/api/documentosRequeridosArrematante/${id}/arquivo`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteDocumentoRequerido = (id) => {
  let url = `/api/documentosRequeridosArrematante/${id}/arquivo`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const findGruposComitente = (id) => {
  let url = `/api/grupo-comitentes/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listGruposComitente = (limit, page, filtros) => {
  let url = '/api/grupo-comitentes?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newGrupoComitente = (data) => {
  let url = `/api/grupo-comitentes`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateGrupoComitente = (id, data) => {
  let url = `/api/grupo-comitentes/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteGrupoComitente = (id) => {
  let url = `/api/grupo-comitentes/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listCategoriaEspecies = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/rural/categoria-especies?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/rural/categoria-especies/tree'
  }
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const findCategoriaEspecie = (id) => {
  let url = `/api/rural/categoria-especies/${id}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const newCategoriaEspecie = (data) => {
  let url = `/api/rural/categoria-especies`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateCategoriaEspecie = (id, data) => {
  let url = `/api/rural/categoria-especies/${id}`
  return http.put(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listStatusProcessos = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/processos/status?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/processos/all-status'
  }
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const findStatusProcesso = (id) => {
  let url = `/api/processos/status/${id}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const newStatusProcesso = (data) => {
  let url = `/api/processos/status`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateStatusProcesso = (id, data) => {
  let url = `/api/processos/status/${id}`
  return http.put(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const deleteStatusProcesso = (id) => {
  let url = `/api/processos/status/${id}`
  return http.delete(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listClassificacaoProcessos = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/processos/classificacao?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/processos/classificacao/tree'
  }
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const findClassificacaoProcesso = (id) => {
  let url = `/api/processos/classificacao/${id}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const newClassificacaoProcesso = (data) => {
  let url = `/api/processos/classificacao`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateClassificacaoProcesso = (id, data) => {
  let url = `/api/processos/classificacao/${id}`
  return http.put(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const deleteClassificacaoProcesso = (id) => {
  let url = `/api/processos/classificacao/${id}`
  return http.delete(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listTiposParteProcessos = (limit, page, filtros, tree = false) => {
  let url
  if(!tree){
    url = '/api/processos/tiposParte?page=' + page + '&limit=' + limit + filtros
  } else{
    url = '/api/processos/all-tiposParte'
  }
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const findTiposParteProcesso = (id) => {
  let url = `/api/processos/tiposParte/${id}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const newTiposParteProcesso = (data) => {
  let url = `/api/processos/tiposParte`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateTiposParteProcesso = (id, data) => {
  let url = `/api/processos/tiposParte/${id}`
  return http.put(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const deleteTiposParteProcesso = (id) => {
  let url = `/api/processos/tiposParte/${id}`
  return http.delete(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}
