export const testRoles = (roles, permission) => {
  if (permission.includes('ROLE_SUPER_ADMIN')) return true
  if (Array.isArray(roles) && roles.includes('ROLE_GERENCIA') && (!permission || !permission.includes('ROLE_GERENCIA'))) {
    return false
  }
  if (!Array.isArray(permission) || !permission.length) {
    return false
  }
  let success = false
  if (permission.includes('ROLE_ADMIN')) return true
  if (typeof roles !== 'undefined' && Array.isArray(roles)) {
    for (let i = 0; i < roles.length; i++) {
      if (testRole(roles[i], permission)) {
        success = true
      }
    }
  }
  return success
}

export const testRole = (role, permission) => {
  if (String(role).startsWith('!')) {
    // NEG
    return !permission.includes(role.replace('!', ''))
  }
  return permission.includes(role)
}

export const testAcls = (acls, permission, roles = null) => {
  if (roles && roles.includes('ROLE_ADMIN')) return true
  // TMP
  //if (roles && roles.includes('ROLE_BEM') && roles.includes('ROLE_VISTORIA') && roles.includes('ROLE_LEILAO') && roles.includes('ROLE_GESTAO') && roles.includes('ROLE_PATIO') && roles.includes('ROLE_EARREMATANTE') && roles.includes('ROLE_ECOMITENTE') && roles.includes('ROLE_CRM') && roles.includes('ROLE_WEBSITE') && roles.includes('ROLE_RELATORIOS')) return true
  if (!Array.isArray(permission) || !permission.length) {
    return false
  }
  if (permission.includes('ROLE_ADMIN')) return true
  for (let i = 0; i < acls.length; i++) {
    if (testAcl(acls[i], permission)) {
      return true
    }
  }
  return false
}

export const testAcl = (acl, permission) => {
  if (String(acl).startsWith('!')) {
    // NEG
    return !permission.includes(acl.replace('!', ''))
  }
  let success = false
  if (String(acl).endsWith('*')) {
    success = permission.some(item => item.startsWith(acl.replace('*', '')))
  }
  if (permission.includes(acl)) {
    success = true
  }
  return success
}

/**
 * Check for Role or ACL pass
 * @param role
 * @param acl
 * @param permissionRoles
 * @param permissionAcls
 * @returns {boolean}
 */
export const checkRoleAcl = (role, acl, permissionRoles, permissionAcls) => {
  if (!Array.isArray(permissionRoles) || !permissionRoles.length) {
    permissionRoles = []
  }
  if (permissionRoles.includes('ROLE_ADMIN')) return true
  if (permissionRoles && permissionRoles.includes('ROLE_BEM') && permissionRoles.includes('ROLE_VISTORIA') && permissionRoles.includes('ROLE_LEILAO') && permissionRoles.includes('ROLE_GESTAO') && permissionRoles.includes('ROLE_PATIO') && permissionRoles.includes('ROLE_EARREMATANTE') && permissionRoles.includes('ROLE_ECOMITENTE') && permissionRoles.includes('ROLE_CRM') && permissionRoles.includes('ROLE_WEBSITE') && permissionRoles.includes('ROLE_RELATORIOS')) return true
  let success = false
  if (role) {
    if (Array.isArray(role)) {
      success = testRoles(role, permissionRoles)
    } else if (testRole(role, permissionRoles)) {
      success = true
    }
  }
  if (success) return true

  if (!Array.isArray(permissionAcls) || !permissionAcls.length) {
    permissionAcls = []
  }
  if (acl) {
    if (Array.isArray(acl)) {
      return testAcls(acl, permissionAcls)
    } else if (testAcl(acl, permissionAcls)) {
      return true
    }
  }
  return false
}
