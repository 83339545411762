export default function (finalidade = 1, ids = null, documento = null, formato = null) {
    let window = 'gerador.documento'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: 'Gerador de Documentos',
        move: true,
        backdrop: false,
        clickOutside: true,
        closeOnEsc: false,
        windowClass: 'erp-window-modern v2',
        width: '550',
        height: '520',
        props: {
            $router: this.$router,
            router: this.$router,
            finalidade,
            ids,
            documento,
            formato
        }
    }, () => import('./Gerador'))
        .then((wid) => {
            this.$uloc.window.listen(wid, {
                updated: (wid, val) => {
                    console.log('Update payment event')
                },
                created: (wid, val) => {
                    console.log('Create payment event')
                }
            })
        }) // return wid
}
