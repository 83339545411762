<template>
  <div class="menu-content">
    <ul class="menu-items">
      <slot name="list">
        <menu-item :key="m.id" v-for="m in filteredMenu" :m="m" />
      </slot>
    </ul>
  </div>
</template>

<script>
import MenuItem from "@/components/layout/menu-components/MenuItem"
import {testRoles} from "@/utils/security"
export default {
  name: "MenuContent",
  provide: function () {
    return {
      menuContent: this
    }
  },
  components: {MenuItem},
  inject: ['menuContainer'],
  props: {
    menu: {
      required: true
    }
  },
  computed: {
    filteredMenu () {
      return this.menu.filter(m => {
        let ok = true
        if (m.roles) {
          if (!testRoles(m.roles, this.$uloc.auth.session.user.roles)) {
            ok = false
          }
        }
        return ok
      })
    }
  },
  data () {
    return {
      whenSubmenuOpened: false,
      hasSubmenuEffect: false
    }
  }
}
</script>
