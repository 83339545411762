export {getStatus} from '../../../utils/getStatus'

export const STATUS_RASCUNHO = 0;
export const STATUS_ABERTO_PARA_LANCES = 1;
export const STATUS_EM_PREGAO = 2;
export const STATUS_HOMOLOGANDO = 5;
export const STATUS_VENDIDO = 100;
export const STATUS_CONDICIONAL = 7;
export const STATUS_SEM_LICITANTES = 8;
export const STATUS_BAIXA_OFERTA = 9;
export const STATUS_RETIRADO = 10;
export const STATUS_CANCELADO = 11;
export const STATUS_PREJUDICADO = 12;
export const STATUS_REPASSE = 31;

export const STATUS_QUE_PRECISA_DE_LANCES = [STATUS_VENDIDO, STATUS_CONDICIONAL, STATUS_BAIXA_OFERTA]
export const LIST_STATUS_VENDA = [
  STATUS_CONDICIONAL,
  STATUS_VENDIDO,
];
export const Status = {
  [STATUS_RASCUNHO]: {title: 'Rascunho', class: 'lote-status-' + STATUS_RASCUNHO, color: '#FFFFFF'},
  [STATUS_ABERTO_PARA_LANCES]: {title: 'Aberto para lances', class: 'lote-status-' + STATUS_ABERTO_PARA_LANCES, color: '#FFFFFF'},
  [STATUS_EM_PREGAO]: {title: 'Em pregão', class: 'lote-status-' + STATUS_EM_PREGAO, color: '#FF7F00'},
  [STATUS_HOMOLOGANDO]: {title: 'Homologando', class: 'lote-status-' + STATUS_HOMOLOGANDO, color: '#007FFF'},
  [STATUS_VENDIDO]: {title: 'Vendido', class: 'lote-status-' + STATUS_VENDIDO, color: '#7FFF00'},
  [STATUS_CONDICIONAL]: {title: 'Condicional', class: 'lote-status-' + STATUS_CONDICIONAL, color: '#FFCC99'},
  [STATUS_SEM_LICITANTES]: {title: 'Sem licitantes', class: 'lote-status-' + STATUS_SEM_LICITANTES, color: '#FFBFFF'},
  [STATUS_BAIXA_OFERTA]: {title: 'Baixa oferta', class: 'lote-status-' + STATUS_BAIXA_OFERTA, color: '#FFBFFF'},
  [STATUS_RETIRADO]: {title: 'Retirado', class: 'lote-status-' + STATUS_RETIRADO, color: '#ff5a5a'},
  [STATUS_CANCELADO]: {title: 'Cancelado', class: 'lote-status-' + STATUS_CANCELADO, color: '#ff5a5a'},
  [STATUS_PREJUDICADO]: {title: 'Prejudicado', class: 'lote-status-' + STATUS_PREJUDICADO, color: '#ff5a5a'},
  [STATUS_REPASSE]: {title: 'Repasse', class: 'lote-status-' + STATUS_REPASSE, color: '#FFFFFF'},
}

export const StatusFake = {
  ...Status,
  // Status fake, somente para mock data
  10001: {title: 'Dou-lhe uma', class: 'lote-status-doulhe-uma', color: '#FFE95A'},
  10002: {title: 'Dou-lhe duas', class: 'lote-status-doulhe-duas', color: '#ff5a5a'}
}

export const StatusTitle = function (s) {
  if (typeof Status[s] === 'undefined') {
    return 'Unknow status code'
  }
  return Status[s].title
}
