const disableAlert = () => {
  alert('Função desabilitada para suas configurações')
}

const ruralLicenseAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}
const configureAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}

const configureFinanceiroAlert = () => {
  alert('Módulo em processo de testes, se você deseja participar dos testes, solicite ao seu Gerente de Contas SL.')
}
export default [
  {
    id: 'principal',
    menuName: 'Principal',
    components: [
      {
        component: 'Minha conta',
        features: [
          {name: 'Dashboard', icon: 'dashboard', href: '/#/'},
          {name: 'Meu perfil', icon: 'perfil', href: '/#/perfil'},
          {name: 'Mensagens', icon: 'mensagens', tip: null, href: '/#/perfil/mensagens'},
          {name: 'Configurações', icon: 'config', href: '/#/perfil/configuracoes'},
        ]
      },
      /*{
        component: 'Intranet',
        features: [
          {name: 'Social Network', icon: 'network', clickEvent: disableAlert},
          {name: 'Base de conhecimento', icon: 'knowledge', clickEvent: disableAlert}
        ]
      },*/
      {
        component: 'Colaborativo',
        roles: ['ROLE_GESTAO'],
        acl: ['gestao/task/l'],
        features: [
          {name: 'Tarefas', icon: 'tasks', href: '/#/sl/tarefas'},
          // {name: 'Minhas metas', icon: 'metas', clickEvent: configureAlert},
          // {name: 'Arquivos', icon: 'files', clickEvent: configureAlert}
        ]
      },
      {
        component: 'Ajuda',
        right: true,
        hideLabel: true,
        features: [
          {name: 'Ajuda e Documentação', icon: 'help', href: 'https://docs.suporteleiloes.com', target: '_blank'}
        ]
      }
    ]
  },
  {
    id: 'gerencia',
    roles: ['ROLE_GERENCIA'],
    menuName: 'Console',
    components: [
      {
        component: 'Gerência',
        features: [
          {name: 'Console', icon: 'reports', href: '/#/console'},
          {name: 'Clientes', icon: 'contract', href: '/#/console/clientes'},
          {name: 'Servidores', icon: 'api', href: '/#/gerencia/servidores'}
        ]
      },
      {
        component: 'Suporte',
        features: [
          //{name: 'Dashboard', icon: 'reports', href: '/#/console/suporte'},
          {name: 'Projetos', icon: 'contract', href: '/#/console/suporte/projetos'},
          {name: 'Tarefas', icon: 'tasks', href: '/#/console/tarefas'}
        ]
      },
      {
        component: 'Marketplace',
        features: [
          {name: 'Produtos e Serviços', icon: 'centercost', href: '/#/console/produtos'},
        ]
      }
    ]
  },
  {
    id: 'bens',
    menuName: 'Bens',
    roles: ['ROLE_BEM', 'ROLE_VISTORIA', 'ROLE_PATIO'],
    acl: ['bem/*', 'vistoria/*', 'patio/*'],
    components: [
      {
        component: 'Administração de Bens',
        features: [
          {name: 'Gestão de Bens', icon: 'mat', href: '/#/bens/lista', roles: ['ROLE_BEM'], acl: ['bem/s']},
          {name: 'Imóveis', icon: 'house', href: '/#/bens/lista#imoveis', roles: ['ROLE_BEM'], acl: ['bem/s']},
          {name: 'Veículos', icon: 'car', href: '/#/bens/lista#veiculos', roles: ['ROLE_BEM'], acl: ['bem/s']},
          {name: 'Vistoria', icon: 'metas', href: '/#/vistoria', roles: ['ROLE_VISTORIA'], acl: ['vistoria/s']},
          {name: 'Propostas', icon: 'contract', href: '/#/propostas-venda-direta', roles: ['ROLE_BEM'], acl: ['bem/proposta/l']},
          {name: 'Dashboard Cadastral', icon: 'monitor', href: '/#/insights/bens', roles: ['ROLE_BEM'], acl: ['bem/s']}
          /*{name: 'Cálculo de Estadas', icon: 'calc', clickEvent: disableAlert}*/
        ]
      },
      {
        component: 'Processo de Remoção',
        roles: ['ROLE_PATIO'],
        acl: ['patio/*'],
        features: [
          {name: 'Remocão', icon: 'truck', href: '/#/remocao', roles: ['ROLE_PATIO'], acl: ['patio/remocao/l']},
          {name: 'Rastreamento', icon: 'track', href: '/#/remocao/monitor', roles: ['ROLE_PATIO'], acl: ['patio/remocao/rastreio']},
          {name: 'Pátios', icon: 'map-pointer', href: '/#/patios/lista', roles: ['ROLE_PATIO'], acl: ['patio/l']},
          {name: 'Estoque', icon: 'bank', href: '/#/patio/estoque', roles: ['ROLE_BEM'], acl: ['bem/s']},
        ]
      },
      /*{
        component: 'Gestão de Processos',
        features: [
          {name: 'Processos', icon: 'archive', href: '/#/processo'},
        ]
      },*/
      {
        component: 'Configurações',
        roles: ['ROLE_ADMIN', 'ROLE_CONFIG'],
        acl: ['config/*'],
        features: [
          {name: 'Cadastro Básico', icon: 'register', href: '/#/cadastro', roles: ['ROLE_ADMIN', 'ROLE_CONFIG'], acl: ['config/*']},
          {name: 'Configurações Globais', icon: 'config', href: "/#/configuracoes/globais", roles: ['ROLE_ADMIN', 'ROLE_CONFIG'], acl: ['config/*']},
        ]
      }
    ]
  },
  {
    id: 'leiloes',
    menuName: 'Leilões',
    roles: ['ROLE_LEILAO'],
    acl: ['leilao/*'],
    components: [
      {
        component: 'Administração de Leilão',
        features: [
          {name: 'Leilões', icon: 'auction', href: "/#/leiloes", roles: ['ROLE_LEILAO'], acl: ['leilao/l']},
          // {name: 'PDS', icon: 'terminal', clickEvent: disableAlert},
          {name: 'Pagamento de Lotes', icon: 'thermal-print', href: "/#/pda?app=app-iniciar-atendimento-leilao", roles: ['ROLE_LEILAO'], acl: ['leilao/paglote*']},
          {name: 'Ponto de Atendimento', icon: 'contact', href: "/#/pda", roles: ['ROLE_LEILAO'], acl: ['leilao/paglote*']},
          {name: 'Auditório Unificado', icon: 'monitor', href: "/#/leiloes/auditorio", roles: ['ROLE_LEILAO'], acl: ['leilao/control']},
          {name: 'Fila', icon: 'metas', href: "/#/fila-vendas-leilao", roles: ['ROLE_LEILAO'], acl: ['leilao/formalizar']}
        ]
      },
      {
        component: 'Fiscal',
        features: [
          {name: 'Notas', icon: 'archive', href: "/#/notas", roles: ['ROLE_LEILAO'], acl: ['leilao/formalizar']},
        ]
      }
    ]
  },
  {
    id: 'gestao',
    menuName: 'Gestão',
    roles: ['ROLE_GESTAO'],
    acl: ['gestao/*'],
    components: [
      {
        component: 'Gestão',
        features: [
          {name: 'Pessoas', icon: 'user', href: '/#/pessoas', roles: ['ROLE_GESTAO'], acl: ['gestao/pessoas/l']},
          {name: 'Processos', icon: 'archive', href: '/#/processo/', roles: ['ROLE_GESTAO'], acl: ['gestao/processo*']},
          {name: 'Bens', icon: 'mat', href: '/#/bens/lista', roles: ['ROLE_BEM'], acl: ['bem/s']},
          {name: 'Tarefas', icon: 'tasks', href: '/#/sl/tarefas', roles: ['ROLE_GESTAO'], acl: ['gestao/task/l']},
          {name: 'Produtividade', icon: 'financialbook', href: '/#/processo/produtividade', roles: ['ROLE_GESTAO'], acl: ['gestao/produtiv/s']},
          {name: 'Documentos', icon: 'template', href: '/#/documentos/', roles: ['ROLE_GESTAO'], acl: ['gestao/documento/l']},
          {name: 'Validade de Documentos', icon: 'metas', href: '/#/validade-documentos/', roles: ['ROLE_GESTAO'], acl: ['gestao/validDoc/l']},
          {name: 'Controle de RGI', icon: 'metas', href: '/#/gestao/controle-rgi', roles: ['ROLE_GESTAO'], acl: ['gestao/validDoc/l']},
          // {name: 'Parceiros', icon: 'contract', href: '/#/parceiros', roles: ['ROLE_GESTAO'], acl: ['gestao/parceiros/s']},
        ]
      },
      {
        component: 'Automação de Processos',
        roles: ['ROLE_GESTAO'],
        acl: ['gestao/processo/mov/l'],
        features: [
          {name: 'Andamentos', icon: 'reports', href: '/#/andamentos'},
          //{name: 'Robôs PJe', icon: 'api', href: '/#/processo/', clickEvent: disableAlert},
        ]
      },
      {
        component: 'Gestão de Bens',
        roles: ['ROLE_BEM'],
        acl: ['bem/s'],
        features: [
          {name: 'Imóveis', icon: 'house', href: '/#/bens/lista#imoveis'},
          {name: 'Veículos', icon: 'car', href: '/#/bens/lista#veiculos'}
        ]
      }
      /*{
        component: 'Gestão de Documentos',
        features: [
        ]
      },*/
    ]
  },
  {
    id: 'crm',
    menuName: 'CRM',
    roles: ['ROLE_CRM'],
    acl: ['crm/*'],
    components: [
      {
        component: 'CRM',
        features: [
          {name: 'Negócios', icon: 'contract', href: '/#/negocios', roles: ['ROLE_CRM'], acl: ['crm/negocio/s']},
          {name: 'Callcenter', icon: 'contact', href: '/#/callcenter', roles: ['ROLE_GESTAO', 'ROLE_CRM'], acl: ['gestao/pessoa/call']},
        ]
      },
      {
        component: 'Marketing',
        features: [
          {name: 'Campanhas', icon: 'mkt', href: "/#/marketing/campanhas", roles: ['ROLE_CRM'], acl: ['crm/mkt/l/l']},
          {name: 'Gerador', icon: 'template', href: "/#/marketing/gerador", roles: ['ROLE_LEILAO']},
        ]
      },
      {
        component: 'Comunicação',
        features: [
          {name: 'Aviso de Entrega de Itens', icon: 'metas', href: "/#/entrega-itens", roles: ['ROLE_CRM']},
        ]
      }
    ]
  },
  {
    id: 'comitentes',
    menuName: 'Comitentes',
    roles: ['ROLE_ECOMITENTE'],
    acl: ['ecomitente/*'],
    components: [
      {
        features: [
          {name: 'Comitentes', icon: 'user', href: "/#/comitentes/lista", roles: ['ROLE_ECOMITENTE'], acl: ['comitente/l']}
        ]
      },
      {
        component: 'Integração',
        features: [
          {name: 'Seguradoras', icon: 'terminal', href: "/#/integra/grupo-porto", roles: ['ROLE_BEM', 'ROLE_ECOMITENTE'], acl: ['bem/integra/grupoPorto']},
          //{name: 'Tribunais', icon: 'terminal', clickEvent: disableAlert},
          {name: 'Resale', icon: 'terminal', href: "/#/integra/resale", roles: ['ROLE_BEM', 'ROLE_ECOMITENTE'], acl: ['bem/integra/resale']},
        ]
      }
    ]
  },
  {
    id: 'arrematantes',
    menuName: 'Arrematantes',
    roles: ['ROLE_EARREMATANTE'],
    acl: ['earrematante/l'],
    components: [
      {
        component: 'Administração de Arrematantes',
        features: [
          {name: 'Arrematantes', icon: 'user', href: "/#/arrematantes/lista", roles: ['ROLE_EARREMATANTE'], acl: ['earrematante/l']},
          {name: 'Exportar base', icon: 'export', href: "/#/arrematantes/exportar", roles: ['ROLE_EARREMATANTE'], acl: ['earrematante/export']},
          {name: 'Campanhas de comunicação', icon: 'chat', href: "/#/marketing/campanhas#arrematantes", roles: ['ROLE_CRM'], acl: ['crm/mkt/l']},
          /*{name: 'Monitoramento', icon: 'monitor', href: "/#/arrematantes/monitor"},*/
          {name: 'Aprovação de cadastros', icon: 'approved', href: "/#/arrematantes/aprovacao", roles: ['ROLE_EARREMATANTE'], acl: ['earrematante/analise/c']},
          {name: 'Habilitação', icon: 'list', href: "/#/arrematantes/habilitacao", roles: ['ROLE_EARREMATANTE'], acl: ['earrematante/habilitacao/l']}
        ]
      }
    ]
  },
  {
    id: 'administrar-site',
    menuName: 'Sites',
    roles: ['ROLE_WEBSITE'],
    acl: ['website/*'],
    components: [
      {
        component: 'Configurações',
        features: [
          {name: 'Sites', icon: 'monitor', href: "/#/configuracoes/sites", roles: ['ROLE_WEBSITE'], acl: ['website/s']},
          {name: 'Menus', icon: 'config', href: "/#/website/menus", roles: ['ROLE_WEBSITE'], acl: ['website/menu/s']},
        ]
      },
      {
        component: 'Conteúdo Sites',
        roles: ['ROLE_WEBSITE'],
        acl: ['website/*'],
        features: [
          {name: 'Banners', icon: 'files', href: "/#/website/banners", roles: ['ROLE_WEBSITE'], acl: ['website/banner/s']},
          {name: 'Popup', icon: 'files', href: "/#/website/banners?type=popups", roles: ['ROLE_WEBSITE'], acl: ['website/banner/s']},
          {name: 'Páginas', icon: 'template', href: "/#/website/paginas", roles: ['ROLE_WEBSITE'], acl: ['website/conteudo/s']},
        ]
      },
      {
        component: 'Blog', roles: ['ROLE_WEBSITE'], acl: ['website/blog/l'],
        features: [
          {name: 'Publicações', icon: 'template', href: "/#/blog/posts", roles: ['ROLE_WEBSITE'], acl: ['website/blog/l']},
          {name: 'Categorias', icon: 'metas', href: "/#/website/menus", roles: ['ROLE_WEBSITE'], acl: ['website/menu/s']},
        ]
      }
    ]
  },
  {
    id: 'financeiro',
    menuName: 'Financeiro',
    roles: ['ROLE_FINANCEIRO'],
    components: [
      {
        component: 'Financeiro',
        features: [
          {name: 'Resumo', icon: 'reports', href: '/#/financeiro', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/mov/l']},
          {name: 'Contas a Pagar', icon: 'pay1', href: '/#/financeiro/contas#pagar', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/mov/l']},
          {name: 'Contas a Receber', icon: 'pay2', href: '/#/financeiro/contas#receber', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/mov/l']},
          {name: 'Extrato', icon: 'invoice', href: '/#/financeiro/contas#extrato', roles: ['ROLE_FINANCEIRO']},
        ]
      },
      /* {
        component: 'Compras',
        features: [
          {name: 'Compras', icon: 'cart', clickEvent: configureAlert},
        ]
      }, */
      {
        component: 'Consultas',
        features: [
          {name: 'Consulta CPF/CNPJ', icon: 'cpf', clickEvent: disableAlert},
          // {name: 'Inadimplentes', icon: 'proccess', clickEvent: configureFinanceiroAlert}, @TODO
        ]
      },
      {
        component: 'Contabilidade',
        features: [
          {name: 'Plano de Contas', icon: 'charts', href: '/#/financeiro/categorias', roles: ['ROLE_FINANCEIRO']},
          // {name: 'Orçamento Anual', icon: 'finalcialbook', clickEvent: configureAlert},
          // {name: 'NF-e recebidas', icon: 'financialbook', clickEvent: configureFinanceiroAlert},
          // {name: 'Contratos', icon: 'contract', clickEvent: configureFinanceiroAlert}, @TODO
        ]
      },
      {
        component: 'Cadastros',
        right: true,
        features: [
          {name: 'Fornecedores', icon: 'userwork', href: "/#/pessoas?paper=fornecedor", roles: ['ROLE_GESTAO'], acl: ['gestao/pessoas/l']},
          {name: 'Contas', icon: 'bank', href: '/#/cadastro/bancos', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/banco/l']},
          {name: 'Centro de Custo', icon: 'centercost', href: '/#/financeiro/cc', roles: ['ROLE_FINANCEIRO'], acl: ['financeiro/cc/l']},
        ]
      },
    ]
  },
  /*{
    id: 'rh',
    menuName: 'RH',
    components: []
  },*/
  {
    id: 'relatorios',
    menuName: 'Relatórios',
    roles: ['ROLE_RELATORIOS'],
    acl: ['relatorios/*'],
    components: [
      {
        component: 'Emissão',
        features: [
          {name: 'Relatórios Gerenciais', icon: 'reports', href: "/#/relatorios", roles: ['ROLE_RELATORIOS']},
        ]
      },
      {
        component: 'Auditoria e Logs',
        features: [
          {name: 'Log Operacional', icon: 'log', href: '/#/relatorios/operacional/logs', roles: ['ROLE_RELATORIOS', 'ROLE_CONFIG'], acl: ['config/log/l']},
          {name: 'API Error', icon: 'api', clickEvent: configureAlert},
          {name: 'Comunicação Geral', icon: 'chat', clickEvent: configureAlert}
        ]
      }
    ]
  },
  {
    id: 'configuracoes',
    menuName: 'Configurações',
    roles: ['ROLE_CONFIG'],
    components: [
      {
        component: 'Cadastro Básico',
        features: [
          {name: 'Cadastro Básico', icon: 'register', href: "/#/cadastro"},
          {name: 'Templates e Documentos', icon: 'template', href: "/#/documentos"},
          {name: 'Variáveis', icon: 'archive', href: "/#/configuracoes/variaveis"},
          {name: 'Taxas', icon: 'percent', href: "/#/configuracoes/taxas"}
        ]
      },
      {
        component: 'Administração',
        features: [
          {name: 'Usuários', icon: 'user', href: "/#/configuracoes/usuarios"},
          {name: 'Sites', icon: 'monitor', href: "/#/configuracoes/sites"},
          {name: 'Formulários', icon: 'metas', href: "/#/forms"},
        ]
      },
      {
        component: 'Sistema',
        features: [
          {name: 'Importação', icon: 'files', href: "/#/configuracoes/importador"},
          {name: 'Configurações Globais', icon: 'config', href: "/#/configuracoes/globais"},
        ]
      },
      {
        component: 'Ajuda',
        right: true,
        hideLabel: true,
        features: [
          {name: 'Suporte', icon: 'contact', href: '/#/suporte'},
        ]
      }
    ]
  }
]
