import Comunicator from 'comunicator/src/index'
// import Comunicator from '../../comunicator_wrapper/src/index'
import RealtimeInterface from 'comunicator/src/realtime-service/interface.js'
// import RealtimeInterface from '../../comunicator_wrapper/src/realtime-service/interface.js'
import http from '../utils/services/http'
import Vue from 'vue'
import env from '../env'

export const createComunicator = function (token = '', domain = '') {
  Vue.prototype.comunicatorClass = new Comunicator(process.env.VUE_APP_GATEWAY, RealtimeInterface, http)
  let wsURL = env.websocket  + `?token=${token}&client=${domain}`
  try {
    const hostname = window.location.hostname
    const fullUrl = window.location.href;
    const queryString = fullUrl.includes('?') ? fullUrl.split('?')[1].split('#')[0] : null;
    function verificarComitente() {
      if (hostname.startsWith("comitente")) {
        return true
      }
      if (queryString) {
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.get("comitente") === "1") {
          return true;
        }
      }
      return false
    }

    if (verificarComitente()) {
      wsURL = 'wss://realtime.suporteleiloes.com'
    } else {}
  } catch (e) {
    console.error(e)
  }
  Vue.prototype.comunicator = this.comunicatorClass.comunicator.connect(wsURL, {})
  //this.comunicator.subscribe('all')
  //this.$globalConfig && this.$globalConfig.clientId && this.comunicator.subscribe(this.$globalConfig.clientId);

  // bind events
}
