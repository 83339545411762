<script>
import http from "@/utils/services/http"
import {validar} from "@/domain/aprovacao/services"

export default {
  name: "Autorizador",
  inject: ['appRoot'],
  data() {
    return {
      opened: false,
      pass: null,
      data: {
        requisicaoId: null,
        canal: null,
        codigo: null,
      },
      code: ["", "", "", "", "", ""],
      isSubmiting: false
    }
  },
  components: {},
  mounted() {
    document.body.append(this.$el)
    this.intercept()
  },
  beforeDestroy() {
    document.body.contains(this.$el) && document.body.removeChild(this.$el)
  },
  methods: {
    intercept() {
      // Interceptador de resposta do Axios
      http.interceptors.response.use(
          function (response) {
            return response
          },
          async (error) => {
            console.log('ON ERROR', error)
            if (error && error.response && error.response.status === 403 && error.response.data.error === 'autorizacao_gerencial_necessaria') {
              console.log(error)
              const requisicaoId = error.response.data.requisicao

              this.open(requisicaoId, error.response.data.canal)
              return new Promise((resolve, reject) => {
                if (this.$interval) clearInterval(this.$interval)
                this.$interval = setInterval(() => {
                  if (!this.opened) {
                    clearInterval(this.$interval)
                    reject(error)
                  }
                  if (this.pass !== null) {
                    if (this.pass) {
                      resolve(this.pass)
                    } else {
                      reject(error)
                    }
                  }
                }, 2000)
              })
            } else {
              return Promise.reject(error)
            }
          }
      )
    },
    moveToNextBox(index) {
      // Move para o próximo input quando o usuário digitar um número
      if (this.code[index].length === 1 && index < 5) {
        this.$refs.inputs[index + 1].focus();
      }
    },
    moveToPreviousBox(index) {
      // Quando o usuário pressiona "backspace" e o campo está vazio, foca no campo anterior
      if (this.code[index] === "" && index > 0) {
        this.$refs.inputs[index - 1].focus();
      }
    },
    handlePaste(event) {
      // Manipula o evento de colar (paste)
      const pasteData = event.clipboardData.getData('text');

      if (pasteData.length === 6 && !isNaN(pasteData)) {
        // Se for um código com 6 números
        this.code = pasteData.split(""); // Divide os números e preenche o array
        this.$nextTick(() => {
          this.$refs.inputs[5].focus(); // Foca no último input
        });
      }
      event.preventDefault(); // Evita o comportamento padrão de colar
    },
    open(requisicaoId, canal = null) {
      this.code = ["", "", "", "", "", ""]
      this.pass = null
      this.data.canal = canal
      this.data.codigo = null
      this.data.requisicaoId = requisicaoId
      this.opened = true
    },
    close() {
      this.opened = false
      this.pass = null
      this.code = ["", "", "", "", "", ""]
      this.data.canal = null
      this.data.codigo = null
      this.data.requisicaoId = null
    },
    validar() {
      this.isSubmiting = true
      this.data.codigo = this.code.join('')
      validar(this.data.requisicaoId, this.data.codigo)
          .then(response => {
            this.isSubmiting = false
            //this.$uloc.notify({color: 'positive', position: 'bottom', message: 'Requisição autorizada e realizada com sucesso.'})
            this.dg('Requisição autorizada e realizada com sucesso.')
            this.pass = response.data
            this.close()
            console.log(response.data)
          })
          .catch(error => {
            this.isSubmiting = false
            this.pass = false
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <div class="app-autorizador-container" v-if="opened">
    <div class="app-autorizador">
      <div class="auth-v2fa">
        <div class="login-form-pre-header">
          <div class="login-lbl text-center">Precisamos de <strong>autorização</strong> para validar esta alteração.</div>
          <div v-if="data.canal === 'sms'">
            <div class="title">Enviamos uma código para o telefone da gerência, digite ele abaixo:
            </div>
          </div>
          <div v-if="data.canal === 'email'">
            <div class="title">Enviamos uma código de segurança para o e-mail da gerência,
              digite abaixo:
            </div>
          </div>
        </div>
        <div class="code-inputs">
          <input
              v-for="(value, index) in code"
              :key="index"
              type="text"
              maxlength="1"
              :autofocus="index === 0"
              v-model="code[index]"
              @input="moveToNextBox(index)"
              @keydown.backspace="moveToPreviousBox(index)"
              @paste="handlePaste($event)"
              ref="inputs"
          />
        </div>
        <u-btn :disabled="isSubmiting" @click="validar" size="md"
               label="Validar"
               class="w-full m-t-md btn-validador" no-caps/>
        <div class="m-t">
          <u-btn :disabled="isSubmiting" @click="close" size="md" flat label="Cancelar" no-caps/>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="stylus">
.app-autorizador-container {
  background rgba(0,0,0,0.6)
  position fixed
  top 0
  bottom 0
  right 0
  left 0
  z-index 12000
}
.app-autorizador {
  background: rgb(255,255,255);
  background: linear-gradient(130deg, rgba(255,255,255,1) 0%, rgba(239,243,250,1) 100%);
  position fixed
  left 0
  right 0
  top 0
  bottom 0
  max-width 500px
  max-height 400px
  z-index 12001
  margin auto
  border-radius 25px
  border: 1px solid rgba(0, 0, 0, .15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  font-family 'Helvetica Neue', Helvetica, Arial, sans-serif
  padding 20px
  display flex
  align-items center
  justify-content center

  .auth-v2fa {
    margin-top 0px !important
  }

  .btn-validador {
    font-size 16px
    font-weight 600
    height 36px
    border-radius 8px
    background-color #000000
    color #FFFFFF
    width 50%
  }
}
</style>
