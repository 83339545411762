import { render, staticRenderFns } from "./StatusBemIcon.vue?vue&type=template&id=040a0a15&scoped=true"
import script from "./StatusBemIcon.vue?vue&type=script&lang=js"
export * from "./StatusBemIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040a0a15",
  null
  
)

export default component.exports