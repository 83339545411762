<template>
  <li @click="click" class="level1" :class="{active: active}">
    <a class="menu_link" :href="href" data-menu-level="1">
      <div class="erp-icon-size" v-if="icon && iconType === 'erp'"><i class="erp-icon" :class="icon"></i></div>
      <u-icon :name="icon" :type="iconType" :icon-style="iconStyle" v-else-if="icon" :class="iconClass" />
      <i class="icon-none" v-else />
      <span class="menu_link-text">{{ label }}</span>
      <span v-if="hasSubmenu" class="menu_link-arrow"></span>
    </a>
    <slot v-if="hasSubmenu" name="submenu" />
  </li>
</template>

<script>
import {stopAndPrevent} from "uloc-vue/src/utils/event"

export default {
  name: 'NavLink',
  inject: ['nav'],
  data () {
    return {
      href: null,
      active: false
    }
  },
  props: {
    label: {required: true},
    link: {required: false},
    route: {required: false},
    icon: {required: false},
    iconType: {required: false},
    iconStyle: {required: false},
    iconClass: {required: false},
    hasSubmenu: {
      type: Boolean,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.route) {
      this.href = this.$router.resolve(this.route).href
    } else {
      this.href = this.link
    }
  },
  methods: {
    click ($event) {
      if (!this.href) {
        stopAndPrevent($event)
      }
      console.log(this.active)
      this.active = !this.active
      if (!this.hasSubmenu || this.closeOnClick) {
        this.nav.clickEvent($event)
      }
      this.$emit('click')
    }
  }
}
</script>
