<template>
  <div class="menu_submenu">
    <div class="submenu-column submenu-column_1">
      <ul class="submenu_inner submenu_inner_level-1">
        <li class="submenu_item submenu_item_level-2">
          <span class="submenu_link submenu_link_nolink"><span class="submenu_link-text">Marketplace API</span></span>
          <ul class="submenu_inner_level-2">
            <nav-sub-link @click="sincronizarImovelWeb" icon="upload" icon-type="fa" label="Sincronizar lotes com Imóvel Web" />
            <nav-sub-link @click="sincronizarImovelWeb" icon="upload" icon-type="fa" label="Remover lotes com Imóvel Web" />
            <nav-sub-link @click="sincronizarSantanderImoveis" icon="upload" icon-type="fa" label="Sincronizar com Santander Imóveis" />
            <nav-sub-link @click="atualizarUrlResale" icon="upload" icon-type="fa" label="Atualizar URL com a Resale" />
            <nav-sub-link disabled icon="upload" icon-type="fa" label="Sincronizar lotes com Resale" />
            <!-- @click="() => LIST_STATUS_ENCERRADO.includes(leilao.status) && comunicarDetranPB"  -->
            <nav-sub-link 
              title="Habilitado apenas quando leilão estiver encerrado" 
              :disabled="!LIST_STATUS_ENCERRADO.includes(leilao.status)"
              @click="() => comunicarDetranPB()" 
              icon="upload"
              icon-type="fa"
              label="Comunicar Vendas para Detran Paraíba" />
            <nav-sub-link @click="gerarTextos" icon="keyboard" icon-type="fa" label="Gerar títulos e descrição automaticamente" />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavSubLink from './NavSubLink'
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
import {listLoteIds, atualizaDescricoesBens} from "@/domain/leiloes/services"
import {
detranPBComunicacaoVenda,
  imovelWebCriaAnuncio,
  imovelWebDeletarAnuncio,
  resaleTransmiteUrl,
  santanderImoveisTransmiteDevolucao
} from "@/domain/bem/services/marketplace"
import { LIST_STATUS_ENCERRADO } from '@/domain/leiloes/helpers/LeilaoStatus'
import { LIST_STATUS_VENDA } from '@/domain/leiloes/helpers/LoteStatus'
export default {
  name: 'MarketplaceApiSubmenu',
  mixins: [LeilaoMixin],
  components: {NavSubLink},
  inject: ['nav', 'leilaoPage'],
  computed: {
    LIST_STATUS_ENCERRADO() {
      return LIST_STATUS_ENCERRADO
    }
  },
  methods: {
    click () {
      this.nav.clickEvent()
      return true
    },
    sincronizarImovelWeb () {
      this.click()
      this.$uloc.dialog({
        title: 'Sincronizar com Imóvel Web',
        message: 'Tem certeza que deseja realizar a operação?',
        cancel: true,
        preventClose: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.loading.show({message: 'Consultando bens...'})
        listLoteIds(this.leilao.id)
            .then(response => {
              console.log(response.data)
              response.data.ids.map(lt => {
                this.$uloc.loading.show({message: `Sincronizando lote ${lt.numero}, bem ID ${lt.idBem}`})
                imovelWebCriaAnuncio(lt.idBem)
                    .then(response => {
                      this.$uloc.loading.hide()
                    })
                    .catch(error => {
                      this.$uloc.loading.hide()
                      this.alertApiError(error)
                    })
              })
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
    },
    comunicarDetranPB: async function () {
      try{
      this.click()
        await this.$uloc.dialog({
          title: 'Comunicar venda ao Detran Paraíba?',
          message: 'Tem certeza que deseja realizar a operação?',
          cancel: true,
          preventClose: true,
          color: 'secondary'
        })
        this.$uloc.loading.show({message: 'Buscando lotes vendidos...'})
        await this.processaComunicacaoDetran()
      } catch (error) {
      }
    },
    processaComunicacaoDetran: async function () {
      try {
        const response = await listLoteIds(this.leilao.id)
        const idsFiltered = response.data.ids?.filter(i => LIST_STATUS_VENDA.includes(i.statusLote))
        const total = idsFiltered.length;
        let counter = 1
        for (const {numero, idLote, idBem} of idsFiltered) {
            this.$uloc.loading.show({message: `(${counter}/${total}) Sincronizando lote ${numero}, bem ID ${idBem}`})
            await this.transmitirComunicacaoDetran(idLote)
            ++counter
        }
      } catch (error) {
        this.$uloc.loading.hide()
        this.alertApiError(error)
      }
    },
    transmitirComunicacaoDetran: async function (idLote) {
      try {
        const response = await detranPBComunicacaoVenda(idLote)
        this.$uloc.loading.hide()
      } catch (error) {
        this.$uloc.loading.hide()
        this.alertApiError(error)
      }
    },
    removerImovelWeb () {
      this.click()
      this.$uloc.dialog({
        title: 'Remover Imóveis da Imóvel Web',
        message: 'Tem certeza que deseja realizar a remoção?',
        cancel: true,
        preventClose: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.loading.show({message: 'Consultando bens...'})
        listLoteIds(this.leilao.id)
            .then(response => {
              console.log(response.data)
              response.data.ids.map(lt => {
                this.$uloc.loading.show({message: `Removendo lote ${lt.numero}, bem ID ${lt.idBem}`})
                imovelWebDeletarAnuncio(lt.idBem)
                    .then(response => {
                      this.$uloc.loading.hide()
                    })
                    .catch(error => {
                      this.$uloc.loading.hide()
                      this.alertApiError(error)
                    })
              })
              /*this.$uloc.dialog({
                title: 'Atualização de valores',
                message: response.data.status
              })*/
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
    },
    sincronizarSantanderImoveis: async function () {
      try {
        this.click()
        await this.$uloc.dialog({
          title: 'Transmissão Santander Imóveis',
          message: 'Tem certeza que deseja realizar a operação?',
          cancel: true,
          preventClose: true,
          color: 'secondary'
        })
        this.$uloc.loading.show({message: 'Consultando bens...'})
        await this.processaDevolucoesSantander()
      } catch (error) {
      }
    },
    processaDevolucoesSantander: async function () {
      try {
        const response = await listLoteIds(this.leilao.id)

        const total = response.data.ids?.length || 0
        let counter = 1
        for (const {numero, idLote, idBem} of response.data.ids) {
          this.$uloc.loading.show({message: `(${counter}/${total}) Sincronizando lote ${numero}, bem ID ${idBem}`})
          await this.transmitirDevolucaoSantander(idLote)
          ++counter
        }
      } catch (error) {
        this.$uloc.loading.hide()
        this.alertApiError(error)
      }
    },
    transmitirDevolucaoSantander: async function (idLote) {
      try {
        const response = await santanderImoveisTransmiteDevolucao(idLote)
        this.$uloc.loading.hide()
      } catch (error) {
        this.$uloc.loading.hide()
        this.alertApiError(error)
      }
    },
    atualizarUrlResale: async function () {
      try {
        this.click()
        await this.$uloc.dialog({
          title: 'Transmissão URL para Resale',
          message: 'Tem certeza que deseja realizar a operação?',
          cancel: true,
          preventClose: true,
          color: 'secondary'
        })
        this.$uloc.loading.show({message: 'Consultando lotes...'})
        await this.processaDevolucoesResale()
      } catch (error) {
      }
    },
    processaDevolucoesResale: async function () {
      try {
        const response = await listLoteIds(this.leilao.id)

        const total = response.data.ids?.length || 0
        let counter = 1
        for (const {numero, idLote, idBem} of response.data.ids) {
          this.$uloc.loading.show({message: `(${counter}/${total}) Sincronizando lote ${numero}, bem ID ${idBem}`})
          await this.transmitirDevolucaoResale(idLote)
          ++counter
        }
      } catch (error) {
        this.$uloc.loading.hide()
        this.alertApiError(error)
      }
    },
    transmitirDevolucaoResale: async function (idLote) {
      try {
        const response = await resaleTransmiteUrl(idLote)
        this.$uloc.loading.hide()
      } catch (error) {
        this.$uloc.loading.hide()
        this.alertApiError(error)
      }
    },
    gerarTextos () {
      this.click()
      this.$uloc.dialog({
        title: 'Gerar Títulos e Descrições automaticamente',
        message: 'Para prosseguir, certifique-se que as definições de Montagem de Título e Descrição estejam corretas no cadastro dos tipos de bem presentes neste leilão. Tem certeza?',
        cancel: true,
        preventClose: true,
        color: 'secondary'
      }).then(data => {
        this.$uloc.loading.show({message: 'Executando operação...'})
        atualizaDescricoesBens(this.leilao.id)
            .then(response => {
              console.log(response.data)
              this.$uloc.loading.hide()
              this.$uloc.dialog({
                title: 'Atualização concluída com sucesso',
                message: response.data.status
              })
              this.leilaoPage.$refs.lotes.load()
            })
            .catch(error => {
              this.$uloc.loading.hide()
              this.alertApiError(error)
            })
      })
    },
  }
}
</script>
