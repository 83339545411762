<template>
  <ul>
    <menu-options-item close label="Abrir" @click="$emit('abrir')" />
    <menu-options-item close label="Excluir" @click="$emit('excluir')" label-class="text-negative" />
  </ul>
</template>

<script>
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
export default {
  name: 'task-list-menu',
  props: ['bem'],
  components: {MenuOptionsItem}
}
</script>
