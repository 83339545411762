<script>
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
import leilaoWindow from '../../windows/leilao'
import * as Status from '../../../../domain/leiloes/helpers/LeilaoStatus'
import {gerarEdital} from '@/domain/leiloes/services'
import {donwloadFile} from '@/utils/downloadFile'

export default {
  name: 'BtnAcoesLeilao',
  inject: ['leilaoPage'],
  props: ['leilao'],
  components: {MenuOptionsItem, UPopover},
  computed: {
    Status () {
      return Status
    }
  },
  methods: {
    leilaoWindow: leilaoWindow,
    windowLeilaoEvents (wid) {
      this.$uloc.window.listen(wid, {
        update: (wid, leilao) => {
          console.log('Updated leilão event')
          // this.load()
          this.$emit('updateLeilao')
        }
      })
    },
    gerarEdital () {
      gerarEdital(this.leilao.id)
          .then((response) => {
            donwloadFile(response)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>

<template>
  <e-btn align="left" label="Ações">
    <span class="m-l-md"></span>
    <u-icon name="chevron-down" type="fa" class="absolute-right m-l m-r-sm text-grey-7"
            style="font-size: 10px !important;" />
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu_ menu-btn-leilao">
        <ul>
          <menu-options-item v-if="cracl(null, ['sl/leilao/update'])" icon="edit" icon-type="fa" label="Editar leilão" @click="leilaoWindow(leilao.id)" close />
          <menu-options-item icon="file-pdf" icon-type="fa" label="Gerar Edital" @click="gerarEdital" close />
          <!--<menu-options-item v-if="leilao.statusInterno === STATUS_INTERNO_EM_PREPARACAO && cracl(null, ['sl/leilao/alterar-status-interno'])" @click="prepararLeilao" class="bg-green-2"
                             icon="clipboard-check"
                             icon-type="fa" label="Preparar para leilão" close />
          <menu-options-item @click="atualizarStatusInterno" class="bg-purple-2"
                             v-if="leilao.statusInterno !== STATUS_INTERNO_EM_PREPARACAO && cracl(null, ['sl/leilao/alterar-status-interno'])" icon="clipboard-check"
                             icon-type="fa" label="Atualizar status interno" close />-->
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
