<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import {list, deleteTask} from '@/domain/tarefas/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date, UProgress, UPopover, UTooltip, LocalStorage} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '../../layout/context-menu/context-window-options'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import listOptions from '../windows/listOptions'
import windowTarefa from '../windows/tarefa'
import {datePtToEn} from '@/utils/date'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "@/components/layout/components/Row"
import Resumo from "./lista/Resumo"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import TaskListMenu from "@/components/sltarefas/components/lista/list-menu"
import ContextMenuTaskItem from "@/components/sltarefas/components/lista/context-menu"
import Vue from "vue"
import EmptyTemplate from "@/components/layout/helpers/EmptyTemplate"
import {differenceInHours, parseISO, format, addDays, subDays, startOfWeek, endOfWeek} from "date-fns"
import StatusTaskSelect from "@/components/sltarefas/components/inputs/StatusTaskSelect"
import TaskGroupPersons from "@/components/sltarefas/components/others/TaskGroupPersons"
import PessoaInput from "@/components/sltarefas/components/inputs/PessoaInput"
import TypeTaskSelect from "@/components/sltarefas/components/inputs/TypeTaskSelect"
import TaskFiltrosAdicionais from "@/components/sltarefas/components/inputs/FiltrosAdicionais"
import filters from "@/domain/tarefas/helpers/filters"

const listStorage = tableColumnsCache('sl.tarefas.lista', [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Título', name: 'titulo', active: true, sortable: true, ordering: 1},
  {label: 'Descrição', name: 'descricao', active: false, sortable: true, ordering: 1},
  {label: 'Tipo', name: 'tipo', active: true, sortable: true, ordering: 1},
  {label: 'Registro', name: 'dataRegistro', active: true, sortable: true, ordering: 1},
  {label: 'Processo', name: 'processo', active: false, sortable: true, ordering: 1},
  {label: 'Cliente', name: 'cliente', active: false, sortable: true, ordering: 1},
  {label: 'Prazo', name: 'dataPrazo', active: true, sortable: true, ordering: 1},
  {label: 'Responsáveis', name: 'participantes', active: true, sortable: true, ordering: 1},
  {label: 'Progresso', name: 'progress', active: true, sortable: true, ordering: 1},
  {label: 'Status', name: 'status', active: true, sortable: true, ordering: 1},
], 10)

const listStorageIntoView = tableColumnsCache('sl.tarefas.lista.intoView', [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Título', name: 'titulo', active: true, sortable: true, ordering: 1},
  {label: 'Descrição', name: 'descricao', active: false, sortable: true, ordering: 1},
  {label: 'Tipo', name: 'tipo', active: false, sortable: true, ordering: 1},
  {label: 'Registro', name: 'dataRegistro', active: false, sortable: true, ordering: 1},
  {label: 'Prazo', name: 'dataPrazo', active: true, sortable: true, ordering: 1},
  {label: 'Responsáveis', name: 'participantes', active: true, sortable: true, ordering: 1},
  {label: 'Progresso', name: 'progress', active: true, sortable: true, ordering: 1},
  {label: 'Status', name: 'status', active: true, sortable: true, ordering: 1},
], 4)

Vue.component('layout', Layout)
Vue.component('empty', EmptyTemplate)

export default {
  provide: function () {
    return {
      container: this
    }
  },
  name: 'Tarefas',
  props: {
    layout: {
      type: String,
      default: 'layout'
    },
    relacaoEntidade: {
      required: false
    },
    relacaoEntidadeId: {
      required: false
    },
    tags: {
      type: Array, // @TODO
      required: false
    },
    cliente: {
      required: false
    },
    processo: {
      required: false
    },
    hideCalendar: {
      type: Boolean,
      required: false
    },
    hideKanban: {
      type: Boolean,
      required: false
    },
    hideWelcome: {
      type: Boolean,
      required: false
    },
    autoSelect: {
      required: false
    },
  },
  mixins: [DefaultMixin],
  components: {
    TaskFiltrosAdicionais,
    TypeTaskSelect,
    PessoaInput,
    TaskGroupPersons,
    StatusTaskSelect,
    ContextMenuTaskItem,
    TaskListMenu,
    DateInput,
    ECol,
    Resumo,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField,
    UProgress,
    ErpSelect,
    UPopover,
    UTooltip
  },
  data() {
    const lstore = this.layout === 'layout' ? listStorage : listStorageIntoView
    return {
      listStorage: lstore,
      listType: 'ativos',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: lstore.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      filtroPeriodos: [
        {name: 'Para fazer', value: null, active: true, action: function () {
          this.table.filters.data1 = null
          this.table.filters.data2 = null
          this.table.filters.dueFilter = false
        }},
        {value: 'Vencido', active: false, action: function () {
            this.table.filters.dueFilter = true
          }},
        {value: 'Hoje', active: false, action: function () {
            this.table.filters.data1 = format(new Date(), 'dd/MM/yyyy')
            this.table.filters.data2 = format(new Date(), 'dd/MM/yyyy')
            this.table.filters.tipoData = 'deadline'
          }},
        {value: 'Amanhã', active: false, action: function () {
            this.table.filters.data1 = format(addDays(new Date(), 1), 'dd/MM/yyyy')
            this.table.filters.data2 = format(addDays(new Date(), 1), 'dd/MM/yyyy')
            this.table.filters.tipoData = 'deadline'
          }},
        {value: 'Esta semana', active: false, action: function () {
            const start = addDays(startOfWeek(new Date()), 0);
            const end = subDays(endOfWeek(new Date()), 0);
            this.table.filters.data1 = format(start, 'dd/MM/yyyy')
            this.table.filters.data2 = format(end, 'dd/MM/yyyy')
            this.table.filters.tipoData = 'deadline'
          }},
        {value: 'Próxima semana', active: false, action: function () {
            const end = endOfWeek(new Date());
            this.table.filters.data1 = format(addDays(startOfWeek(addDays(end, 2)), 0), 'dd/MM/yyyy')
            this.table.filters.data2 = format(subDays(endOfWeek(addDays(end, 2)), 0), 'dd/MM/yyyy')
            this.table.filters.tipoData = 'deadline'
          }}
      ],
      participants: []
    }
  },
  computed: {
    menu() {
      return Menu
    },
    menuDashOptions() {
      return MenuOptions
    },
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  mounted() {
    if (this.testConfig('task.list', '3') && this.layout === 'layout') {
      this.table.filters.data1 = '01/01/1969'
      this.table.filters.data2 = format(new Date(), 'dd/MM/yyyy')
      this.table.filters.tipoData = 'deadline'
      this.addParticipantFilter({
        ...this.$uloc.auth.session.user,
        person: {
          id: this.$uloc.auth.session.user.person,
          name: this.$uloc.auth.session.user.name
        }
      })
    }
    if (this.parseHash()) {
      this.load()
    }
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      this.relacaoEntidade && extraFilters.push(`&entity=${this.relacaoEntidade}`)
      this.relacaoEntidadeId && extraFilters.push(`&entityId=${this.relacaoEntidadeId}`)
      this.processo && extraFilters.push(`&processo=${this.processo.id}`)
      this.cliente && extraFilters.push(`&cliente=${this.cliente.id}`)

      this.table.filters.onlyOwnerTasks && extraFilters.push(`&onlyOwnerTasks=${this.table.filters.onlyOwnerTasks}`)
      this.table.filters.participants && extraFilters.push(`&participants=${this.table.filters.participants}`)
      this.table.filters.participants && extraFilters.push(`&participantFilter=${this.table.filters.participantFilter}`)

      this.table.filters.dueFilter && extraFilters.push(`&dueFilter=${this.table.filters.dueFilter}`)

      this.table.filters.priority && extraFilters.push(`&priority=${this.table.filters.priority}`)
      this.table.filters.cliente && extraFilters.push(`&cliente=${this.table.filters.cliente.id}`)
      this.table.filters.tribunal && extraFilters.push(`&tribunal=${this.table.filters.tribunal}`)
      this.table.filters.comarca && extraFilters.push(`&comarca=${this.table.filters.comarca}`)
      this.table.filters.vara && extraFilters.push(`&vara=${this.table.filters.vara}`)
      this.table.filters.processoNumero && extraFilters.push(`&processoNumero=${this.table.filters.processoNumero.numero}`)

      if (Array.isArray(this.tags) && this.tags.length > 0) {
        extraFilters.push(`&tags=${this.tags.join(',')}`)
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&search=${filter}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            let result = data.result
            if (Number(pagination.page) < 2) {
              if (data.hoje && data.hoje.length) {
                data.hoje.map(r => {
                  r.isToday = true
                  result = result.filter(l => l.id !== r.id)
                  result.unshift(r)
                })
              }
            }
            this.table.serverData = result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
      setTimeout(() => {
        this.$refs.resumo && this.$refs.resumo.load()
      }, 2000)
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo() {
      console.log('New...')
      this.windowTarefa()
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      // this.$router.push({name: 'sl.tarefas.show', params: {id: id}})
      this.windowTarefa(id)
    },
    edit(id) {
      this.windowTarefa(id)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir esta tarefa? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir tarefa-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteTask(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Tarefa excluída com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(this.layout === 'layout' ? 'sl.tarefas.lista' : 'sl.tarefas.lista.intoView', newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      this.table.filters.onlyOwnerTasks = false
      // this.table.filters.participants = null
      // this.participants = []
      if (this.hasForMeFilter) {
        this.hasForMeFilter = false
        this.table.filters.participants = null
        this.participants = []
      }
      this.table.filters.status = []
      if (v === 'ativos') {
        this.table.filters.status = ['0', 'open', 'pending', 'in-progress', 'homolog']
      } else if (v === 'finalizados') {
        this.table.filters.status = ['recused', 'canceled', 'completed']
      }  else if (v === 'user') {
        this.table.filters.status = ['0', 'open', 'pending', 'in-progress', 'homolog']
        this.table.filters.onlyOwnerTasks = true
      } else if (v === 'forMe') {
        //this.table.filters.participants = [this.$uloc.auth.session.user.person]
        this.hasForMeFilter = true
        this.addParticipantFilter({
          ...this.$uloc.auth.session.user,
          person: {
            id: this.$uloc.auth.session.user.person,
            name: this.$uloc.auth.session.user.name
          }
        })
      }
      this.load()
    },
    windowTarefa: windowTarefa,
    buscaPorPeriodo (periodo) {
      this.table.filters.data1 = null
      this.table.filters.data2 = null
      this.table.filters.dueFilter = false
      this.table.filters.tipoData = 'deadline'
      this.$nextTick(() => {
        this.filtroPeriodos.map(l => periodo === l ? l.active = true : l.active = false)
        if (periodo.action) {
          periodo.action.call(this)
        }
      })
      this.$nextTick(() => {
        this.load()
      })
    },
    atraso (d) {
      return Math.ceil(differenceInHours(parseISO(d.date || d), new Date()) / 24) * -1
    },
    openSearchPerson () {
      // this.$refs.tooltip.hide()
      setTimeout(() => {
        this.$refs.searchPersonInput.open()
      }, 100)
    },
    addParticipantFilter (user) {
      console.log('Add user', user)
      if (!Array.isArray(this.table.filters.participants)) {
        this.table.filters.participants = []
        this.participants = []
      }
      if (!this.table.filters.participants || !this.table.filters.participants.find(p => {
        return p === user.person.id
      })) {
        this.table.filters.participants.push(user.person.id)
        this.participants.push({
          ...user.person
        })
      } else {
        this.removeParticipantFilter(user.person)
      }
      this.$nextTick(() => {
        this.load()
      })
    },
    removeParticipantFilter (person) {
      this.table.filters.participants.splice(this.table.filters.participants.indexOf(person.id), 1)
      const finded = this.participants.find(p => p.id === person.id)
      this.participants.splice(this.participants.indexOf(finded), 1)
      this.$nextTick(() => {
        this.load()
      })
    },
    label (a, b) {
      if (this.listType === b) {
        return a + ` (${this.table.serverPagination.rowsNumber})`
      }
      return a
    },
    isOpen (task) {
      return task.internal === 990 || ['open', 'pending', 'in-progress', 'homolog'].includes(task.status.code)
    },
    parseHash () {
      if (this.$route && this.$route.hash) {
        const hash = this.$route.hash.replace('#', '')
        if (hash === 'paraMim') {
          this.changeListType('forMe')
          return false
          //this.load()
        }
      }
      return true
    },
  },
  watch: {
    'table.filters.participantFilter' (v) {
      this.$nextTick(() => {
        this.load()
      })
    },
    '$route' () {
      this.$nextTick(() => {
        if (this.parseHash()) {
          this.load()
        }
      })
    }
  },
  meta: {
    title: 'Tarefas',
    name: 'Tarefas'
  }
}
</script>

<template>
  <compoment v-bind:is="layout" class="erp-list list-md" :menu="menu" menu-style="compact" menu-active="tasks" no-menu pad>
    <div>
      <div class="sl-alert m-b-lg text-center wrapper font-bold" v-if="isSuporte">
        Atenção! Você está acessando o módulo de suporte da SL. Somente será listado tarefas de suporte, demais tarefas do ERP utilize o módulo de tarefas convencional.
      </div>
      <e-row>
        <e-col>
          <resumo ref="resumo" v-if="layout === 'layout'" :participants="participants" />
        </e-col>
        <e-col style="min-width: 70%" class="text-right">
          <div class="flex content-end justify-end">
            <div class="m-l flex items-center justify-end">
              <div class="flex items-center justify-end"  v-if="participants && participants.length">
                <span class="m-r font-bold font-12 flex no-wrap items-center">
                  <span class="no-wrap flex" style="white-space: nowrap">Filtrar por</span> <erp-select style="width: 100px" class="m-l-xs" v-model="table.filters.participantFilter" :options="[{label: 'Responsável', value: 'participant'},{label: 'Proprietário', value: 'owner'}]" /></span>
<!--                <span @click="removeParticipantFilter(p)" v-for="p in participants" :key="'p-' + p.id" class="m-r-sm font-12">
                  {{p.name}}
                </span>-->
                <task-group-persons emit-click @click="(p) => removeParticipantFilter(p)" :persons="participants" class="m-r-sm font-12" />
              </div>
              <u-icon style="font-size: 12px" @click.native="openSearchPerson" name="user" type="fa" icon-style="solid" class="m-r">
                <u-tooltip :offset="[5,5]">Filtrar tarefas por Responsável(is)</u-tooltip>
                <u-popover ref="popover" @hide="$refs.searchPersonInput.close()" class="task-person-input-popover">
                  <pessoa-input @input="addParticipantFilter" @close="$refs.popover.hide()" ref="searchPersonInput" />
                </u-popover>
              </u-icon>
              <task-filtros-adicionais class="m-r" :filters="table.filters" />
            </div>
            <div class="app-input-group">
              <div class="i-group only-icon">
                <u-btn class="active" icon="list-alt" icon-style="regular" icon-type="fa" no-caps></u-btn>
                <u-btn disable v-if="!hideCalendar" icon="calendar-week" icon-type="fa" icon-style="regular" no-caps></u-btn>
                <u-btn disable v-if="!hideKanban" icon="th-large" icon-type="fa" icon-style="regular" no-caps></u-btn>
              </div>
            </div>
            <u-btn @click="novo" label="+ Criar nova tarefa" class="app-btn bg-green bold text-white m-l-sm no-shadow" size="sm" no-caps />
          </div>
          <div v-if="layout === 'layout'" class="m-t-xxl">
            <ul class="menu-alfabeto bold">
              <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
            </ul>
          </div>
<!--          <e-btn v-shortkey="['f2']" @shortkey.native="novo" @click="novo" class="full-width green-btn short-btn" align="left"><span class="text">Registrar tarefa</span> <span class="short">F2</span></e-btn>-->
        </e-col>
      </e-row>
      <div class="erp-list list-tasks no-pad">
        <div class="erp-list-top-btns">
          <div class="actives-btns">
            <u-btn @click="changeListType('ativos')" :label="label('Ativos', 'ativos')" class="active-table-btn" :class="{active: listType === 'ativos'}" no-caps />
            <u-btn v-if="testConfig('task.mostrarAbaParaMim')" @click="changeListType('forMe')" :label="label('Para mim', 'forMe')" class="active-table-btn" :class="{active: listType === 'forMe'}" no-caps />
            <u-btn v-if="testConfig('task.mostrarAbaCriadasPorMim')" @click="changeListType('user')" :label="label('Criadas por mim', 'user')" class="active-table-btn" :class="{active: listType === 'user'}" no-caps />
            <u-btn @click="changeListType('finalizados')" :label="label('Concluídas/Fechadas', 'finalizados')" class="text-grey active-table-btn" :class="{active: listType === 'finalizados'}" no-caps />
          </div>
<!--          <div class="end font-14">
            {{table.serverPagination.rowsNumber}} tarefas
          </div>-->
<!--          <div class="end">
            <u-btn @click="listOptions" icon="cog" icon-type="fa" label="Configurar lista" no-caps size="sm" flat class="text-grey-8"/>
          </div>-->
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table erp-table-options"
            :rows-per-page-options="[20, 50, 100]"
            :hide-no-results-label="true"
        >
          <template slot="top" slot-scope="props">
            <e-row mr>
              <e-col style="min-width: 150px">
                <erp-s-field
                    view="ll"
                    label="ID da tarefa"
                >
                  <erp-input shortkey="F3" v-model="table.filters.id" @keydown.enter="pesquisar"/>
                </erp-s-field>
              </e-col>
              <e-col style="min-width: 150px">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)" v-model="table.filters.data1"/>
              </e-col>
              <e-col style="min-width: 150px">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)" v-model="table.filters.data2"/>
              </e-col>
              <e-col style="min-width: 150px">
                <erp-s-field
                    view="ll"
                    label="Filtro Data"
                >
                  <erp-select
                      :options="[
                          {label: 'Criação', value: 'createdAt'},
                          {label: 'Início', value: 'startTime'},
                          {label: 'Prazo', value: 'deadline'},
                      ]"
                      v-model="table.filters.tipoData" />
                </erp-s-field>
              </e-col>
              <e-col style="min-width: 150px">
                <status-task-select field-view="ll" multiple v-model="table.filters.status" @change="load()" code-as-value />
              </e-col>

              <e-col style="min-width: 150px">
                <type-task-select field-view="ll" multiple v-model="table.filters.tipo" @change="load()" code-as-value />
              </e-col>
            </e-row>
          </template>
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
                  style="margin-left: 13px"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
              </div>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td class="pos-rlt" auto-width>
              <div v-if="props.row.priority" :class="'task-priority-label __priority-' + props.row.priority.code" :style="{backgroundColor: props.row.priority.color}"></div>
              <u-checkbox color="primary" v-model="props.selected" size="xs" style="margin-left: 15px"/>
            </u-td>
            <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td :title="props.row.resumo" class="td-limit l-130" key="titulo"
                  :props="props">
              {{ props.row.description }}
            </u-td>
            <u-td key="descricao" style="max-width: 300px; min-width: 150px; white-space: break-spaces; word-break: break-word" :props="props">
              <div v-html="props.row.text "></div>
            </u-td>
            <u-td class="td-limit l-200" key="tipo" :props="props">
              {{ props.row.type ? props.row.type.name : '-' }}
            </u-td>
            <u-td class="td-limit" key="dataRegistro" :props="props">
              <div v-if="props.row.createdAt">
                {{ props.row.createdAt.date|formatDate('dd/MM/yyyy HH:mm') }}
              </div>
              <div v-else>Nunca</div>
            </u-td>
            <u-td class="td-limit" key="processo" :props="props">
              <div v-if="props.row.processo">
                {{ props.row.processo.numero }}
              </div>
              <div v-else>-</div>
            </u-td>
            <u-td class="td-limit" key="cliente" :props="props">
              <div v-if="props.row.cliente">
                {{ props.row.cliente.name }}
              </div>
              <div v-else>-</div>
            </u-td>
            <u-td class="td-limit today" key="dataPrazo" :props="props">
              <div v-if="props.row.deadline">
                {{ props.row.deadline|formatDate('dd/MM/yyyy HH:mm') }}
                <span class="m-l-xs trask-atraso-lbl" v-if="isOpen(props.row) && atraso(props.row.deadline) > 0"><i class="fas fa-exclamation-triangle m-r-xs"></i> {{atraso(props.row.deadline)}} dia{{atraso(props.row.deadline) > 1 ? 's' : ''}}</span>
              </div>
              <span v-else>Sem previsão</span>
            </u-td>
            <u-td class="td-limit today" style="max-width: 80px; width: 80px" key="participantes" :props="props">
              <div v-if="props.row.cache && props.row.cache.participants">
                <task-group-persons :persons="props.row.cache.participants" />
              </div>
              <span v-else>-</span>
            </u-td>
            <u-td class="td-limit today" key="progress" :props="props">
              <div class="flex items-center no-wrap">
              <u-progress class="progress-sgrp m-r"
                          :percentage="props.row.progress"
                          color="positive" height="6px" style="border-radius: 5px; max-width: 70%"></u-progress>
              {{props.row.progress}}%
              </div>
            </u-td>
            <u-td class="text-uppercase" key="status" :props="props">
              <div :class="'task-status-' + props.row.status.code">{{ props.row.status ? props.row.status.name : '-' }}</div>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <task-list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                     @excluir="excluir(props.row.id)" :leilao="props.row"/>
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-task-item @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                      @excluir="excluir(props.row.id)" :leilao="props.row"/>
          </u-tr>
        </u-table>
<!--        <template v-if="!hideWelcome" slot="bottom" slot-scope="props">--> <!-- v-if="!table.serverData || !table.serverData.length" -->
        <template v-if="!hideWelcome && layout === 'layout'">
<!--          <slot name="bottom">-->
          <div class="bg-white full-width m-b m-t-n-sm pos-rlt" style="z-index: 2">
            <div class="noRowsTemplate">
              <div class="image"><img src="../assets/images/task-icon.png"></div>
              <h2>Reporte aqui tudo que precisa demandar para seu time ou para nós</h2>
              <p>Aqui você cadastra todas as tarefas e solicitações de suporte. Nós iremos avaliar e atualizá-las frequentemente, com prazo e detalhes das atividades.</p>
              <div class="norows-btn">
                <u-btn @click="novo" rounded label="Adicionar uma nova tarefa" color="positive" no-caps icon="plus" icon-type="fa" />
              </div>
            </div>
          </div>
<!--          </slot>-->
        </template>
      </div>
    </div>
  </compoment>
</template>

<!--<style lang="stylus" src="../assets/default.styl"></style>-->
