<template>
  <div class="auth-v2fa">
    <div class="login-form-pre-header">
      <div class="login-lbl text-center">Precisamos <strong>validar seu acesso</strong></div>
      <div v-if="auth.method === 'sms'">
        <div class="title">Enviamos uma código para seu telefone com final <strong>{{auth.recipient}}</strong>, digite ele abaixo:</div>
      </div>
      <div v-if="auth.method === 'email'">
        <div class="title">Enviamos uma código de segurança para seu e-mail <strong>{{auth.recipient}}</strong>, digite abaixo:</div>
      </div>
    </div>
    <div class="code-inputs">
      <input
          v-for="(value, index) in code"
          :key="index"
          type="text"
          maxlength="1"
          :autofocus="index === 0"
          v-model="code[index]"
          @input="moveToNextBox(index)"
          @keydown.backspace="moveToPreviousBox(index)"
          @paste="handlePaste($event)"
          ref="inputs"
      />
    </div>
    <u-btn :disabled="isSubmiting" @click="validar" size="md"
           label="Validar"
           class="w-full m-t-md btn-login" no-caps />
  </div>
</template>

<script>
import {login2FA} from "@/domain/globalconfig/services"
import Plugin from '@/plugins/uloc-auth/globals.js'
import {http} from "uloc-vue-auth/lib/http"

export default {
  name: "Validacao2Fa",
  props: {
    auth: {required: true}
  },
  data() {
    return {
      code: ["", "", "", "", "", ""],
      isSubmiting: false
    };
  },
  methods: {
    moveToNextBox(index) {
      // Move para o próximo input quando o usuário digitar um número
      if (this.code[index].length === 1 && index < 5) {
        this.$refs.inputs[index + 1].focus();
      }
    },
    moveToPreviousBox(index) {
      // Quando o usuário pressiona "backspace" e o campo está vazio, foca no campo anterior
      if (this.code[index] === "" && index > 0) {
        this.$refs.inputs[index - 1].focus();
      }
    },
    handlePaste(event) {
      // Manipula o evento de colar (paste)
      const pasteData = event.clipboardData.getData('text');

      if (pasteData.length === 6 && !isNaN(pasteData)) {
        // Se for um código com 6 números
        this.code = pasteData.split(""); // Divide os números e preenche o array
        this.$nextTick(() => {
          this.$refs.inputs[5].focus(); // Foca no último input
        });
      }
      event.preventDefault(); // Evita o comportamento padrão de colar
    },
    validar () {
      const codeString = this.code.join('')
      this.isSubmiting = true
      login2FA(this.auth.token, codeString)
          .then(response => {
            Plugin.logged = true
            http.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token
            this.$uloc.auth.save(response.data)
            this.isSubmiting = false

            if (this.$route.query.redirect && this.$route.query.redirect !== '/logout') {
              this.$router.push({path: this.$route.query.redirect})
            } else {
              this.$router.push('/')
            }
          })
          .catch(error => {
            this.isSubmiting = false
            this.alertApiError(error)
          })
    }
  }
}
</script>
