<template>
  <list-select ref="select" v-on:input="(v) => $emit('input', v)" :search-data="search" v-model="model" />
</template>

<script>
import ListSelect from "@/reuse/input/ListSelect"
import UFs from "@/domain/remocao/helpers/UFs"
export default {
  name: "UfSelect",
  props: {
    value: {
      required: true
    },
    city: {
      required: false
    },
    fullName: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      model: this.value
    }
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        if (v !== this.model && v) {
          this.$refs.select.autoSelectValue(v)
        }
      })
    }
  },
  components: {ListSelect},
  methods: {
    search (terms) {
      console.log(terms)
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          let list = UFs.map((obj) => {
            return {
              ...obj,
              label: this.fullName ? obj.nome : obj.sigla,
              value: obj.sigla
            }
          }).sort((a, b) => {
            if (a.nome > b.nome) {
              return 1;
            }
            if (a.nome < b.nome) {
              return -1;
            }
            // a must be equal to b
            return 0;
          })
          resolve(list)
        })
      })
    }
  }
}
</script>
