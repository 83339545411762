import Vue from 'vue';
export const usersState = Vue.observable({
  count: 0,
  groups: 0
})
const financeiro = {
  title: 'Financeiro',
  default: 'hom',
  shortName: 'FIN',
  menu: [
    {
      id: 'erp-usuarios',
      title: 'Usuários',
      icon: 'users',
      style: 'light',
      url: '/#/configuracoes/usuarios',
      get count() {
        return usersState.count || null;
      }
    },
    {
      id: 'erp-usuarios-groups',
      title: 'Grupos de Acesso',
      icon: 'ballot-check',
      style: 'light',
      // disabled: true,
      url: '/#/configuracoes/usuarios/grupos',
      get count() {
        return usersState.groups || null;
      }
    }
  ]
}

export default financeiro
