import {atualizaStatusInternoLeilao, atualizaStatusLeilao} from "@/domain/leiloes/services";

export default {
    data () {
        return {
            loadingUpdateStatus: false,
            loadingUpdateStatusInterno: false
        }
    },
    methods: {
        updateStatus (s) {
            console.log('Update status fom ', this.leilao.status, ' to ', s)
            this.loadingUpdateStatus = true
            atualizaStatusLeilao(this.leilao.id, s)
                .then((response) => {
                    this.loadingUpdateStatus = false
                    this.leilao.status = s
                })
                .catch(error => {
                    this.loadingUpdateStatus = false
                    this.alertApiError(error)
                    console.log(error, error.data)
                })
        },
        updateStatusInterno (s) {
            console.log('Update status fom ', this.leilao.statusInterno, ' to ', s)
            this.loadingUpdateStatusInterno = true
            atualizaStatusInternoLeilao(this.leilao.id, s)
                .then((response) => {
                    this.loadingUpdateStatusInterno = false
                    this.leilao.statusInterno = s
                })
                .catch(error => {
                    this.loadingUpdateStatusInterno = false
                    this.alertApiError(error)
                    console.log(error, error.data)
                })
        },
    }
}
