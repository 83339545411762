export {getStatus} from '../../../utils/getStatus'

export const STATUS_PENDENTE = 0;
export const STATUS_APROVADO = 1;
export const STATUS_REPROVADO = 2;
export const STATUS_BLOQUEADO = 3;
export const STATUS_EM_ANALISE = 4;

export const Status = {
  [STATUS_PENDENTE]: {'title': 'Pendente', 'class': 'arrematante-status-' + STATUS_PENDENTE, color: 'text-warning'},
  [STATUS_APROVADO]: {'title': 'Aprovado', 'class': 'arrematante-status-' + STATUS_APROVADO, color: 'text-green'},
  [STATUS_REPROVADO]: {'title': 'Reprovado', 'class': 'arrematante-status-' + STATUS_REPROVADO, color: 'text-red-5'},
  [STATUS_BLOQUEADO]: {'title': 'Bloqueado', 'class': 'arrematante-status-' + STATUS_BLOQUEADO, color: 'text-purple'},
  [STATUS_EM_ANALISE]: {'title': 'Em análise', 'class': 'arrematante-status-' + STATUS_EM_ANALISE, color: 'text-warning'}
}

export const TIPO_PESSOA_FISICA = 1;
export const TIPO_PESSOA_JURIDICA = 2;

export const Tipo = {
  [TIPO_PESSOA_FISICA]: {'title': 'Pessoa física', 'class': 'arrematante-tipo-' + TIPO_PESSOA_FISICA},
  [TIPO_PESSOA_JURIDICA]: {'title': 'Pessoa jurídica', 'class': 'arrematante-tipo-' + TIPO_PESSOA_JURIDICA}
}

export const ESTADO_CIVIL_SOLTEIRO = 1
export const ESTADO_CIVIL_CASADO = 2
export const ESTADO_CIVIL_VIUVO = 3
export const ESTADO_CIVIL_SEPARADO = 4
export const ESTADO_CIVIL_DIVORCIADO = 5
export const ESTADO_CIVIL_UNIAO_ESTAVEL = 6

export const EstadoCivil = [
  {label: 'Solteiro(a)', value: ESTADO_CIVIL_SOLTEIRO},
  {label: 'Casado(a)', value: ESTADO_CIVIL_CASADO},
  {label: 'Viúvo(a)', value: ESTADO_CIVIL_VIUVO},
  {label: 'Separado(a)', value: ESTADO_CIVIL_SEPARADO},
  {label: 'Divorciado(a)', value: ESTADO_CIVIL_DIVORCIADO},
  {label: 'União estável', value: ESTADO_CIVIL_UNIAO_ESTAVEL}
]

export const REGIME_CASAMENTO_COMUNHAO_PARCIAL = 1
export const REGIME_CASAMENTO_COMUNHAO_UNIVERSAL = 2
export const REGIME_CASAMENTO_SEPARACAO_BENS = 3
export const REGIME_CASAMENTO_SEPARACAO_BENS_LIBERDADE = 4
export const REGIME_CASAMENTO_COMUNHAO_PARCIAL_JUSTICA_UNIAO = 5

export const RegimesCasamento = [
  {label: 'Comunhão parcial de bens', value: REGIME_CASAMENTO_COMUNHAO_PARCIAL},
  {label: 'Comunhão universal de bens', value: REGIME_CASAMENTO_COMUNHAO_UNIVERSAL},
  {label: 'Separação de bens', value: REGIME_CASAMENTO_SEPARACAO_BENS},
  {label: 'Separação total de bens, livre escolha', value: REGIME_CASAMENTO_SEPARACAO_BENS_LIBERDADE},
  {label: 'Separação total de bens, obrigatória (CC art. nº1.641)', value: REGIME_CASAMENTO_COMUNHAO_PARCIAL_JUSTICA_UNIAO}
]

export const StatusDocumento = {
  0: {title: 'Aguardando o envio', class: 'a-doc-s-0', icon: 'user-clock'},
  1: {title: 'Em análise', class: 'a-doc-s-1', icon: 'clock'},
  2: {title: 'Verificado', class: 'a-doc-s-2', icon: 'check-square'},
  3: {title: 'Ilegível', class: 'a-doc-s-3', icon: 'file-excel'},
  4: {title: 'Incorreto', class: 'a-doc-s-4', icon: 'times'},
  5: {title: 'Fraude', class: 'a-doc-s-5', icon: 'user-secret'},
}
