<template>
  <ul class="roles-select">
    <li v-for="role in roles" :key="role.value">
      <label>
        <u-checkbox v-model="user.roles" :val="role.value" /> <span>{{role.label}}</span>
      </label>
    </li>
  </ul>
</template>

<script>
import {UCheckbox} from "uloc-vue"
import {roles} from "@/domain/usuario/helpers/roles"

export default {
  name: 'UserRoleSelect',
  props: {
    user: {required: true},
    defaultRoles: {type: Array, required: false}
  },
  data () {
    const _roles = Object.keys(roles).map(role => {
      if (this.defaultRoles && this.defaultRoles.includes(role)) {
        if (!Array.isArray(this.user.roles)) {
          this.user.roles = []
        }
        !this.user.roles.includes(role) && this.user.roles.push(role)
      }
      return {label: roles[role], value: role}
    })
    return {
      roles: _roles
    }
  },
  components: {
    UCheckbox
  }
}
</script>
