<template>
  <div v-if="cracl('ROLE_ADMIN', 'config/gerarDocs')" @click="gerarDocumentoWindow" class="flex items-center cursor-pointer">
    <PosMenuIcon name="folder-open" icon-type="fa" icon-style="light">
    </PosMenuIcon>
    <span class="m-r m-l-n font-10">Gerador Doc</span>
  </div>
</template>

<script>
import {PosMenuIcon} from 'uloc-vue-plugin-erp'
import geradorWindow from "components/layout/components/gerador/geradorWindow"

export default {
  name: "GeradorDocumento",
  components: {
    PosMenuIcon,
  },
  methods: {
  }
}
</script>
