import http from '../../../utils/services/http'

export const dashboard = () => {
  return http.get('/api/patios/resumeDashboard')
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const list = (limit, page, filtros) => {
  let url = '/api/patios?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const listSimple = () => {
    let url = '/api/patios/list-simple'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const find = (id) => {
  let url = `/api/patios/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newPatio = (data) => {
  let url = `/api/patios`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updatePatio = (id, data) => {
  let url = `/api/patios/${id}`
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deletePatio = (id) => {
  let url = `/api/patios/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const validaSiglaPatio = (sigla, local = false) => {
  let url = `/api/patios/valida-sigla?sigla=${sigla}&local=${local}`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const listPossiveisResponsaveis = (limit, page, filtros) => {
    let url = '/api/patios/lista-possiveis-responsaveis?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const adicionarResponsavelPatio = (patioId, responsavelId) => {
    let url = `/api/patios/${patioId}/adiciona-responsavel`
    return http.post(url, {pessoa: responsavelId})
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const tipoVaga = () => {
    const url = '/api/remocoes/app/tiposVaga'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const locais = (tipo, externo) => {
    const url = '/api/remocoes/app/locais?tipo=' + tipo + '&externo=' + (externo ? '1' : '0')
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const vagas = (local, andar) => {
    const url = '/api/remocoes/app/vagas?local=' + local + '&andar=' + andar
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const armazenar = (bem, vaga) => {
    const url = `/api/remocoes/app/bens/${bem}/armazenar/${vaga}`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const liberarVaga = (id) => {
    const url = `/api/remocoes/vagas/${id}/liberar`
    return http.post(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export default {
    dashboard,
    list,
    find,
    listSimple
}
