<script>
import {UPopover} from 'uloc-vue'
import {Status, getStatus} from '@/domain/leiloes/helpers/LeilaoStatus'
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'

export default {
  name: 'BtnStatusLeilao',
  props: ['leilao', 'loading'],
  components: {MenuOptionsItem, UPopover},
  computed: {
    status () {
      return Object.keys(Status).map((k) => {
        return {
          label: Status[k].title,
          value: Number(k),
          class: typeof Status[k].title !== 'undefined' ? Status[k].class : null
        }
      })
    },
    statusFiltered () {
      return this.status.filter((s) => s.value !== Number(this.leilao.status))
    }
  },
  /*mounted() {
    const selector = document.querySelector('.posmenu-default')
    if (selector) {
      this.selector = selector
      selector.querySelector('.right-rest').prepend(this.$el)
      this.$el.classList.add('btn-prepend-postmenu')
    }
  },
  beforeDestroy() {
    if (this.selector) {
      selector.querySelector('.right-rest').removeChild(this.$el)
    }
  },*/
  methods: {
    getStatus (s) {
      const _s = getStatus(s, Status)
      if (this.leilao && this.leilao.vendaDireta && _s && _s.title && _s.title === 'Aberto para lances') {
        return {..._s, title: 'Aberto para propostas'}
      }
      return _s
    },
    updateStatus(newStatus) {
      this.$uloc.dialog({
        title: 'Alterar status do leilão',
        message: `Tem certeza que deseja alterar o status de ${this.getStatus(this.leilao.status).title} para ${this.getStatus(newStatus).title} ?`,
        noCaps: true,
        ok: `Confirmar novo status: ${this.getStatus(newStatus).title}`,
        cancel: 'Não'
      })
      .then(() => {
        this.$emit('updateStatus', newStatus)
      })
      .catch(() => {

      })

    }
  }
}
</script>

<template>
  <e-btn :disabled="!cracl('ROLE_LEILAO', ['sl/leilao/update', 'sl/leilao/status-leilao'])" :loading="loading" class="w-full text-left leilao-status v2 scolor" align="left" :class="getStatus(leilao.status).class || ''" :label="getStatus(leilao.status).title">
    <u-icon name="chevron-down" type="fa" class="m-l-xs m-r-sm text-grey" style="font-size: 10px !important;"/>
    <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
      <div class="menu menu-btn-leilao">
        <ul>
          <menu-options-item v-for="(s, i) in statusFiltered" close :key="i" class="leilao-status scolor" :class="s.class" @click="updateStatus(s.value)">
          <span class="menuLabel">
                <div class="flex col-grow no-wrap justify-between">
                  <div class="flex items-center">
                    <u-icon name="square" type="fa" class="m-r-xs" />
                    <span class="menu-label-content">{{leilao && leilao.vendaDireta ? (s.label === 'Aberto para lances' ? 'Aberto para Propostas' : s.label) : s.label}}</span>
                  </div>
                </div>
            </span>
          </menu-options-item>
        </ul>
        <slot />
      </div>
    </u-popover>
  </e-btn>
</template>
