<script>

import ModelToggleMixin from "uloc-vue/src/mixins/model-toggle"
import PreventScroll from "uloc-vue/src/mixins/prevent-scroll"
import {stopAndPrevent} from "uloc-vue/src/utils/event"
// import EscapeKey from "uloc-vue/src/utils/escape-key"

export default {
  provide: function () {
    return {
      printView: this,
    }
  },
  inject: {
    container: {
      default() {
        console.error('UPrintView needs to be child of UPrintViewContainer')
      }
    }
  },
  nome: 'PrintView',
  mixins: [ModelToggleMixin, PreventScroll],
  props: {
    wid: String,
    close: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    pdf: {
      type: Function,
      default: null
    },
    excel: {
      type: Function,
      default: null
    },
    csv: {
      type: Function,
      default: null
    },
    email: {
      type: Function,
      default: null
    },
    printMenu: {
      type: Object,
      default: null
    },
    contentClass: String,
    windowClass: String,
    theme: String,
    edit: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      printOptions: {
        pdf: this.pdf,
        excel: this.excel,
        csv: this.csv,
        email: this.email
      }
    }
  },
  computed: {},
  created() {
    this.$on('printer', this.print)
    window.addEventListener('keyup', this.eventKey)
    if (this.container) {
      this.container.instances.push(this)
    }
  },
  mounted() {
    const container = this.container.$el
    container.appendChild(this.$el)
    this.oldTitle = document.title
    document.title = this.title
  },
  beforeDestroy() {
    this.$off('printer', this.print)
    window.removeEventListener('keyup', this.eventKey)
    if (this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.container) {
      this.container.instances.splice(this.getInstance(), 1)
    }
    document.title = this.oldTitle
  },
  deactivated() {
  },
  watch: {},
  methods: {
    getInstance() {
      return this.container.instances.indexOf(this)
    },
    updateZindex() {
      if (!this.container) {
        return this.zindex
      }
      this.zindex = this.container.zindex + this.container.instances.length + 1
    },
    __show(e) {
      this.$nextTick(() => {
        this.updateZindex()
      })
    },
    __hide(e) {
      e && stopAndPrevent(e)
      if (this.container) {
        if (this.container.instanceActive === this) {
          this.container.instanceActive = null
        }
      }
      // EscapeKey.findAndPop(this.closeOnEscapeKey)
      // EscapeKey.enable()
      this.__emit('close', this.wid)
    },
    closeOnEscapeKey() {
      this.hide()
    },
    __emit(event, data) {
      this.$emit(event, this.wid)
      if (this.wid) { // is plugin
        this.$uloc.printView.emit(this.wid, {type: event, data: data})
      }
    },
    eventKey(evt) {
      if (evt.which === 27 || evt.keyCode === 27) {
        this.$uloc.dialog({
          title: 'Sair do relatório',
          message: 'Ao sair, você perderá as alterações realizadas, tem certeza?',
          ok: 'Sair',
          cancel: 'Cancelar',
          color: 'negative'
        }).then(() => {
          this.$uloc.printView.close(this.wid)
        }).catch(() => {})
      }
    },
    print() {
      const areaPrint = document.createElement('div')
      areaPrint.classList.add('removeAfterPrint')
      const printer = document.querySelector('#container-printer')
      const printerCopy = printer.cloneNode(true)
      printerCopy.classList.add('removeAfterPrint')
      areaPrint.appendChild(printerCopy)
      document.body.appendChild(areaPrint)
      const app = document.querySelector('#uloc-app')
      app.classList.add('hide')
      app.classList.add('print-view-active')
      printer.classList.add('hidden-print')
      this.$nextTick(() => {
        window.print()
        setTimeout(() => {
          app.classList.remove('hide')
          app.classList.remove('print-view-active')
          printer.classList.remove('hidden-print')
          let nodeToRemove = document.querySelector('.removeAfterPrint')
          document.body.removeChild(nodeToRemove)
        }, 300)
      })
    }
  }
}
</script>

<template>
  <div class="print-view">
    <div class="r-header hidden-print">
      <div class="header1">
        <h1>{{title}}</h1>
        <div @click="$uloc.printView.close(wid)" class="exit">
          Sair do modo relatório <span>(ESC)</span>
        </div>
      </div>
      <div class="header2 hidden-print">
        <slot name="menu">
          <ul class="r-options" role="tablist">
<!--            <li role="presentation">
              <a role="tab"
                 tabindex="-1" aria-selected="false" class="active">
                <div>Saídas</div>
                <span></span>
              </a>
            </li>-->
          </ul>
        </slot>
        <div class="r-buttons">
          <u-btn @click="printOptions.excel && printOptions.excel()" flat icon="file-excel" icon-type="fa" v-if="printOptions.excel" title="Baixar em Excel" />
          <u-btn @click="printOptions.pdf && printOptions.pdf()" flat icon="file-pdf" icon-type="fa" v-if="printOptions.pdf" title="Baixar PDF" />
          <u-btn @click="printOptions.csv && printOptions.csv()" flat icon="file-csv" icon-type="fa" v-if="printOptions.csv" title="Baixar em CSV" />
          <!--<u-btn @click="email && email()" flat icon="envelope-open-text" icon-type="fa" v-if="email" title="Enviar por e-mail" /> @TODO: V2.5 -->
          <button class="btn-info2" @click="print"><i class="fa fa-print m-r-xs"></i> Imprimir <span>Shift + P</span></button>
        </div>
      </div>
    </div>
    <div class="r-content" :contenteditable="edit">
      <slot></slot>
    </div>
  </div>
</template>
