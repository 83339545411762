// import Main from './Main'
import Page from './components/Page'
import Main from './components/Main'
import ListAcessorios from './components/acessorios/List'
import ListTiposBem from './components/tiposbem/List'
import ListTiposCancelamentoVenda from './components/tiposcancelamentovenda/List'
import ListTiposApreensao from './components/tiposapreensao/List'
import ListTiposNotificacao from './components/tiposnotificacao/List'
import ListTiposArquivo from './components/tiposarquivo/List'
import ListTiposCombustivel from './components/tiposcombustivel/List'
import ListTiposPericia from './components/tipospericia/List'
import ListTiposDocumentoLeilao from './components/tiposdocumentoleilao/List'
// import ListTiposInteresseArrematante from './components/tiposinteressearrematante/List'
import ListDocumentosRequeridosArrematante from './components/documentorequeridoarrematante/List'
import ListTiposCor from './components/tiposcor/List'
import ListTiposConservacaoBem from './components/tiposconservacaobem/List'
import ListTiposPatio from './components/tipospatio/List'
import ListTiposVagaPatio from './components/tiposvagapatio/List'
import ListTiposEndereco from './components/tiposendereco/List'
import ListTiposEmail from './components/tiposemail/List'
import ListTiposTelefone from './components/tipostelefone/List'
import ListTiposContato from './components/tiposcontato/List'
import ListTiposComitente from './components/tiposcomitente/List'
import ListMarcas from './components/marcas/List'
import ListModelos from './components/modelos/List'
import ListLeiloeiros from './components/leiloeiros/List'
import ListClassificacoesLeilao from './components/classificacoesLeilao/List'
import ListBancos from './components/bancos/List'
import ListMetodosPagamentoLote from './components/metodospagamentolote/List'
import ListGrupoComitentes from './components/gruposcomitente/List'

export default [
  {
    path: '/cadastro', component: Page, name: 'cadastro.page', children: [
      {
        path: '',
        name: 'cadastro.main',
        component: Main
      },
      {
        path: 'acessorios',
        name: 'acessorios.main',
        component: ListAcessorios
      },
      {
        path: 'tiposbem',
        name: 'tiposbem.main',
        component: ListTiposBem
      },
      {
        path: 'tiposcancelamentovenda',
        name: 'tiposcancelamentovenda.main',
        component: ListTiposCancelamentoVenda
      },
      {
        path: 'tiposapreensao',
        name: 'tiposapreensao.main',
        component: ListTiposApreensao
      },
      {
        path: 'tiposnotificacao',
        name: 'tiposnotificacao.main',
        component: ListTiposNotificacao
      },
      {
        path: 'tiposarquivo',
        name: 'tiposarquivo.main',
        component: ListTiposArquivo
      },
      {
        path: 'tiposarquivoarrematante',
        name: 'tiposarquivoarrematante.main',
        component: () => import('./components/tiposarquivoarrematante/List')
      },
      {
        path: 'tiposcombustivel',
        name: 'tiposcombustivel.main',
        component: ListTiposCombustivel
      },
      {
        path: 'tiposfinalidadeimovel',
        name: 'tiposfinalidadeimovel.main',
        component: () => import('./components/tiposfinalidadeimovel/List')
      },
      {
        path: 'tipospericia',
        name: 'tipospericia.main',
        component: ListTiposPericia
      },
      {
        path: 'tiposdocumentoleilao',
        name: 'tiposdocumentoleilao.main',
        component: ListTiposDocumentoLeilao
      },
      /* {
        path: 'tiposinteressearrematante',
        name: 'tiposinteressearrematante.main',
        component: ListTiposInteresseArrematante
      }, */
      {
        path: 'documentosrequeridosarrematante',
        name: 'documentosrequeridosarrematante.main',
        component: ListDocumentosRequeridosArrematante
      },
      {
        path: 'tiposcor',
        name: 'tiposcor.main',
        component: ListTiposCor
      },
      {
        path: 'tiposconservacaobem',
        name: 'tiposconservacaobem.main',
        component: ListTiposConservacaoBem
      },
      {
        path: 'tipospatio',
        name: 'tipospatio.main',
        component: ListTiposPatio
      },
      {
        path: 'tiposvagapatio',
        name: 'tiposvagapatio.main',
        component: ListTiposVagaPatio
      },
      {
        path: 'tiposendereco',
        name: 'tiposendereco.main',
        component: ListTiposEndereco
      },
      {
        path: 'tiposemail',
        name: 'tiposemail.main',
        component: ListTiposEmail
      },
      {
        path: 'tipostelefone',
        name: 'tipostelefone.main',
        component: ListTiposTelefone
      },
      {
        path: 'tiposcontato',
        name: 'tiposcontato.main',
        component: ListTiposContato
      },
      {
        path: 'tiposcomitente',
        name: 'tiposcomitente.main',
        component: ListTiposComitente
      },
      {
        path: 'marcas',
        name: 'marcas.main',
        component: ListMarcas
      },
      {
        path: 'modelos',
        name: 'modelos.main',
        component: ListModelos
      },
      {
        path: 'leiloeiros',
        name: 'leiloeiros.main',
        component: ListLeiloeiros
      },
      {
        path: 'classificacoesLeilao',
        name: 'classificacoesLeilao.main',
        component: ListClassificacoesLeilao
      },
      {
        path: 'bancos',
        name: 'bancos.main',
        component: ListBancos
      },
      {
        path: 'metodospagamentolote',
        name: 'metodospagamentolote.main',
        component: ListMetodosPagamentoLote
      },
      {
        path: 'gruposcomitentes',
        name: 'gruposcomitentes.main',
        component: ListGrupoComitentes
      }
    ]
  }
]
