<template>
  <div class="menu_submenu">
    <div class="submenu-column submenu-column_1">
      <ul class="submenu_inner submenu_inner_level-1">
        <li class="submenu_item submenu_item_level-2">
          <span class="submenu_link submenu_link_nolink"><span class="submenu_link-text">Fechamento do Leilão</span></span>
          <ul class="submenu_inner_level-2">
            <nav-sub-link @click="$router.push({name: 'leiloes.fechamento.cobranca'})" icon="pay1" icon-type="erp" label="Cobrança" />
            <nav-sub-link @click="$router.push({name: 'leiloes.fechamento.formalizacao'})" icon="contract" icon-type="erp" label="Fila de Formalização" />
            <nav-sub-link @click="$router.push({name: 'pda.main', query: {app: 'app-iniciar-atendimento-leilao', leilao: leilao.id}})" icon="thermal-print" icon-type="erp" label="Pagamento de Lotes" />
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavSubLink from './NavSubLink'
import LeilaoMixin from "@/components/leiloes/components/LeilaoMixin"
export default {
  name: 'FechamentoSubmenu',
  mixins: [LeilaoMixin],
  components: {NavSubLink},
  inject: ['nav'],
  methods: {
    click () {
      this.nav.clickEvent()
      return true
    }
  }
}
</script>
