export default [
    {
        path: '/marketplace',
        component: () => import('./Page'),
        name: 'marketplace.page',
        children: [
            {
                path: '', name: 'marketplace.dashboard', component: () => import('./components/Dashboard'),
                children: [
                    {path: 'produtos/:id', name: 'marketplace.produto', component: () => import('./components/Produto')},
                    {path: 'produtos/:id/contratar', name: 'marketplace.produto.contratar', component: () => import('./components/Contratar')},
                ]
            },
        ]
    }
]
