<template>
  <div class="flex no-wrap items-center" style="margin-left: -3px !important;">
    <div class="flex-zero m-r-xs">
      <label data-test="stage-1-rotting-flag" class="check-switch" :class="bindClasses">
        <input :disabled="disabled" type="checkbox" v-model="model" v-on:input="emit">
        <div class="check-switch-toggle"></div>
      </label>
    </div>
    <span v-show="!noYesNoVal" class="col-grow-1 font-12 sl-checkbox-confim" v-if="parseValueComputed">{{ yesVal }}</span>
    <span v-show="!noYesNoVal" class="col-grow-1 font-12 sl-checkbox-confim" v-else>{{ noVal }}</span>
  </div>
</template>

<script>
export default {
  name: "SlCheckbox",
  props: {
    value: {
      required: true
    },
    trueValue: {required: false},
    falseValue: {required: false},
    yesVal: {
      default: 'Sim'
    },
    noVal: {
      default: 'Não'
    },
    color: {
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noYesNoVal: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value (v) {
      this.$nextTick(() => {
        const vParsed = this.parseReverseValue(v)
        if (this.model !== vParsed) {
          this.model = vParsed
        }
      })
    }
  },
  data () {
    return {
      model: this.parseReverseValue(this.value)
    }
  },
  computed: {
    parseValueComputed () {
      return this.parseReverseValue(this.value)
    },
    bindClasses () {
      const css = []
      if (this.color) {
        css.push('color-' + this.color)
      }
      return css
    }
  },
  methods: {
    emit (e) {
      const v = this.parseValue(e.target.checked)
      this.$emit('input', v)
      this.model = this.parseReverseValue(v)
    },
    parseValue (v) {
      let v2 = v
      if (typeof this.trueValue !== 'undefined' && this.trueValue !== null && v) {
        v2 = this.trueValue
      }
      if (typeof this.falseValue !== 'undefined' && this.falseValue !== null && !v) {
        v2 = this.falseValue
      }
      return v2
    },
    parseReverseValue (v) {
      let v2 = v
      if (typeof this.trueValue !== 'undefined' && this.trueValue !== null && v == this.trueValue) {
        v2 = true
      }
      if (typeof this.falseValue !== 'undefined' && this.falseValue !== null && v == this.falseValue) {
        v2 = false
      }
      return v2
    }
  }
}
</script>
