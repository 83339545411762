import http from '../utils/services/http'
import env from '../env'
import {Provider} from 'uloc-vue-auth'

export const worker = async () => {
    // @TODO TMP. Remover
    if (window && window.location && window.location.hostname && window.location.hostname.endsWith('erp-ovh.e-leiloes.com.br')) {
        env.api = 'https://api.e-leiloes.com.br'
        http.defaults.baseURL = env.api
        const _provider = Provider.get()
        _provider.urlApi = env.api
    }
    return new Promise((resolve, reject) => {
        try {
            if (typeof process.env.VUE_APP_WORKER_SERVER === 'undefined' || !process.env.VUE_APP_WORKER_SERVER) {
                resolve()
                return
            }
            http.get(process.env.VUE_APP_WORKER_SERVER + '/client', {
                transformRequest: (data, headers) => {
                    if (typeof headers.common.Authorization !== 'undefined') {
                        delete headers.common.Authorization
                    }
                    return data
                },
                timeout: 5000
            })
                .then(response => {
                    if (typeof response.data.api !== 'undefined' && response.data.api) {
                        env.api = response.data.api
                        http.defaults.baseURL = env.api
                        const _provider = Provider.get()
                        _provider.urlApi = env.api
                    }
                    if (typeof response.data.websocket !== 'undefined' && response.data.websocket) {
                        env.websocket = response.data.websocket
                    }
                    resolve(response)
                })
                .catch(({response}) => {
                    reject(response)
                })
        } catch (e) {
            resolve()
        }
    })
}

export const pingApi = () => {
    const url = '/api/public/ping'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
export const servicos = () => {
    const url = '/api/public/publicServices'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const consultaIp = (ip) => {
    return http.get(`https://ipinfo.io/${ip}/geo`, {
        transformRequest: (data, headers) => {
            if (typeof headers.common.Authorization !== 'undefined') {
                delete headers.common.Authorization
            }
            return data
        },
        timeout: 5000
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const responseError = ({response}) => {
    if (response.data instanceof Blob) {
        return new Promise((resolve, reject) => {
            const fr = new FileReader();
            fr.onload = function () {
                const r = JSON.parse(this.result) // @?
                reject({data: r})
            }
            fr.readAsText(response.data)
        })
    } else {
        return Promise.reject(response)
    }
}
