import {roles} from "@/domain/usuario/helpers/roles";

export default {
    methods: {
        isAdmin (user) {
            if (user.roles) {
                return user.roles.includes('ROLE_ADMIN')
            }
            return false
        },
        getPapers (r) {
            const userRoles = []
            r.map(role => {
                if (typeof roles[role] !== 'undefined') {
                    userRoles.push(roles[role])
                }
            })

            return userRoles.join(', ')
        }
    }
}
