<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import {list, deleteArrematante} from '@/domain/arrematante/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage, UTooltip} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import StatusArrematante from './include/StatusArrematante'
import ContextMenuArrematanteItem from './context/list-context-arrematante'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu-arrematante'
//import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import StatusSelect from './include/StatusArrematanteSelect'
import DateInput from '../../../reuse/input/Date'
import arrematanteWindow from '../windows/arrematante'
import TipoArrematanteSelect from './include/TipoArrematanteSelect'
import {datePtToEn} from '@/utils/date'
import ECol from "components/layout/components/Col.vue"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import ERow from "components/layout/components/Row.vue"
import FiltrosAdicionais from "components/arrematante/components/include/ListFilters"
import tableColumnsCache from "@/utils/tableColumnsCache"
import listOptions from "components/usuario/v2/window/listOptions"
import filters from "@/domain/arrematante/helpers/filters"
import {addDays, endOfWeek, format, startOfWeek, subDays} from "date-fns";

let listName = 'arrematantes.lista'
let listStorage = tableColumnsCache(listName, [
  {
    name: 'photo',
    required: true,
    label: 'photo',
    align: 'left',
    field: 'photo',
    sortable: false,
    active: true
  },
  {
    name: 'nome',
    required: true,
    label: 'Nome',
    align: 'left',
    field: 'nome',
    sortable: true,
    active: true
  },
  {name: 'id', label: 'ID', field: 'id', align: 'left', sortable: true, active: true},
  {
    name: 'apelido',
    required: true,
    label: 'Apelido',
    align: 'left',
    field: 'apelido',
    sortable: true,
    active: true
  },
  {
    name: 'documento',
    required: false,
    label: 'Documento',
    align: 'left',
    field: 'documento',
    sortable: false,
    active: true
  },
  {
    name: 'dataCadastro',
    required: false,
    label: 'Data Cadastro',
    align: 'left',
    field: 'dataCadastro',
    sortable: true,
    active: true
  },
  {
    name: 'compras',
    required: false,
    label: 'Compras',
    align: 'left',
    field: 'compras',
    sortable: true,
    active: true
  },
  {
    name: 'lances',
    required: false,
    label: 'Lances',
    align: 'left',
    field: 'lances',
    sortable: true,
    active: true
  },
  {
    name: 'status',
    required: true,
    label: 'Situação',
    align: 'left',
    field: 'status',
    sortable: false,
    active: true
  }
], 0)

export default {
  name: 'Arrematantes',
  provide: function () {
    return {
      container: this
    }
  },
  props: {
    title: {
      default: 'Arrematante'
    },
    listStorageProp: {
      default: null
    },
    listStorageName: {
      default: null
    },
  },
  mixins: [DefaultMixin],
  components: {
    ERow,
    ECol,
    ContextMenuArrematanteItem,
    TipoArrematanteSelect,
    // DateInput,
    StatusSelect,
    // SearchFilter,
    DefaultListMenu,
    MenuOptions,
    StatusArrematante,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    EBtn,
    // ErpSelect,
    ErpSField,
    FiltrosAdicionais,
    UTooltip
  },
  data () {
    if (this.listStorageProp) {
      listStorage = this.listStorageProp
      listName = this.listStorageName
    }
    return {
      listStorage: listStorage,
      filtersData: {
        uf: {
          loading: true,
          serverData: []
        }
      },
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20,
          sortBy: 'id',
          descending: true
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      stats: {
        cadastradosHoje: 0,
        cadastradosSemana: 0,
        paraAnalisar: 0
      },
      statsFilterActive: null
    }
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    request ({pagination, filter}) {

      let data1 = ''
      let data2 = ''
      let extraFilters = []
      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        extraFilters.push(`&data1=${data1}`)
        extraFilters.push(`&data2=${data2}`)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
      }/* else {
        let substract = Number(this.filtros.tipoFiltroData)
        let newDate = new Date()
        newDate = date.subtractFromDate(newDate, {days: substract})
        data1 = date.formatDate(newDate, 'YYYY-MM-DD')
        data2 = date.formatDate(new Date(), 'YYYY-MM-DD')
      }*/

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.uf && extraFilters.push(`&uf=${this.table.filters.uf}`)
      this.table.filters.cidade && extraFilters.push(`&cidade=${this.table.filters.cidade.nome}`)
      this.table.filters.status && extraFilters.push(`&status=${this.table.filters.status}`)

      let documento = String(this.table.filters.documento).replace(/\D/gi, '') // TODO: Only numbers

      this.table.loading = true
      let _filter = this.filtros
      console.log(pagination.descending)
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'id'}&descending=${pagination.descending}&search=${this.table.filters.search}&documento=${documento}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.total
          this.table.serverData = data.result
          if (data.stats) {
            this.stats = data.stats
          }

          this.table.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters () {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo () {
      console.log('New...')
      this.arrematanteWindow(null, this.title)
    },
    pesquisar () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.search
      })
    },
    abrir (id) {
      console.log('Abrir window arrematante')
      this.arrematanteWindow(id, this.title)
    },
    arrematanteWindow: arrematanteWindow,
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este arrematante? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir arrematante-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteArrematante(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Arrematante excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    filterToday () {
      if (this.statsFilterActive === 'today') {
        this.statsFilterActive = null
        this.table.filters.data1 = null
        this.table.filters.data2 = null
        this.load()
        return
      }
      this.table.filters.data1 = format(new Date(), 'dd/MM/yyyy')
      this.table.filters.data2 = format(new Date(), 'dd/MM/yyyy')
      this.statsFilterActive = 'today'
      this.load()
    },
    filterWeek () {
      if (this.statsFilterActive === 'week') {
        this.statsFilterActive = null
        this.table.filters.data1 = null
        this.table.filters.data2 = null
        this.load()
        return
      }
      const start = addDays(startOfWeek(new Date()), 0);
      const end = subDays(endOfWeek(new Date()), 0);
      this.table.filters.data1 = format(start, 'dd/MM/yyyy')
      this.table.filters.data2 = format(end, 'dd/MM/yyyy')
      this.statsFilterActive = 'week'
      this.load()
    }
  },
  meta: {
    title: 'Arrematantes',
    name: 'Arrematantes'
  }
}
</script>

<template>
  <layout class="erp-list" no-title :menu="menu" menu-style="compact" menu-active="arrematantes">
    <div class="wrapper-md">
      <div class="erp-list">
        <div class="flex justify-between">
          <div class="m-t-sm">
            <a @click="filterToday" class="box-v2-pre-btn m-r" :class="{active: statsFilterActive === 'today'}">
              <i class="fa-duotone fa-calendar-day" />
              <span><strong>{{ stats.cadastradosHoje }}</strong> hoje</span>
            </a>
            <a @click="filterWeek" class="box-v2-pre-btn m-r" :class="{active: statsFilterActive === 'week'}">
              <i class="fa-duotone fa-calendar-week" />
              <span><strong>{{ stats.cadastradosSemana }}</strong> esta semana</span>
            </a>
            <a @click="$router.push({name: 'arrematantes.aprovacao'})" class="box-v2-pre-btn m-r">
              <i class="fa-duotone fa-person-circle-check" />
              <span><strong>{{ stats.paraAnalisar }}</strong> para analisar</span>
            </a>
          </div>
          <div class="flex justify-end m-t m-b-lg">
            <u-btn @click="novo" class="sl-btn" color="green" no-caps v-shortkey="['shift', 'n']"
                   @shortkey.native="novo">Cadastrar <span class="shortcut font-10 m-l">Shift + N</span></u-btn>
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table table-v2"
            :rows-per-page-options="[20, 50, 100]"
        >
          <template slot="top" slot-scope="props">
            <div class="sl-v2-table-filters full-width">
              <e-row class="e-input-modern size1">
                <e-col style="min-width: 150px" class="m-l m-r">
                  <erp-s-field
                      view="ll"
                      label="Busca"
                  >
                    <erp-input placeholder="Busca Inteligente" v-on:keyup.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 100px" class="m-l m-r">
                  <erp-s-field
                      view="ll"
                      label="ID"
                  >
                    <erp-input v-on:keyup.enter="pesquisar" shortkey="F3" v-model="table.filters.id" />
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 150px" class="m-l m-r arr-tipo-select">
                  <tipo-arrematante-select field-view="ll" select-null v-model="table.filters.tipo" />
                </e-col>
                <e-col style="min-width: 150px" class="m-l m-r">
                  <status-select field-view="ll" multiple v-model="table.filters.status" />
                </e-col>
                <e-col style="max-width: 80px">
                  <e-btn @click="pesquisar" label="Filtrar" />
<!--                  <u-btn @click="pesquisar" color="grey" size="sm" class="app-btn erp-btn text-white" no-caps>Filtrar</u-btn>-->
                </e-col>
                <e-col class="flex items-end content-end justify-end">
                  <filtros-adicionais :filters="table.filters" />
                </e-col>
              </e-row>
              <e-row>
              </e-row>
            </div>
          </template>
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props" :style="col.name === 'photo' ? 'text-align: center !important; width: 20px' : ''">
              <template v-if="col.name !== 'photo'">
                {{ col.label }}
              </template>
              <template v-else>
                <i class="fa-duotone fa-camera-retro" style="display: inline" />
              </template>
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
              </div>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>
            <u-td key="photo" style="width: 20px !important; text-align: center">
              <div class="app-person-avatar m-r-sm flex justify-center full-width">
                <img v-if="props.row.pessoa && props.row.pessoa.photo" :src="props.row.pessoa.photo">
                <div v-else class="img-fake">{{ firstLettersName(props.row.pessoa.name) }}</div>
              </div>
            </u-td>
            <u-td key="nome"
                  :props="props">
              <div class="flex items-center">
                <div>{{props.row.pessoa.name}}</div>
              </div>
            </u-td>
            <u-td style="max-width: 25px" key="id" :props="props">
              {{props.row.id}}
            </u-td>
            <u-td key="apelido" :props="props">
              {{props.row.apelido}}
            </u-td>
            <u-td key="documento" :props="props">
              <div v-if="Number(props.row.pessoa.type) === 1">
                {{props.row.pessoa.document|formataCpf}}
              </div>
              <div v-else>
                {{props.row.pessoa.document|formataCnpj}}
              </div>
            </u-td>
            <u-td key="dataCadastro"
                  :props="props">
              {{props.row.createdAt.date|formatDate}}
            </u-td>
            <u-td key="compras" :props="props">
              <div v-if="props.row.stats && props.row.stats.compras">
                {{ props.row.stats.compras }}
                <u-tooltip class="font-10" :offset="[10,10]">R$ {{ props.row.stats.totalCompras|moeda }}</u-tooltip>
              </div>
              <div v-else>0</div>
            </u-td>
            <u-td key="lances" :props="props">
              <div v-if="props.row.stats && props.row.stats.lances">{{ props.row.stats.lances }}</div>
              <div v-else>0</div>
            </u-td>
            <u-td class="text-uppercase" key="status" :props="props">
              <status-arrematante :arrematante="props.row" />
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="abrir(props.row.id)" @excluir="excluir(props.row.id)" :leilao="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-arrematante-item @abrir="abrir(props.row.id)" @excluir="excluir(props.row.id)" :leilao="props.row" />
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<style lang="stylus" src="../assets/default.styl"></style>
