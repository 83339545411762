export default {
    id: '',
    tipo: '',
    search: '',
    documento: '',
    data1: '',
    data2: '',
    uf: null,
    cidade: null,
    status: []
}
