<template>
  <div @click="$router.push({name: 'suporte.cliente.dashboard'})" class="flex items-center cursor-pointer">
    <PosMenuIcon name="headset" icon-type="fa" icon-style="light" />
    <span class="m-r m-l-n font-10 font-bold text-blue">Suporte</span>
  </div>
</template>

<script>
import {PosMenuIcon} from 'uloc-vue-plugin-erp'

export default {
  name: "SuporteBtn",
  components: {
    PosMenuIcon,
  },
  methods: {
  }
}
</script>
