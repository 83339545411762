import {humanStorageSize} from "uloc-vue/src/utils/format";

export default {
  data () {
    return {
      icons: {
        pdf: 'file-pdf',
        image: 'image',
        video: 'video',
        document: 'file-word',
        audio: 'file-audio',
        file: 'file'
      }
    }
  },
  methods: {
    filesize(size) {
      return humanStorageSize(size)
    },
    getFileExtension(filename) {
      let path = filename.split('.')
      return path.pop()
    },
    getFileIco (filename) {
      console.log(filename)
      return this.bindIcon(this.getFileExtension(filename))
    },
    bindIcon (fileType) {
      let images = ['jpg', 'jpeg', 'png', 'gif']
      let pdf = ['pdf']
      let document = ['doc', 'docx']
      if (images.some((type) => fileType.indexOf(type) > -1)) {
        return this.icons.image
      }
      if (fileType.indexOf('video') > -1) {
        return this.icons.video
      }
      if (pdf.some((type) => fileType.indexOf(type) > -1)) {
        return this.icons.pdf
      }
      if (document.some((type) => fileType.indexOf(type) > -1)) {
        return this.icons.document
      }
      if (fileType.indexOf('audio') > -1) {
        return this.icons.audio
      }
      return this.icons.file
    }
  }
}
