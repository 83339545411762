<script>
import UserForm from "components/usuario/v2/components/UserForm.vue";
import Layout from "components/layout/Layout.vue"

export default {
  name: 'Dashboard',
  provide: function () {
    return {
      userPage: this
    }
  },
  inject: {
    erplayout: {
      default() {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  components: {
    Layout,
    UserForm
  },
  data() {
    return {}
  },
  watch: {
  },
  computed: {},
  mounted() {
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {},
  meta: {
    title: 'Meu Perfil',
    name: 'MeuPerfil'
  }
}
</script>

<template>
  <layout no-menu :folded="false" no-title hfull>
    <user-form :user-id="$uloc.auth.session.user.id" />
  </layout>
</template>

<style src="./assets/default.styl" lang="stylus"></style>

