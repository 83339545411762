<template>
  <div class="fin-pessoa-input-container">
    <autocomplete ref="input" :placeholder="placeholder" :parse="parse" :search="search" v-model="model" :min-characters="3" :is-loading="loading" caret />
    <i v-if="value && value.id" class="fin-p-edit fa fa-eye">
      <u-popover class="window-context-menu no-border-radius" anchor="bottom left" self="top left">
        <div class="menu">
          <ul>
            <menu-options-item @click="gotoPerson(value)" icon="user" icon-type="fa" icon-style="light" label="Acessar Pessoa" close  />
            <menu-options-item @click="editPessoa(value.id)" icon="user-pen" icon-type="fa" icon-style="light" label="Editar Pessoa" close  />
          </ul>
        </div>
      </u-popover>
    </i>
    <pessoa-cadastro ref="cadastro" :mount-body="true" style="z-index: 11000" />
  </div>
</template>

<script>
import Autocomplete from "@/components/layout/components/Autocomplete"
import {UPopover} from "uloc-vue"
import {list} from "@/domain/pessoa/services"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item.vue"
import PessoaCadastro from "components/pessoa/components/window/Cadastro.vue"
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"
const search = function (terms, tipo = null, full = false) {
  let filtros = encodeURI(`&sortBy=name&descending=false&search=${terms}`)
  if (tipo) {
    filtros = filtros + '&tag=' + (tipo === 2 ? 'fornecedor' : '')
  }
  if (full) {
    filtros = filtros + '&full=1'
  }
  return list(50, 1, filtros)
}
export default {
  name: "FinPessoaInput",
  mixins: [GlobalPersonMixin],
  components: {
    PessoaCadastro,
    MenuOptionsItem,
    Autocomplete,
    UPopover
  },
  props: {
    value: {
      required: true
    },
    placeholder: {
      required: false
    },
    tipo: {
      required: false
    },
    full: {
      type: Boolean,
      default: false
    },
    router: {
      required: false
    }
  },
  data () {
    return {
      model: this.value,
      loading: false
    }
  },
  watch: {
    model(v) {
      this.$emit('input', this.model)
    },
    value(v) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.model !== v) {
            this.model = v
            this.$refs.input.set(v)
          }
        }, 100)
      })
    }
  },
  methods: {
    parse (v) {
      return v && v.name
    },
    search(terms, done) {
      this.loading = true
      search(terms, this.tipo, this.full)
          .then(response => {
            this.loading = false
           let  extra = null
            if (terms) {
              extra = {
                label: `Adicionar nova pessoa`,
                value: terms,
                isNew: true,
                link: true,
                click: () => {
                  window.open('/#/pessoas#cadastro', '_blank')
                }
              }
            }
            let result = response.data.result.map(p => {
              return {
                ...p,
                label: p.name,
                value: p.name,
                active: null
              }
            })
            extra && result.push(extra)
            done(result)
          }).catch(error => {
        this.loading = false
        this.alertApiError(error)
      })
    },
    editPessoa(id) {
      this.$refs.cadastro.show(id)
    }
  }
}
</script>
