<script>
import DefaultMixin from '../mixin'
import Menu from './menu/menu-config'
import Layout from "components/layout/Layout.vue"

export default {
  name: 'ConfigLayout',
  provide: function () {
    return {
      page: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    Layout
  },
  data () {
    return {
      active: 'hom'
    }
  },
  computed: {
    menu () {
      return Menu
    }
  },
  methods: {
    setActive (v) {
      this.active = v
    }
  },
  meta: {
    title: 'Integra Seguradoras',
    name: 'Integra Seguradoras'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="bens">
  <div class="wrapper-lg">
    <div class="app-layout-cmenu" style="padding-right: 0">
      <transition name="slide" appear>
        <div class="transition-generic">
          <router-view />
        </div>
      </transition>
    </div>
  </div>
  </layout>
</template>
