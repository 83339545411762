export default [
  {
    id: 'acessorios',
    name: 'Acessórios',
    icon: 'archive',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/acessorios'
  },
  {
    id: 'tiposbem',
    name: 'Tipos de Bem',
    icon: 'badge-check',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposbem'
  },
  {
    id: 'tiposapreensao',
    name: 'Tipos de Apreensão',
    icon: 'truck-loading',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposapreensao'
  },
  {
    id: 'tiposnotificacao',
    name: 'Tipos de Notificação',
    icon: 'alert',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposnotificacao'
  },
  {
    id: 'tiposarquivo',
    name: 'Tipos de Arquivo',
    icon: 'file',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposarquivo'
  },
  {
    id: 'tiposarquivoarrematante',
    name: 'Tipos de Arquivo Arrematantes',
    icon: 'file',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposarquivoarrematante'
  },
  {
    id: 'tiposcombustivel',
    name: 'Tipos de Combustível',
    icon: 'users-cog',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposcombustivel'
  },
  {
    id: 'tiposfinalidadeimovel',
    name: 'Tipos de Finalidade de Imóvel',
    icon: 'gas-pump',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposfinalidadeimovel'
  },
  {
    id: 'tipospericia',
    name: 'Tipos de Perícias/Observações',
    icon: 'book',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tipospericia'
  },
  {
    id: 'tiposdocumentoleilao',
    name: 'Tipos de Documento de Leilão',
    icon: 'file-alt',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposdocumentoleilao'
  },
  /* {
    id: 'tiposinteressearrematante',
    name: 'Interesses de Arrematante',
    icon: 'person-sign',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposinteressearrematante'
  }, */
  {
    id: 'tiposcor',
    name: 'Tipos de Cor',
    icon: 'palette',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposcor'
  },
  {
    id: 'tiposconservacaobem',
    name: 'Tipos de Conservação de Bem',
    icon: 'comment-alt-dollar',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposconservacaobem'
  },
  {
    id: 'tipospatio',
    name: 'Tipos de Pátio',
    icon: 'map-marker-question',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tipospatio'
  },
  {
    id: 'tiposvagapatio',
    name: 'Tipos de Vaga de Pátio',
    icon: 'map-marker-alt',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposvagapatio'
  },
  {
    id: 'tiposendereco',
    name: 'Tipos de Endereço',
    icon: 'address-card',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposendereco'
  },
  {
    id: 'tipostelefone',
    name: 'Tipos de Telefone',
    icon: 'phone',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tipostelefone'
  },
  {
    id: 'tiposemail',
    name: 'Tipos de E-mail',
    icon: 'mail-bulk',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposemail'
  },
  {
    id: 'tiposcontato',
    name: 'Tipos de Contato',
    icon: 'id-card',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposcontato'
  },
  {
    id: 'tiposcomitente',
    name: 'Tipos de Comitente',
    icon: 'user',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposcomitente'
  },
  {
    id: 'marcas',
    name: 'Marcas',
    icon: 'copyright',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/marcas'
  },
  {
    id: 'modelos',
    name: 'Modelos',
    icon: 'sitemap',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/modelos'
  },
  {
    id: 'tiposcancelamentovenda',
    name: 'Motivos Cancelamento Venda',
    icon: 'badge-check',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/tiposcancelamentovenda'
  },
  {
    id: 'leiloeiros',
    name: 'Leiloeiros',
    icon: 'user',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/leiloeiros'
  },
  {
    id: 'classificacoesLeilao',
    name: 'Classificações de Leilão',
    icon: 'quote-right',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/classificacoesLeilao'
  },
  {
    id: 'documentosrequeridosarrematante',
    name: 'Documentos Requeridos (A)',
    icon: 'file-alt',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/documentosrequeridosarrematante'
  },
  {
    id: 'bancos',
    name: 'Bancos / Contas',
    icon: 'money-check',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/bancos'
  },
  {
    id: 'metodospagamentolote',
    name: 'Métodos Pagamento Lote',
    icon: 'money-check-alt',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/metodospagamentolote'
  },
  {
    id: 'gruposcomitentes',
    name: 'Grupo de Comitentes',
    icon: 'users-cog',
    iconType: 'fa',
    iconStyle: 'light',
    class: '',
    href: '/#/cadastro/gruposcomitentes'
  }
]
