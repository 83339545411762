export const extra = {
    nomeFinanciadoSegurado: null,
    contratoFinanciamento: null,
    assessoriaJuridica: null,
    codigoFilial: null,
    vistoriador: null,
    numeroSinistro: null,
    numeroAtendimento: null,
    avaliacaoTerceiros: {
        avaliador: null,
        remessa: null,
        valorMercado: null,
        relatorio: null
    }
}

export const mockImovel = {
    ocupado: null,
    possuiCondominio: null,
    valorCondominio: null,
    possuiIptu: null,
    valorIptu: null,
    periodicidadeIptu: null,
    possuiOutrasDespesas: null,
    valorOutrasDespesas: null,
    numeroIptu: null,
    numeroMedidorEnergia: null,
    numeroMedidorAgua: null,
    numeroMedidorGas: null,
    localCartorio: null,
    finalidade: null
}

export const mockVeiculo = {
    sinistro: null,
    numeroMotor: null,
    municipioPlaca: null
}

export const mockBem = {
    id: null,
    identificador: null,
    destaque: false,
    vendaDireta: false,
    codigo: null,
    descricao: null,
    tipoPai: null,
    tipo: null,
    comitente: null,
    remocao: {
        id: null,
        solicitacoes: []
    },
    dataEntrada: null,
    dataSaida: null,
    status: 1,
    bloqueadoLeilao: false,
    motivoBloqueio: null,
    // Veículo
    placa: null,
    ufPlaca: null,
    chassi: null,
    renavam: null,
    km: null,
    codigoFipe: null,
    marca: null,
    modelo: null,
    anoModelo: null,
    fipeAnoModelo: null,
    anoFabricacao: null,
    cor: null,
    tipoCombustivel: null,
    alagamento: null,
    dataHigienizacao: null,

    liga: null,
    possuiChave: null,
    situacaoChave: null,
    codigoChave: null,
    conservacao: null,

    //Site
    siteTitulo: null,
    siteSubtitulo: null,
    siteDescricao: '',
    siteObservacao: '',
    tituloLeiloeiro: null,
    descricaoLeiloeiro: null,
    observacaoLeiloeiro: '',

    camposExtra: [
        {id: 1, value: null}
    ],

    camposExtras: [],

    extra: JSON.parse(JSON.stringify(extra)),

    //Avaliação
    observacaoConservacao: null,
    valorMercado: 0.00,
    valorAvaliacao: 0.00,
    porcentagemAvaliacaoSobreValorMercado: null,
    valorMinimo: 0.00,
    valorDebitos: 0.00,
    porcentagemMinimoSobreValorMercado: null,
    avarias: null,

    // Imóvel
    pais: 1,
    cep: null,
    endereco: null,
    endComplemento: null,
    numero: null,
    bairro: null,
    cidade: null,
    uf: null,
    inscricaoMunicipal: null,
    cartorio: null,
    areaEdificada: null,
    areaTerreno: null,

    acessorios: [],

    tipoApreensao: null,

    pericias: [],

    leiloes: [],

    // Processo
    processoNumero: null,
    processoExequente: null,
    processoExecutado: null,
    processoVara: null,
    processoComarca: null,
    processo: null,

    localizacaoLatitude: null,
    localizacaoLongitude: null,
    localizacaoUrlGoogleMaps: null,
    localizacaoUrlStreetView: null,
    localizacaoMapEmbed: null,
    imovel: JSON.parse(JSON.stringify(mockImovel)),
    veiculo: JSON.parse(JSON.stringify(mockVeiculo)),
    tags: [],
    caracteristicas: [],
    campos: [],

    edital: null,
    ata: null,
    catalogo: null,
    nota: null,

    observacoesInternas: '',
    aprovadoVenda: 0,
    aprovadoVendaMotivo: null,
    eventoInterno: 0,
    active: true,
    order: 99,

    sites: [],
    infoVisitacao: null,
    notificacoes: []
}
