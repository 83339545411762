export default {
  title: 'Gestão de Bens',
  menu: [
    {
      id: 'bens',
      title: 'Bens',
      icon: 'clipboard-list-check',
      style: 'light',
      url: '/#/bens/lista'
    },
    {
      id: 'imoveis',
      title: 'Imóveis',
      icon: 'house-building',
      style: 'light',
      url: '/#/bens/lista#imoveis'
    },
    {
      id: 'veiculos',
      title: 'Veículos',
      icon: 'car',
      style: 'light',
      url: '/#/bens/lista#veiculos'
    },
    {
      id: 'vistoria',
      title: 'Vistoria',
      icon: 'ballot-check',
      style: 'light',
      url: '/#/vistoria'
    },
    {
      id: 'patios',
      title: 'Pátios',
      icon: 'map-marker-check',
      style: 'regular',
      url: '/#/patios/lista'
    },
    /*{
      id: 'calculo',
      title: 'Cálculo de Estadas',
      icon: 'file-chart-line',
      style: 'regular',
      url: '#'
    },*/
    {
      id: 'remocao',
      title: 'Remoções',
      icon: 'exchange',
      style: 'regular',
      url: '/#/remocao'
    }/*,
    {
      id: 'config',
      title: 'Configurações',
      icon: 'cog',
      style: 'light',
      url: '#'
    }*/
  ]
}
