import {getEventKey, position as eventPosition, stopAndPrevent} from "uloc-vue/src/utils/event"
import EditorToolbar from "@/components/layout/components/EditorToolbar"
import {EditorCommands, getSelectionCoords} from "@/components/layout/components/EditorCommands"
import FullScreenMixin from "uloc-vue/src/mixins/fullscreen"

export default {
    name: "SLEditor",
    provide: function () {
        return {
            editorContainer: this
        }
    },
    mixins: [FullScreenMixin],
    props: {
        value: {
            type: String,
            required: true
        },
        readonly: Boolean,
        autofocus: Boolean,
        disable: Boolean,
        contentStyle: Object,
        contentClass: [Object, Array, String],
        placeholder: {
            type: String,
            default: null
        },
        showToolbar: {
            type: Boolean,
            default: true
        },
        showToolbarOnFocus: {
            type: Boolean,
            default: false
        },
        closeClickOutside: {
            type: Boolean,
            default: true
        },
        toolbarMenu: {
            type: Object
        },
        onlySource: {
            type: Boolean,
            default: false
        },
        defaultType: {
            type: String,
            default: 'html'
        },
        disableMark: {
            type: Boolean,
            default: false
        },
        disableSource: {
            type: Boolean,
            default: false
        },
        disablePrint: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editWatcher: true,
            isFocused: false,
            source: this.onlySource
        }
    },
    created() {
        document.execCommand('defaultParagraphSeparator', false, 'div')
        this.defaultFont = window.getComputedStyle(document.body).fontFamily
    },
    mounted() {
        document.body.addEventListener('click', this.clickOutside, true)
        this.$nextTick(() => {
            if (this.$refs.content) {
                this.ecommand = new EditorCommands(this.$refs.content, this)
                this.$refs.content.innerHTML = this.value
                if (this.autofocus) {
                    this.focus()
                }
            }
            this.$nextTick(this.refreshToolbar)
            this.$nextTick(() => {
                if (this.defaultType === 'source') {
                    this.ecommand.vm.source = true
                }
            })
        })
    },
    beforeDestroy() {
        document.body.removeEventListener('click', this.clickOutside)
    },
    computed: {
        editable() {
            return !this.readonly && !this.disable
        },
        innerStyle() {
            return [
                this.contentStyle
            ]
        },
        innerClass() {
            return [
                this.contentClass
            ]
        },
        editorClass () {
            return {
                'focused': this.isFocused,
                'show-toolbar-on-focus': this.showToolbarOnFocus,
            }
        }
    },
    watch: {
        value(v) {
            if (this.editWatcher) {
                this.$refs.content.innerHTML = v
            } else {
                this.editWatcher = true
            }
        }
    },
    methods: {
        onInput(e) {
            if (this.editWatcher) {
                const val = this.$refs.content.innerHTML
                if (val !== this.value) {
                    this.editWatcher = false
                    this.$emit('input', val)
                }
            }
        },
        onKeydown(e) {
            setTimeout( () => {
                this.$nextTick(() => {
                    this.ecommand.keydownEvent(e, this.value, this.$refs.toolbar.keys, this.runCmd)
                })
            }, 10)
            const key = getEventKey(e)

            // console.log(key) // @TODO: REMOVER

            let cancel = false

            const target = this.$refs.toolbar.keys[key]
            // console.log('Target', target)
            if (target !== void 0) {
                if (!e.ctrlKey && !target.forceKeyMapping) {
                    cancel = true
                }
            } else {
                cancel = true
            }

            if (cancel) {
                this.refreshToolbar()
                this.$uloc.platform.is.ie && this.$nextTick(this.onInput)
                return
            }

            const {cmd, param} = target
            if (target.forceKeyMapping) {
                const {x, y} = getSelectionCoords()
                e.clientX = x
                e.clientY = y + 20
            } else{
                stopAndPrevent(e)
            }
            /*console.log('TEST FUNCTION', getSelectionCoords())
            console.log('TARGET: ', e.target)
            console.log('SELECTION: ', window.getSelection().getRangeAt(0))
            console.log('TEST: ', eventPosition(e))
            console.log('TEST2: ', e.target.getBoundingClientRect())*/
            this.runCmd(cmd, param, false, e, target.forceKeyMapping)
            this.$uloc.platform.is.ie && this.$nextTick(this.onInput)
        },
        runCmd(cmd, param, update = true, evt = null, keymapping = false) {
            console.log('Run CMD', cmd, param)
            this.focus()
            this.ecommand.apply(cmd, param, () => {
                this.focus()
                if (update) {
                    this.refreshToolbar()
                }
            }, evt, keymapping)
        },
        refreshToolbar() {
            setTimeout(() => {
                this.editLinkUrl = null
                this.$forceUpdate()
            }, 1)
        },
        focus() {
            this.$emit('focus')
            this.isFocused = true
            console.log('is focused')
            this.$refs.content.focus()
            // window.getSelection().selectAllChildren(this.$refs.content)
            // window.getSelection().collapseToEnd()
        },
        focusEnd() {
            this.$emit('focus')
            this.isFocused = true
            console.log('is focused')
            this.$refs.content.focus()
            window.getSelection().selectAllChildren(this.$refs.content)
            window.getSelection().collapseToEnd()
        },
        blur() {
            if (!this.showToolbarOnFocus) {
                this.isFocused = false
            }
            this.$emit('blur')
            console.log('is blur')
        },
        forceBlur () {
            this.isFocused = false
            this.$refs.content.blur()
        },
        clickOutside(evt) {
            if (
                evt && evt.target && this.$el &&
                (this.$el.contains(evt.target) || (this.$el.parentNode && this.$el.parentNode.contains(evt.target)))
            ) {
                // this.leave()
                // this.$el.classList.add('focused')
                console.log(evt)
                console.log('AQUI')
                this.focus()
                // placeCaretAtEnd(this.$refs.editor)
                return
            }
            if (this.closeClickOutside) {
                this.isFocused = false
            }
            // this.hide(evt)
        },
        contentNode () {
            return this.$refs.content
        },
        htmlContent () {
            return this.source ? this.$refs.content.value : this.$refs.content.innerHTML
        },
        addTextToCursor (text) {
            !this.readonly && !this.source && this.ecommand.addTextToCursor(text)
        }
    },
    render(h) {

        let inputData =  {
            ref: 'content',
            staticClass: `sl-editor-content`,
            style: this.innerStyle,
            class: this.innerClass,
            attrs: {contenteditable: this.editable},
            // domProps: {innerHTML: this.value},
            on: {
                input: this.onInput,
                keydown: this.onKeydown,
                click: this.refreshToolbar,
                focus: this.focus,
                blur: this.blur
            }
        }

        if (this.source) {
            delete inputData.attrs
            inputData.domProps = {
                value: this.value
            }
            inputData.on.input = (e) => {
                this.$emit('input', e.target.value)
            }
            delete inputData.on.keydown
            delete inputData.on.click
        } else {}

        return h('div',
            {
                staticClass: 'sl-editor',
                class: this.editorClass
            },
            [
                h('div', {staticClass: 'sl-editor-helpers'}, [
                    !this.isFocused && this.placeholder && !this.value && h('div',
                        {
                            staticClass: 'sl-editor-input-placeholder',
                            attrs: {contenteditable: false},
                        }, this.placeholder),
                ]),
                h('div', {staticClass: 'sl-editor-container'}, [
                    this.source && !this.readonly ? h(
                        'textarea',
                        inputData
                    ) : h(
                        'div',
                        inputData
                    )
                ]),
                this.showToolbar && h('div', {staticClass: 'sl-editor-tools'}, [
                    h(EditorToolbar, {
                        ref: 'toolbar',
                        on: {
                            runCmd: this.runCmd
                        },
                        props: {
                            toolbarMenu: this.toolbarMenu
                        }
                    })
                ]),
            ])
    }
}
