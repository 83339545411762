export default [{
    path: '/console/carteira',
    component: () => import('./components/Page'),
    name: 'carteira.page',
    children: [
        {path: '', name: 'clientes.dashboard', component: () => import('./components/Dashboard')},
        {
            path: 'clientes', name: 'clientes.page', component: () => import('./components/cliente/ClientesPage'),
            children: [
                {path: '', name: 'clientes.lista', component: () => import('./components/Clientes')},
                {
                    path: 'anotador', name: 'clientes.anotador', component: () => import('./components/Anotador')
                },
                {
                    path: ':id', name: 'clientes.cliente', component: () => import('./components/cliente/Cliente'),
                    children: [
                        {path: 'instancia', name: 'clientes.cliente.instancia', component: () => import('./components/cliente/Instancia')},
                        {path: 'financeiro', name: 'clientes.cliente.financeiro', component: () => import('./components/cliente/Financeiro')},
                    ]
                }
            ]
        }
    ]
}]
