import http from '../../utils/services/http'
import {responseError} from "@/domain/global";

export const STATS_ENDPOINT = process.env.VUE_APP_STATS
const transformRequest = (data, headers) => {
    delete headers.common['Authorization']
    return data
}

export const work = (userId, date, secondsWorked) => {
    let url = `${STATS_ENDPOINT}/workedHours`
    return http.post(url, {
        userId: userId,
        date: date,
        seconds: secondsWorked
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const background = () => {
    let url = `/api/public/background`
    return http.get(url, {
        transformRequest(data, headers) {
            delete headers.common['Authorization']
            return data
        }
    })
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const realtimeOnlines = (roomId = null, client = null) => {
    let url = `/api/stats/realtime/clientsOnline?roomId=${roomId}&client=${client}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const leilaoLog = (id, page = 1, limit = 100, download = false) => {
    let url = `/api/stats/leilao/${id}/logs?page=${page}&limit=${limit}`
    let config = {}
    if (download) {
        config.responseType = 'blob'
        url = url + '&download=1'
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(responseError)
}
