<script>
import Layout from '../../layout/Layout'
import Menu from '../menu'
import {list, listClassificacao, listLeiloeiros, deleteLeilao} from '@/domain/leiloes/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import StatusLeilao from './include/StatusLeilao'
import ContextMenuLeilaoItem from './context/list-context-leilao'
import MenuOptions from '../../layout/context-menu/context-window-options'
import DefaultListMenu from './context/list-menu-leilao'
import SearchFilter from '../../layout/helpers/SearchFilter'
import {ErpInput, /*ErpSelect,*/ ErpSField} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import StatusSelect from './include/StatusLeilaoSelect'
import DateInput from '../../../reuse/input/Date'
import leilaoWindow from '../windows/leilao'
import TipoLeilaoSelect from './include/TipoLeilaoSelect'
import DefaultAsyncSelect from '../../../reuse/input/DefaultAsyncSelect'
import StatusInternoSelect from './include/StatusInternoLeilaoSelect'
import listOptions from '../windows/listOptions'
import {datePtToEn} from '@/utils/date';
import StatusInternoLeilao from './include/StatusInternoLeilao'
import tableColumnsCache from "@/utils/tableColumnsCache"
import {donwloadFile} from "@/utils/downloadFile"
import leiloesHtmlView from "@/components/relatorios/components/leiloes/leiloesHtmlView"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import FiltrosAdicionais from "components/leiloes/components/include/FiltrosAdicionaisLeilao.vue"
import EBtn from "@/plugins/uloc-erp/components/button/EBtn"
import {filters} from "@/domain/leiloes/helpers/filters"
import habilitacaoType from "@/domain/leiloes/helpers/habilitacaoType";
import SlCheckbox from "@/reuse/input/Checkbox.vue";

const listStorage = tableColumnsCache('leiloes.lista', [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Código', name: 'codigo', active: true, sortable: true, ordering: 1},
  {label: 'Data', name: 'dataProximoLeilao', active: true, sortable: true, ordering: 1},
  {label: 'Resumo', name: 'resumo', active: true, sortable: true, ordering: 1},
  {label: 'Publicação', name: 'publicacao', active: false, sortable: true, ordering: 1},
  {label: 'Abertura', name: 'abertura', active: false, sortable: true, ordering: 1},
  {label: 'Encerramento', name: 'encerramento', active: false, sortable: true, ordering: 1},
  {label: 'Detalhes', name: 'detalhes', active: true, sortable: true, ordering: 1},
  {label: 'Robô', name: 'robo', active: true, sortable: true, ordering: 1},
  {label: 'Habilitação', name: 'habilitacao', active: true, sortable: true, ordering: 1},
  {label: 'Habilitados', name: 'habilitados', active: true, sortable: true, ordering: 1},
  {label: 'Status', name: 'status', active: true, sortable: true, ordering: 1},
  {label: 'Status Interno', name: 'statusInterno', active: false, sortable: true, ordering: 1}
], 3)

const listStorageApis = tableColumnsCache('leiloes.lista.apis', [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Código', name: 'codigo', active: true, sortable: true, ordering: 1},
  {label: 'Data', name: 'dataProximoLeilao', active: true, sortable: true, ordering: 1},
  {label: 'Resumo', name: 'resumo', active: true, sortable: true, ordering: 1},
  {label: 'Detalhes', name: 'detalhes', active: true, sortable: true, ordering: 1},
  {label: 'Status', name: 'status', active: true, sortable: true, ordering: 1},
  {label: 'Imóvel Web', name: 'imovelWeb', active: true, sortable: true, ordering: 1},
], 1)

export default {
  name: 'Leiloes',
  provide: function () {
    return {
      leilaoListContainer: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    SlCheckbox,
    EBtn, FiltrosAdicionais,
    StatusInternoLeilao,
    // StatusInternoSelect,
    StatusSelect,
    DefaultListMenu,
    MenuOptions,
    ContextMenuLeilaoItem,
    StatusLeilao,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    // UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField,
    ERow,
    ECol
  },
  data() {
    return {
      listStorage: listStorage,
      listStorageApis: listStorageApis,
      listType: 'ativos',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      },
      habilitacaoOptions: habilitacaoType,
      modoApis: false,
      apis: {
        imovelWeb: {
          203: false,
          209: true,
        }
      }
    }
  },
  computed: {
    menu() {
      return Menu
    },
    menuDashOptions() {
      return MenuOptions
    },
    columns() {
      let c = listStorage
      if (this.modoApis) {
        c = listStorageApis
      }
      return c.map(o => {
        return {
          ...o,
          field: o.name,
          align: 'left'
        }
      }).concat([
        {
          name: 'options',
          required: true,
          label: '',
          field: 'options',
          sortable: false
        }
      ])
    },
    visibledColumns() {
      if (this.modoApis) {
        return this.listStorageApis.filter(item => item.active).map(item => item.name)
      }
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  beforeMount () {
    if (this.$route.hash) {
      this.parseHash()
    } else {
      this.table.filters.modulo = null
    }
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        if (this.parseHash()) {
          this.load()
        }
      })
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    parseHash () {
      if (this.$route.hash) {
        const hash = this.$route.hash.replace('#', '')
        if (hash === 'rural') {
          this.table.filters.modulo = 'rural'
          //this.load()
        }
        return true
      }
    },
    request({pagination, filter, format}) {

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10) {
          alert('Digite a data inicial corretamente.')
          return
        }

        if (!this.table.filters.data2) {
          this.table.filters.data2 = this.table.filters.data1
        }

        if (this.table.filters.data2.length < 10) {
          alert('Digite a data final corretamente.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)
      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.codigo && extraFilters.push(`&codigo=${this.table.filters.codigo}`)
      this.table.filters.ano && extraFilters.push(`&ano=${this.table.filters.ano}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)
      this.table.filters.classificacao && extraFilters.push(`&classificacao=${this.table.filters.classificacao}`)
      this.table.filters.leiloeiro && extraFilters.push(`&leiloeiro=${this.table.filters.leiloeiro}`)
      this.table.filters.modulo && extraFilters.push(`&modulo=${this.table.filters.modulo}`)
      this.table.filters.typeFilter && extraFilters.push(`&typeFilter=${this.table.filters.typeFilter}`)
      this.table.filters.tipoEvento && extraFilters.push(`&tipoEvento=${this.table.filters.tipoEvento}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      if (Array.isArray(this.table.filters.statusInterno) && this.table.filters.statusInterno.length > 0) {
        extraFilters.push(`&statusInterno=${this.table.filters.statusInterno.join(',')}`)
      }

      if (format === 'excel' || format === 'xlsx') {
        extraFilters.push('&format=excel')
      } else if(format === 'html') {
        extraFilters.push('&format=html')
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'dataProximoLeilao'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&search=${filter}${extraFilters.join('')}`)


      list(pagination.rowsPerPage, pagination.page, filtros, format)
          .then((response) => {
            let data = response.data
            this.loading = false
            this.table.loading = false
            if (format === 'excel' || format === 'xlsx') {
              donwloadFile(response)
              return
            } else if(format === 'html') {
              this.leiloesHtmlView(data)
              return
            }
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            let result = data.result
            if (Number(pagination.page) < 2) {
              if (data.hoje && data.hoje.length) {
                data.hoje.map(leilao => {
                  leilao.isToday = true
                  result = result.filter(l => l.id !== leilao.id)
                  result.unshift(leilao)
                })
              }
            }
            this.table.serverData = result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.table.loading = false
            this.loading = false
          })
    },
    load(format = null) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca,
        format: format
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo() {
      console.log('New...')
      this.leilaoWindow(null, this.table.filters.modulo)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      this.$router.push({name: 'leiloes.show', params: {id: id}})
      /*let router = this.$router || this.router
      let routeData = router.resolve({name: 'leiloes.show', params: {id: id}})
      window.open(routeData.href, '_blank')*/
    },
    edit(id) {
      this.leilaoWindow(id, this.$route.query.tipo)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este leilão? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir leilao-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteLeilao(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Leilão excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    leilaoWindow: leilaoWindow,
    windowLeilaoEvents(wid) {
      console.log('Bind events')
      this.$uloc.window.listen(wid, {
        new: (wid, leilao) => {
          console.log('Updated event')
          // this.load()
          this.$router.push({name: 'leiloes.show', params: {id: leilao.id}})
        },
        update: (wid, leilao) => {
          console.log('Updated event')
          this.load()
        }
      })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set('leiloes.lista', newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      this.table.filters.tipoEvento = null
      if (v === 'ativos') {
        this.table.filters.status = [0, 1, 2, 3, 4]
      } else if (v === 'finalizados') {
        this.table.filters.status = [96, 97, 98, 99]
      } else if (v === 'eventoLeilao') {
        this.table.filters.status = [0, 1, 2, 3, 4]
        this.table.filters.tipoEvento = 'leilao'
      } else if (v === 'eventoVendaDireta') {
        this.table.filters.status = [0, 1, 2, 3, 4]
        this.table.filters.tipoEvento = 'venda-direta'
      } else {
        this.table.filters.status = []
      }
      this.load()
    },
    leiloesHtmlView: leiloesHtmlView,
    imprimir () {
      this.load('html')
    },
    toggleModoApis () {
      this.modoApis = !this.modoApis
    },
    tabFilter (t) {
      if (this.table.filters.typeFilter === t) {
        this.table.filters = JSON.parse(JSON.stringify(filters))
        this.load()
        return
      }
      this.table.filters = JSON.parse(JSON.stringify(filters))
      this.table.filters.typeFilter = t
      this.load()
    },
    getHabilitacao (h) {
      if (!h) {
        return '-'
      }
      const find = this.habilitacaoOptions.find(f => f.value === Number(h))
      if (!find) {
        return '-'
      }
      return find.label
    }
  },
  meta: {
    title: 'Leilões',
    name: 'Leilões'
  }
}
</script>

<template>
  <layout v-if="$route.name === 'leiloes.lista'" class="erp-list" :menu="menu" menu-style="compact" menu-active="leiloes">
    <div class="leiloes-list">
      <div class="sheader">
        <h2 class="component-title">Leilões</h2>
        <div class="flex justify-between">
          <div class="m-b">
            <a @click="tabFilter('comLance')" class="box-v2-pre-btn m-r" :class="{active: table.filters.typeFilter === 'comLance'}">
              <i class="fa-duotone fa-gavel text-green" />
              <span>Com Lance(s)</span>
            </a>
            <a @click="tabFilter('semLance')" class="box-v2-pre-btn m-r" :class="{active: table.filters.typeFilter === 'semLance'}">
              <i class="fa-duotone fa-bag-shopping-minus" />
              <span>Sem Lance</span>
            </a>
            <a @click="tabFilter('comHabilitados')" class="box-v2-pre-btn m-r" :class="{active: table.filters.typeFilter === 'comHabilitados'}">
              <i class="fa-duotone fa-users" />
              <span>Com Habilitados</span>
            </a>
            <a @click="$router.push({name: 'leiloes.auditorio-unificado'})" class="box-v2-pre-btn m-r bg-blue text-white">
              <i class="fa-duotone fa-computer" />
              <span class="text-white">Auditório Unificado</span>
            </a>
          </div>
        </div>
        <div class="btns">
          <u-btn class="sl-btn box-v2 m-l" color="green" no-caps @click="novo">
            <i class="fa-duotone fa-add" /> <span>Novo Leilão</span>
          </u-btn>
        </div>
      </div>
      <div class="erp-list">
        <div class="erp-list-top-btns">
          <div class="actives-btns">
            <u-btn @click="changeListType('ativos')" label="Ativos" class="active-table-btn" :class="{active: listType === 'ativos'}" no-caps />
            <u-btn @click="changeListType('finalizados')" label="Finalizados" class="text-grey active-table-btn" :class="{active: listType === 'finalizados'}" no-caps />
            <u-btn @click="changeListType('todos')" label="Todos" class="text-grey active-table-btn" :class="{active: listType === 'todos'}" no-caps />
            <u-btn @click="changeListType('eventoLeilao')" label="Somente Leilões" class="text-grey active-table-btn" :class="{active: listType === 'eventoLeilao'}" no-caps />
            <u-btn @click="changeListType('eventoVendaDireta')" label="Somente Venda Direta" class="text-grey active-table-btn" :class="{active: listType === 'eventoVendaDireta'}" no-caps />
          </div>
          <div class="end">
            <u-btn @click="toggleModoApis" title="APIs" no-caps size="sm" flat icon="webhook" icon-type="fa" color="grey-4" class="text-grey-8" :class="{'bg-positive': modoApis}" style="padding: 1px 8px"/>
            <u-btn @click="imprimir" title="Imprimir" no-caps size="sm" flat icon="print" icon-type="fa" color="grey-4" class="text-grey-8" style="padding: 1px 8px"/>
            <u-btn @click="load('excel')" title="Exportar para Excel" no-caps size="sm" flat icon="file-excel" icon-type="fa" color="grey-4" class="text-grey-8 m-r-sm" style="padding: 1px 8px"/>
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table table-v2 m-b-xxl"
            :rows-per-page-options="[20, 50, 100]"
        >
          <template slot="top" slot-scope="props">
            <div class="sl-v2-table-filters full-width">
              <e-row class="e-input-modern size1">
                <e-col style="min-width: 150px; max-width: 330px" class="m-l m-r">
                  <erp-s-field
                      view="ll"
                      label="Busca"
                  >
                    <erp-input class="input-placeholder-min" placeholder="Busca Inteligente. Descrição, Processo, Placa, etc..." v-on:keyup.enter="pesquisar" shortkey="F2" v-model="table.filters.search" />
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 100px" class="m-l m-r">
                  <erp-s-field
                      view="ll"
                      label="ID"
                  >
                    <erp-input v-on:keyup.enter="pesquisar" shortkey="F3" v-model="table.filters.id" />
                  </erp-s-field>
                </e-col>
                <e-col class="m-l m-r">
                  <status-select field-view="ll" multiple v-model="table.filters.status"/>
                </e-col>
                <e-col class="m-r-xxl" style="max-width: 80px">
                  <e-btn @click="pesquisar" label="Filtrar" />
                  <!--                  <u-btn @click="pesquisar" color="grey" size="sm" class="app-btn erp-btn text-white" no-caps>Filtrar</u-btn>-->
                </e-col>
                <e-col style="max-width: 120px" class="flex items-end content-end justify-end">
                  <filtros-adicionais :filters="table.filters" />
                </e-col>
              </e-row>
              <div style="margin-left: 7px; display: none">
                <e-row class="m-l-xxl sl-alert font-10">
                  <strong class="m-r-xs">Dica:</strong> Saiba que agora você pode, por meio da busca inteligente, encontrar leilões pelo número do processo, nome das partes, vara, comarca, placa, matrícula do imóvel e outros critérios de pesquisa que se estendem para os lotes.
                </e-row>
              </div>
            </div>
          </template>
          <u-tr slot="header" slot-scope="props">
<!--            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>-->
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn @click="listOptions" flat round no-caps size="sm" color="grey-8" icon="cog" icon-type="fa" />
              </div>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
<!--            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>-->
            <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td style="max-width: 100px; min-width: 100px; width: 100px" key="codigo" :props="props">
              {{ props.row.numero }}/{{ props.row.ano }}
            </u-td>
            <u-td style="max-width: 180px; min-width: 180px; width: 180px" class="td-limit today" key="dataProximoLeilao" :props="props">
              <span class="m-r-xs" v-if="props.row.dataProximoLeilao">{{ props.row.dataProximoLeilao.date|formatDate('dd/MM/yyyy HH:mm') }}</span>
              <span class="m-r-xs" v-else-if="props.row.dataAbertura">{{ props.row.dataAbertura.date|formatDate('dd/MM/yyyy HH:mm') }} <i class="font-10 m-l-xs">(abertura)</i> </span>
              <span v-if="Number(props.row.instancia) > 1" class="badge b-radius-3px badge-sm bg-grey-7 text-white font-10"><strong>{{ Number(props.row.praca) }}</strong>/{{Number(props.row.instancia)}}</span>
            </u-td>
            <u-td :title="props.row.resumo" class="td-limit l-300" key="resumo"
                  :props="props">
              <span v-if="props.row.vendaDireta" class="badge badge-sm bg-purple text-white">Venda Direta</span>
              {{ props.row.descricaoInterna }}
            </u-td>
            <u-td style="max-width: 160px; min-width: 160px; width: 160px" class="td-limit" key="publicacao" :props="props">
              <div v-if="props.row.dataPublicacao">
                {{ props.row.dataPublicacao.date|formatDate('dd/MM/yyyy HH:mm') }}
              </div>
              <div v-else>Nunca</div>
            </u-td>
            <u-td style="max-width: 160px; min-width: 160px; width: 160px" class="td-limit today" key="abertura" :props="props">
              <span v-if="props.row.dataAbertura">{{ props.row.dataAbertura.date|formatDate('dd/MM/yyyy HH:mm') }}</span>
            </u-td>
            <u-td style="max-width: 160px; min-width: 160px; width: 160px" class="td-limit" key="encerramento" :props="props">
              <span
                  v-if="props.row.dataFimPraca1">{{
                  props.row.dataFimPraca1.date|formatDate('dd/MM/yyyy HH:mm')
                }}</span>
              <span v-else>-</span>
            </u-td>
            <u-td style="max-width: 100px; min-width: 100px; width: 100px" key="detalhes" :props="props">
              {{ props.row.totalLotes }} lote{{ Number(props.row.totalLotes) > 1 ? 's' : '' }}
            </u-td>
            <u-td key="robo" :props="props">
              <div v-if="props.row.controleAutomatico">
                <i class="fa-duotone fa-robot text-green" /> <span>{{parseTimerInMinutes(props.row.timerPregao)}}</span>
              </div>
              <div v-else><i class="fa-duotone fa-robot text-negative" /></div>
            </u-td>
            <u-td key="habilitacao" :props="props">
              <div>{{ getHabilitacao(props.row.habilitacao) }}</div>
            </u-td>
            <u-td style="max-width: 80px" key="habilitados" :props="props">
              <div v-if="Number(props.row.habilitados) > 0 && Number(props.row.habilitados) < 300" class="text-green-8 font-bold">{{ props.row.habilitados }}</div>
              <div v-if="Number(props.row.habilitados) > 300" class="text-green-8 font-bold">
                {{ props.row.habilitados }}
                <i class="fa-light fa-triangle-exclamation text-negative">
                  <u-tooltip :offset="[10,10]">
                    Alto tráfego previsto, recomendamos aumento da infra ou infra exclusiva. Contate o time SL
                  </u-tooltip>
                </i>
              </div>
              <div v-else></div>
            </u-td>
            <u-td style="max-width: 140px; min-width: 140px; width: 140px" class="text-uppercase" key="status" :props="props">
              <status-leilao :leilao="props.row"/>
            </u-td>
            <u-td style="max-width: 120px; min-width: 120px; width: 120px" class="text-uppercase" key="statusInterno" :props="props">
              <status-interno-leilao :leilao="props.row"/>
            </u-td>
            <u-td key="imovelWeb" :props="props">
              <sl-checkbox v-model="apis.imovelWeb[props.row.id]" />
            </u-td>
            <u-td style="max-width: 70px; min-width: 70px; width: 70px" class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                     @excluir="excluir(props.row.id)" :leilao="props.row"/>
                </menu-options>
              </e-btn-table-options>
            </u-td>
            <context-menu-leilao-item @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                      @excluir="excluir(props.row.id)" :leilao="props.row"/>
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
  <router-view v-else />
</template>

<style lang="stylus" src="../assets/default.styl"></style>
