import { render, staticRenderFns } from "./MenuLateral.vue?vue&type=template&id=6418f0ec"
import script from "./MenuLateral.vue?vue&type=script&lang=js"
export * from "./MenuLateral.vue?vue&type=script&lang=js"
import style0 from "./menu.styl?vue&type=style&index=0&prod&lang=stylus&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports