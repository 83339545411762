import http from '../../../utils/services/http'

export const list = async (limit, page, filtros) => {
  let url = '/api/user-groups?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const create = (data) => {
  let url = '/api/user-groups'
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/user-groups/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const update = (id, data, includePerson = false) => {
  let url = `/api/user-groups/${id}`
  if (includePerson) {
    url = url + '?includePerson=1'
  }
  return http.put(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const deleteGroup = (id) => {
  let url = `/api/user-groups/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
export const acl = async () => {
  let url = `/api/user-groups/getAcl`
  return http.get(url)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}
