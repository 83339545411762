<template>
  <div class="popup-select" :class="classes">
    <div v-if="active && enablePhoto" class="popup-photo">
      <img v-if="active.photo" width="25" class="" :src="active.photo"/>
      <div v-else class="img-fake">{{ firstLettersName(active.name) }}</div>
    </div>
    <div v-else-if="enableProfile" class="popup-photo fake">
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation"><g fill="currentColor" fill-rule="evenodd"><path d="M6 14c0-1.105.902-2 2.009-2h7.982c1.11 0 2.009.894 2.009 2.006v4.44c0 3.405-12 3.405-12 0V14z"></path><circle cx="12" cy="7" r="4"></circle></g></svg>
      <span>{{profilePlaceholder}}</span>
    </div>
    <erp-input :autofocus="autofocus" :size="inputSize" :class="inputClass" :placeholder="placeholder" :disable="disabled" v-if="!readonly" @focus="focus" @blur="blur" @keydown="keyup" v-model="search" />
    <u-icon v-if="active" @click.native.stop.prevent="reset" class="btn-reset absolute-center-right m-r-lg text-primary cursor-pointer" name="times-circle" type="fa" />
    <u-icon class="absolute-center-right m-r-sm helper-chevron" name="chevron-down" type="fa" />
    <div class="helper-bg"></div>
    <popup ref="popup" :async="async" :default-value="value" :emitOnlyValue="emitOnlyValue" :search-data="searchData" @close="closePopup" autofocus v-show="openPopup && !readonly" :active="active" @selected="select" :search-only-select="searchOnlySelect" :expand="expand" :enable-photo="enablePhoto" />
  </div>
</template>

<script>
import {ErpInput} from "uloc-vue-plugin-erp"
import Popup from "./ListSelectPopup"
import {stopAndPrevent} from "uloc-vue/src/utils/event"

export default {
  name: "ListSelect",
  props: {
    value: {
      required: true
    },
    searchData: {
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    emitOnlyValue: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    async: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false
    },
    inputClass: {
      type: String
    },
    inputSize: {
      type: Number,
      default: 2
    },
    searchOnlySelect: {
      type: Boolean,
      default: false
    },
    parseCallback: {
      required: false
    },
    expand: {
      type: Boolean,
      default: false
    },
    enablePhoto: {
      type: Boolean,
      default: false
    },
    enableProfile: {
      type: Boolean,
      default: false
    },
    profilePlaceholder: {
      type: String,
      default: 'Não definido'
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: this.parseCallback ? this.parseCallback(this.value) : (this.value && this.value.label ? this.value.label : this.value),
      active: this.value,
      openPopup: false
    }
  },
  computed: {
    classes () {
      const css = []
      if (this.enablePhoto) {
        css.push('popup-with-photo')
      }
      this.readonly && css.push('readonly')
      return css
    }
  },
  mounted() {
  },
  methods: {
    closePopup () {
      this.openPopup = false
    },
    focus () {
      console.log('Focus...')
      this.openPopup = true
      this.$nextTick(() => {
        this.$refs.popup.focus()
      })
    },
    blur () {
      console.log('Blur...')
      // this.openPopup = false
    },
    select (c) {
      console.log('Selected', c)
      const value = this.emitOnlyValue && typeof c.value !== 'undefined' ? c.value : c
      this.$emit('input', value)
      this.active = c
      this.search = this.parseCallback ? this.parseCallback(c) : c.label
      this.$refs.popup.close()
    },
    keyup (e) {
      stopAndPrevent(e)
    },
    limit (s, l) {
      if (String(s).length <= l) {
        return s
      }
      return String(s).substring(0, l) + '...'
    },
    getNewData () {
      return this.$refs.popup.getNewData()
    },
    reset () {
      this.active = null
      this.search = null
      this.$emit('input', null)
    },
    autoSelectValue (v, done) {
      console.log('Auto select', v)
      if (v && v.id) {
        this.select(v)
        return
      } else if (v) {
        setTimeout(async () => {
          console.log('Buscando item em ', v, this.$refs.popup.result)
          // if (!this.$refs.popup.result || !this.$refs.popup.result.length) {
          //  console.log('Result vazio, tentando buscar...')
          await this.$refs.popup.searchTrigger(v)
          //}
          const item = this.$refs.popup.result.find(r => r.id === Number(v) || (r.value && r.value === v) || r === v || (r && r.label && r.label === v))
          console.log('Finded item: ', item)
          item && this.select(item)
        }, 20)
        return
      }
      this.$refs.popup.autoSelectValue(v, done)
    },
    firstLettersName(name) {
      name = name.split(' ')
      if (Array.isArray(name) && name.length > 1) {
        return name[0].charAt(0) + name[1].charAt(0)
      }
      return String(name).substr(0, 2)
    }
  },
  components: {
    Popup,
    ErpInput
  }
}
</script>
