import http from "@/utils/services/http";

export const findTiposNotificacao = (id) => {
    let url = `/api/tiposNotificacao/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listTiposNotificacao = (limit, page, filtros) => {
    let url = '/api/tiposNotificacao?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newTipoNotificacao = (data) => {
    let url = `/api/tiposNotificacao`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateTipoNotificacao = (id, data) => {
    let url = `/api/tiposNotificacao/${id}`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteTipoNotificacao = (id) => {
    let url = `/api/tiposNotificacao/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
