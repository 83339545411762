export default [
    {
        path: '/configuracoes/usuarios', component: () => import('./Page.vue'), name: 'usuarios.main', children: [
            {
                path: 'grupos', component: () => import('./v2/components/Grupos.vue'), name: 'grupos.list', children: [
                    {path: 'create', component: () => import('./v2/components/Grupo.vue'), name: 'grupos.grupo.new'},
                    {path: ':id', component: () => import('./v2/components/Grupo.vue'), name: 'grupos.grupo'},
                ]
            },
            {
                path: '', component: () => import('./v2/components/Usuarios.vue'), name: 'usuarios.list', children: [
                    {path: 'create', component: () => import('./v2/components/Usuario.vue'), name: 'usuarios.usuario.new'},
                    {path: ':id', component: () => import('./v2/components/Usuario.vue'), name: 'usuarios.usuario', children: [
                            {path: 'perfil', component: () => import('./v2/components/Usuario.vue'), name: 'usuarios.usuario.perfil'}
                        ]},
                ]
            }
        ]
    }
]
