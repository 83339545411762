<template>
  <PosMenuIcon v-if="cracl('ROLE_BEM') || cracl('ROLE_GESTAO') || cracl('ROLE_LEILAO')" name="plus" icon-type="fa" icon-style="light">
    <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 5]">
      <div>
        <template v-if="cracl('ROLE_BEM', 'bem/c')">
        <h2 class="pos">BENS</h2>
        <ul>
          <menu-options-item v-if="$appConfig.isConfigTrue('bem.tipoGestao')" @click="bemWindow2(null)" label="Cadastrar Bem" close  />
          <menu-options-item v-else @click="bemWindow(null)" label="Cadastrar Bem" close  />
        </ul>
        </template>
        <template v-if="cracl('ROLE_LEILAO', 'leilao/c')">
        <div class="diviser"></div>
        <h2 class="pos">LEILÕES</h2>
        <ul>
          <menu-options-item @click="leilaoWindow(null)" label="Cadastrar Leilão" close  />
        </ul>
        </template>
        <template v-if="cracl('ROLE_GESTAO', 'gestao/task/c')">
        <div class="diviser"></div>
        <h2 class="pos">TAREFAS</h2>
        <ul>
          <menu-options-item @click="tarefaWindow(null)" label="Criar Tarefa" close  />
        </ul>
        </template>
        <template v-if="cracl('ROLE_GESTAO', 'gestao/pessoas/c')">
        <div class="diviser"></div>
        <h2 class="pos">PESSOAS</h2>
        <ul>
          <menu-options-item @click="$router.push('/pessoas#cadastro')" label="Cadastrar uma Pessoa" close  />
        </ul>
        </template>
        <template v-if="cracl('ROLE_GESTAO', 'gestao/processo/c')">
        <div class="diviser"></div>
        <h2 class="pos">PROCESSOS</h2>
        <ul>
          <menu-options-item @click="processoWindow(null)" label="Cadastrar um Processo" close  />
        </ul>
        </template>
      </div>
    </u-popover>
  </PosMenuIcon>
</template>

<script>
import {PosMenuIcon} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import bemWindow from '@/components/bem/windows/bem'
import bemWindow2 from '@/components/bem2/windows/bem'
import leilaoWindow from '@/components/leiloes/windows/leilao'
import tarefaWindow from '@/components/sltarefas/windows/tarefa'
import processoWindow from '@/components/processos/components/processos/window/windowProcesso'
export default {
  name: "AtalhoCadastros",
  components: {
    PosMenuIcon,
    MenuOptionsItem,
    UPopover
  },
  methods: {
    bemWindow,
    bemWindow2,
    leilaoWindow,
    tarefaWindow,
    processoWindow
  }
}
</script>
