<template>
  <div class="user-permissions-v2">
    <div v-for="(m, k) in modules" :key="'module-' + k" class="module" :class="[{open: m.open}]">
      <div @click="toggleModule(m)" class="user-module-tab">
        <div>
          <i class="fa icon-tab" :class="m.open ? 'fa-chevron-up' : 'fa-chevron-down'" />
          <i :class="m.icon" class="icon-lbl" /> <span class="lbl">{{m.name}}</span>
        </div>
        <div class="item-check">
          <span v-if="!m.check && !m.permissions">Nenhuma permissão habilitada</span>
          <span v-else-if="m.permissions && !m.check"><strong>{{ m.permissions }} de {{ m.components.length }} permissões</strong> habilitadas</span>
          <span v-else class="font-bold">Todas as permissões selecionadas</span>
          <u-checkbox v-model="m.check" :disable="disabled" />
        </div>
      </div>
      <div class="permissions" v-if="m.open">
        <ul>
          <li v-for="c in m.components" :class="[{checked: c.check}]">
            <label>
              <u-checkbox v-model="c.check" color="positive" :disable="disabled" /> <span class="lbl">{{c.label}}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {UCheckbox} from "uloc-vue"

export default {
  name: "UserPermission",
  props: ['acl', 'entity', 'disabled'],
  components: {
    UCheckbox
  },
  data () {
    return {
      /*modules: [
        {name: 'Bens', icon: 'fal fa-car-building', open: false}
      ]*/
      modules: this.parseModule(this.acl.helper)
    }
  },
  computed: {
    /*disabled () {
      return this.form.perfil !== null
    }*/
  },
  beforeDestroy() {
    this.reset()
  },
  methods: {
    toggleModule (m) {
      m.open = !m.open
    },
    reset() {
      this.modules.map(m => {
        m.check = false
        m.components && m.components.map(c => {
          c.check = false
          return c
        })
        return m
      })
    },
    parseModule (m) {
      const modules = JSON.parse(JSON.stringify(m))
      return Object.keys(modules).map(k => {
        let module = modules[k]
        let moduleCheck = false
        if (this.entity.roles && this.entity.roles.includes('ROLE_' + String(k).toUpperCase())) {
          moduleCheck = true
        }
        module = {
          name: module,
          value: k,
          icon: this.parseModuleIcon(k),
          open: false,
          get permissions() {
            const l = this.components.filter(component => component.check).length
            /*if (l < this.components.length) {
              this.check = false
            } else {
              this.check = true
            }*/
            return l
          },
          _check: moduleCheck,
          set check(value) {
            this._check = value
            this.components.forEach(component => {
              component.check = value
            })
          },
          get check() {
            return this._check
          },
          components: typeof this.acl.modules[k] !== 'undefined' ? Object.keys(this.acl.modules[k]).map(acl => {
            const map = this.acl.modules[k][acl]
            let componentCheck = false
            if ((this.entity.acl && this.entity.acl.includes(acl))) {
              componentCheck = true
            }
            return {
              name: acl,
              label: map[2],
              map: map,
              check: moduleCheck || componentCheck
            }
          }) : []
        }
        return module
      })
    },
    parseModuleIcon (m) {
      switch (m) {
        case 'bem':
          return 'fa-duotone fa-fw fa-car-building'
        case 'vistoria':
          return 'fa-duotone fa-fw fa-ballot-check'
        case 'leilao':
          return 'fa-duotone fa-fw fa-gavel'
        case 'gestao':
          return 'fa-duotone fa-fw fa-people-roof'
        case 'patio':
          return 'fa-duotone fa-fw fa-garage-open'
        case 'arrematante':
          return 'fa-duotone fa-fw fa-person-sign'
        case 'comitente':
          return 'fa-duotone fa-fw fa-user-tie-hair'
        case 'financeiro':
          return 'fa-duotone fa-fw fa-money-check'
        case 'crm':
          return 'fa-duotone fa-fw fa-people-group'
        case 'website':
          return 'fa-duotone fa-fw fa-browser'
        case 'relatorios':
          return 'fa-duotone fa-fw fa-chart-user'
        case 'config':
          return 'fa-duotone fa-fw fa-folder-gear'
        default:
          return 'fa-duotone fa-fw fa-square-sliders'
      }
    }
  }
}
</script>
