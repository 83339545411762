<script>
import {resetPassword} from "@/domain/usuario/services"
import SlLoading from "components/layout/components/Loading.vue";

export default {
  name: "AlterarSenhaTerminal",
  components: {SlLoading},
  props: {
    user: {required: true}
  },
  data () {
    return {
      enabled: false,
      loading: true,
      passwd: false,
      sendmail: false
    }
  },
  methods: {
    generatePassword() {
      this.$uloc.dialog({
        title: 'Gerar uma nova senha',
        message: 'Selecione os canais que deseja enviar a senha para o usuário',
        options: {
          type: 'checkbox',
          model: [],
          items: [
            {label: 'Visualizar Senha', value: 'preview', color: 'primary'},
            {label: 'E-mail', value: 'email', color: 'secondary'},
            {label: 'Whatsapp', value: 'whatsapp', disable: true},
            {label: 'SMS', value: 'sms', disable: true}
          ]
        },
        cancel: true,
        preventClose: true,
        color: 'secondary'
      }).then((data) => {
        console.log(data)
        //this.$uloc.loading.show()
        this.enabled = true
        this.passwd = false
        this.sendmail = false
        this.loading = true
        resetPassword(this.user.id, null, 'alertChannels=' + data.join(','))
            .then((response) => {
              let message
              if (data.includes('preview')) {
                message = 'Nova senha gerada: ' + response.data.password
                this.passwd = response.data.password
              } else {
                this.sendmail = true
                message = 'Nova senha gerada com sucesso!'
              }
              /*this.$uloc.loading.hide()
              this.$uloc.dialog({
                title: 'Senha redefinida com sucesso!',
                color: 'positive',
                message: message
              })*/
              this.loading = false
            })
            .catch(error => {
              //this.$uloc.loading.hide()
              this.loading = false
              console.log(error.data)
              this.$nextTick(() => {
                this.alertApiError(error)
              })
            })
      }).catch(() => {
      })
    }
  }
}
</script>

<template>
  <div v-if="enabled" class="erp-terminal v1">
    <sl-loading v-if="loading" class="size-18" :content="''" />
    <div v-if="passwd" class="passwd" style="line-height: 1.2">
      <div>----------------------------------</div>
      <div class="font-bold">Senha gerada com sucesso!</div>
      <div>----------------------------------</div>
      <div><span style="width: 60px">Login:</span> <span class="text-white">{{user.username}}</span> </div>
      <div><span style="width: 60px">E-mail:</span> <span class="text-white">{{user.email}}</span> </div>
      <div><span style="width: 60px">Senha:</span> <span class="text-white">{{passwd}}</span> </div>
      <div>----------------------------------</div>
    </div>
    <div v-if="sendmail" class="passwd" style="line-height: 1.2">
      <div>----------------------------------</div>
      <div>Senha enviada com sucesso para <strong class="text-white">{{user.email}}</strong></div>
      <div>----------------------------------</div>
    </div>
  </div>
</template>
