<template>
  <li v-if="!m.diviser" @mouseover="onmouseover" @mouseleave="onmouseleave" @mousedown="onmousedown" class="mitem">
    <helper v-if="showHelper" :title="m.title" />
    <a class="menu-link" @click="menuClick(m)" :class="classes">
      <div class="lbl">
        <!--<u-icon class="smenu-icon fa-fw" :name="m.icon" :type="m.type || 'fa'" :icon-style="m.style || 'solid'" />-->
        <i v-if="m.icon" class="smenu-icon" :class="icon"></i>
        <span class="lbl-name">{{ label }}</span></div>
      <div class="count" v-if="typeof count !== 'undefined' && count !== null && count !== ''">
        <span>{{ count }}</span>
      </div>
      <div class="submenu-icon" v-if="m.submenu">
        <span><i class="fal fa-chevron-right" /></span>
      </div>
      <i class="icon-hover fa fa-arrow-alt-circle-right" v-if="m.iconHover"></i>
    </a>
    <submenu @active="eventActive" :level="menuLevel" @close="closeSubmenu" :opened="opened" :title="m.title" v-show="m.submenu && opened"
             :menu="m.submenu"/>
  </li>
  <div v-else class="menu-lbl lbl-master">{{ label }}</div>
</template>

<script>
import Submenu from "@/components/layout/menu-components/Submenu"
import Helper from "@/components/layout/menu-components/Helper"
import {usersState} from "components/usuario/menu"

export default {
  // name: "MenuItem",
  components: {
    Helper,
    Submenu
  },
  inject: ['menuContainer', 'menuContent'],
  props: {
    m: {
      required: true
    },
    submenu: {
      type: Boolean,
      default: false
    },
    menuLevel: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    // usersState.count = 50
    if (this.menuLevel === 1) {
      this.menuContainer.$on('open', (id) => {
        id !== this.m.id && this.close(null, false)
      })
    }
    this.testActive(this.$route)
  },
  watch: {
    '$route' (r) {
      this.testActive(r)
    },
    active (v) {
      if (!v && this.opened) {
        this.menuContainer.isFolded && this.close(null, true)
        //this.menuContainer.$emit('close', null)
      }
    }
  },
  data() {
    return {
      opened: false,
      active: false,
      whenSubmenuOpened: false,
      showHelper: false,
      disabled: this.m.disabled || false
    }
  },
  computed: {
    classes () {
      /*
      {'active': m.id === menuContainer.active || active, 'has-submenu': opened, 'disabled': disabled}
       */
      const css = []
      let name = this.name || this.label.toLowerCase()
      if (this.route && this.route.name && this.route.name === this.$route.name) {
        css.push('active')
      } else if (this.page && this.page.menuActive && typeof this.page.menuActive['name'] !== 'undefined' && this.page.menuActive.name === name) {
        css.push('active')
      } else if (this.page && this.page.menuActive && this.page.menuActive === name) {
        css.push('active')
      } else if (this.menuContainer && this.menuContainer.active === this.m.id) {
        css.push('active')
      } else if (this.active) {
        css.push('active')
      } else if (this.url && this.url.endsWith(this.$route.path)) {
        css.push('active')
      }
      this.opened && css.push('has-submenu')
      this.disabled && css.push('disabled')
      return css
    },
    icon () {
      return this.m.forceIconClass ? this.m.icon : 'fal fa-fw fa-'+ this.m.icon
    },
    label () {
      return this.m.title
    },
    count () {
      return this.m.count
    },
    isSubmenuOpened() {
      return this.menuContainer.submenuOpen
    },
    hasSubmenuEffect() {
      return this.menuContainer.hasSubmenuEffect
    }
  },
  methods: {
    eventActive () {
      console.log('Event active')
      if (this.menuLevel !== 1) return
      this.active = true
    },
    testActive (r) {
      this.active = false
      if (this.m.route) {
        if (this.m.route === r.name) {
          this.active = true
          setTimeout(() => {
            this.$emit('active')
          }, 300)
          return
        }
      }
      if (this.m.url) {
        if (this.m.url.replace(/^\/#/, '') === (r.path + r.hash)) {
          this.active = true
          setTimeout(() => {
            this.$emit('active')
          }, 300)
          return
        }
      }
    },
    menuClick(m) {
      if (this.disabled) return
      if (!m.submenu) {
        if (m.route) {
          this.$router.push({name: m.route})
        }
        if (!m.url) return
        this.$router.push(m.url.replace(/^\/#/, ''))
        if (!m.href) return
        this.$router.push(m.href)
      } else {
        // has submenu
        if (this.menuContainer.isFolded) {
          this.menuContainer.toggle(false)
        }
        // this.opened && this.menuLevel > 1 ? this.close(m) : this.open(m)
        this.opened ? this.close(m) : this.open(m)
      }
    },
    open(m) {
      if (this.opened) return
      this.opened = true
      this.menuContainer.$emit('open', m ? m.id : null)
    },
    close(m, emitEvent = true) {
      this.opened = false
      emitEvent && this.menuContainer.$emit('close', m ? m.id : null)
    },
    closeSubmenu () {
      this.opened = false
      this.menuContainer.$emit('close', null)
    },
    onmouseover () {
      if (this.menuLevel === 1 && !this.opened && (this.menuContainer.isFolded || this.menuContainer.submenuOpen)) {
        this.showHelper = true
      }
    },
    onmouseleave () {
      this.showHelper = false
    },
    onmousedown () {
      this.showHelper = false
    }
  }
}
</script>
