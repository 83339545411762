import http from '../../../utils/services/http'

export const validar = (id, codigo) => {
  let url = `api/autorizacao/${id}/validar?codigo=${codigo}`
  return http.post(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}
